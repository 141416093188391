<div *ngIf="article" class="content">
    <div class="cover">
        <img class="image" [lazyLoad]="article.thumbnail">
        <div class="cover-content">
            <div class="tags">
                <div *ngFor="let tag of article.tags" class="tag">
                    {{ tag }}
                </div>
            </div>
            <div class="title">
                <h1>
                    {{ article.title }}
                </h1>
            </div>
            <div class="summary" [innerHtml]="article.summary"></div>
            <div *ngIf="article.author" class="author">
                {{ article.author }} - {{ getDate(article._id, article.date) }}
            </div>
        </div>
    </div>
    <div class="body">
        <div class="body-inner">
            <br/>
            <div *ngFor="let content of article.content">
                <div *ngIf="content.type === 'text'" class="text" [innerHtml]="content.data">
                </div>
                <img *ngIf="content.type === 'image'" class="body-image" defaultImage="../../../assets/loading.gif" [lazyLoad]="content.data"/>
                <div *ngIf="content.type === 'subtitle'" class="subtitle">
                    {{ content.data }}
                </div>
                <div *ngIf="content.type === 'quote'" class="quote">
                    <span class="quote-marks">&#8220;</span>{{ content.data }}<span class="quote-marks">&#8221;</span>
                </div>
                <div *ngIf="content.type === 'html'" [outerHTML]="content.data | safeHtml"></div>
            </div>
            <div class="share">Share This Article:</div>
            <div class="icons">
                <a href="https://www.instagram.com/edengreenspace/" target="_blank">
                    <img class="icon" src="../../../assets/instagram.png">
                </a>
                <a href="https://www.facebook.com/sharer/sharer.php?u=edengreenspace.co.uk" target="_blank">
                    <img class="icon" src="../../../assets/facebook.png"/>
                </a>
                <a href="mailto:?body=Check out this site https://edengreenspace.co.uk." target="_blank">
                    <img class="icon" src="../../../assets/mail.png"/>
                </a>
                <a href="https://twitter.com/share?url=edengreenspace.co.uk" target="_blank">
                    <img class="icon" src="../../../assets/twitter.png"/>
                </a>
                <a href="http://www.reddit.com/submit?url=https://edengreenspace.co.uk" target="_blank">
                    <img class="icon" src="../../../assets/reddit.png"/>
                </a>
            </div>
            <div class="buttons">
                <div routerLink="/products/eden-bracelet" class="button pointer clickable">
                    Make An Impact
                </div>
                <div routerLink="/learn" class="button pointer clickable">
                    All Articles
                </div>
            </div>
        </div>
    </div>
</div>
