import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChoosePlanComponent } from './components/choose-plan/choose-plan.component';
import { LoginComponent } from './components/login/login.component';
import { AccountComponent } from './components/account/account.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { BioComponent } from './components/bio/bio.component';
import { SuccessComponent } from './components/success/success.component';
import { FaqComponent } from './components/faq/faq.component';
import { MissionComponent } from './components/mission/mission.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { GiftingComponent } from './components/gifting/gifting.component';
import { CartComponent } from './components/cart/cart.component';
import { AdminComponent } from './components/admin/admin.component';
import { BlogComponent } from './components/blog/blog.component';
import { ArticleComponent } from './components/article/article.component';
import { TreePlantingComponent } from './components/tree-planting/tree-planting.component';
import { BusinessComponent } from './components/business/business.component';
import { ImpactCreationComponent } from './components/impact-creation/impact-creation.component';
import { BraceletsComponent } from './components/bracelets/bracelets.component';
import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { PasswordComponent } from './components/password/password.component';
import { MediaComponent } from './components/media/media.component';
import { BusinessHowItWorksComponent } from './components/business-how-it-works/business-how-it-works.component';
import { NewHomePageComponent } from './components/new-home-page/new-home-page.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { PollsComponent } from './components/polls/polls.component';
import { PollResultsComponent } from './components/poll-results/poll-results.component';

const routes: Routes = [
  
  //Home screen
  { path: '', component: NewHomePageComponent },
  { path: 'choose-your-plan', component: ChoosePlanComponent, data : {blur: false} },
  //Account
  { path: 'account-page', component: AccountComponent },
  { path: 'leaderboard', component: LeaderboardComponent },
  { path: 'forgot-password/:token', component: PasswordComponent },
  { path: 'forgot-password', component: PasswordComponent },
  { path: 'media', component: MediaComponent },
  { path: 'business-how-it-works', component: BusinessHowItWorksComponent },
  { path: 'impact-creation', component: ImpactCreationComponent },
  { path: 'impact-creation/:type', component: ImpactCreationComponent },
  { path: 'admin/:article', component: AdminComponent },
  { path: 'admin', component: AdminComponent },
  //Login
  { path: 'login', component: LoginComponent },
  { path: 'create-winkworth-account', component: LoginComponent },

  //Projects
  { path: 'our-projects', component: ProjectsComponent },
  { path: 'our-projects/:url', component: BioComponent },

  //Faqs
  { path: 'faqs', component: FaqComponent },

  //About
  { path: 'about/:option', component: MissionComponent },
  { path: 'overview', component: MissionComponent },

  //Newsletter
  { path: 'newsletter', component: NewsletterComponent },
  
  //Blog
  { path: 'learn', component: BlogComponent },
  { path: 'articles/:url', component: ArticleComponent },

  //Business
  { path: 'business', component: BusinessComponent },
  { path: 'create-business-account', component: BusinessComponent },
  
  //Bracelets
  { path: 'products/eden-bracelet', component: BraceletsComponent },
  { path: 'products/eden-bracelet/checkout', component: BraceletsComponent },
  { path: 'products/eden-bracelet/feedback/:braceletId', component: ReviewsComponent },

  //Misc
  { path: 'success/:plan', component: SuccessComponent },
  { path: 'cart', component: CartComponent },
  { path: 'tree-planting', component: TreePlantingComponent },
  { path: 'polls/:pollId', component: PollsComponent },
  { path: 'poll-results/:pollId', component: PollResultsComponent }
  //{ path: 'eco-gifts', component: GiftingComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
