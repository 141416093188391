<div *ngIf="bio" class="content">
    <div class="cover">
        <app-carousel [images]="bio.gallery" [fullscreen]="true"></app-carousel>
    </div>
    <div class="body">
        <div class="body-inner top">
            <div [class]="'tag ' + bio.status.name">{{ bio.status.displayName }}</div>
            <div class="title">
                <h1 class="title-text">
                    {{ bio.name }}
                </h1>
            </div>
            <div class="quote">
                <div>{{ bio.summary.quote.quote }}</div>
                <br/>
                <div>{{ bio.summary.quote.author }}</div>
            </div>
        </div>
        <div class="summary-container">
            <div class="sdg-subtitle">
                Project Summary
            </div>
            <div class="summary">
                <div class="summary-item">
                    <div class="sdg-title">
                        SDGs this project targets
                    </div>
                    <div class="image-row">
                        <img *ngFor="let image of bio.summary.images" class="image-row-image" [src]="'../../../assets/' + image" />
                    </div>
                </div>
                <div class="bullets">
                    <div *ngFor="let bullet of bio.summary.bullets" class="bullet">
                        <div>•&nbsp;&nbsp;</div>{{ bullet }}
                    </div>
                </div>
            </div>
            <div class="logo-container">
                <img class="project-logo" [src]="getProjectLogo()" />
            </div>
        </div>
        <div class="body-inner bottom">
            <video *ngIf="bio.video" class="video" controls>
                <source [src]="bio.video + '#t=0.1' | safeHtml : 'url'" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <div *ngFor="let content of bio.content">
                <div *ngIf="content.type === 'text'" class="text" [innerHtml]="content.data">
                </div>
                <div *ngIf="content.type === 'image'">
                    <img class="body-image" [src]="'../../../assets/' + content.image" />
                    <div class="caption">
                        <div>{{ content.caption }}</div>
                    </div>
                </div>
                <div *ngIf="content.type === 'subtitle'" class="subtitle">
                    {{ content.data }}
                </div>
                <div *ngIf="content.type === 'quote'" class="quote">
                    {{ content.data }}
                </div>
                <iframe 
                    *ngIf="content.type === 'google-maps'"
                    class="maps"
                    src="https://www.google.com/maps/d/u/0/embed?mid=1selxENxJDJjaoqkK8NHG6eW816eg5RE&ehbc=2E312F"
                ></iframe>
                <div *ngIf="content.type === 'list'" class="list">
                    <div *ngFor="let text of content.text" class="list-text">{{ text }}</div>
                </div>
                <div *ngIf="content.type === 'image-and-text'" class="image-and-text">
                    <img class="small-image" [src]="'../../../assets/' + content.image" />
                    <div class="small-text">{{ content.text }}</div>
                </div>
            </div>
            <app-faq *ngIf="bio.faqs" [faqInput]="bio.faqs"></app-faq>
            <div class="buttons">
                <div id="choose-your-eco-plan" routerLink="/products/eden-bracelet" class="button pointer clickable">
                    Buy A Bracelet
                </div>
                <div id="back-to-projects" routerLink="/our-projects" class="button pointer clickable">
                    Back to Projects
                </div>
            </div>
            <br />
        </div>
    </div>
</div>