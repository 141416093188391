import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Project, projects } from 'server/projects';
import { AuthService } from 'src/app/services/auth.service';
import { FooterService } from 'src/app/services/footer.service';
import { MissionComponent } from '../mission/mission.component';
import { getProjectUrl } from '../projects/projects.component';

export interface Plan {
  name: string,
  cost: string,
  tokens: number,
  selected: boolean;
  image: string;
  tagline: string;
}

export interface SelectedProject extends Project {
  tokens: number,
  index?: number,
}

export const plans: Plan[] = [
  {
    name: 'Kick-Starter',
    cost: '£4.00',
    tokens: 2,
    selected: false,
    image: '../../../assets/kick-starter.png',
    tagline: 'up to 2 projects',
  },
  {
    name: 'Sustainer',
    cost: '£8.00',
    tokens: 4,
    selected: false,
    image: '../../../assets/sustainer.png',
    tagline: 'up to 4 projects',
  },
  {
    name: 'Front-Runner',
    cost: '£16.00',
    tokens: 8,
    selected: false,
    image: '../../../assets/front-runner.png',
    tagline: 'up to 8 projects',
  },
];

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.css'],
})
export class ChoosePlanComponent implements OnInit, OnDestroy {
  public _blur: boolean;

  @Input() set blur(value: boolean) {
    this._blur = value;

    if (!this.blur) {
      this.authService.getPlan().subscribe(
        (data) => {
          const existingPlan = this.plans.find((plan) => plan.name === data[0].planSummary?.plan);
          if (existingPlan) {
            this.selectPlan(existingPlan, false);
            
            for (const project of data[0].planSummary.projects) {
              for (let i = 0; i < project.tokens; i++) {
                this.addToken(project);
              }
            }
          } else {
            this.selectPlan(this.plans[0]);
          }
        },
        () => {
          this.selectPlan(this.plans[0], false);
        }
      );
    }
  };
  
  get blur(): boolean {
    return this._blur;
  }

  @ViewChild("title") title;
  @ViewChild("summary") summary;
  @ViewChild("projects") projects;
  @ViewChild("projectScroll") scroll;
  @ViewChildren("project") scrollProjects: QueryList<ElementRef>;

  public modal = false;
  public changePlanModal = false;
  public infoProject?: Project;

  public projectsFirstRow: Project[] = [...projects];
  public plans: Plan[] = plans.map((plan) => {
    return {...plan};
  });
  public selectedProjects: SelectedProject[] = [];
  public text?: string;
  public dataState = true;
  public showSummary= false;

  public scrollPosition = 0;

  public getProjectUrl = getProjectUrl;

  private kickTool = false;
  private susTool = false;
  private frontTool = false;

  private subscriptions: Subscription[] = [];

  private authService: AuthService;
  private route: ActivatedRoute
  private router: Router;
  
  constructor(authService: AuthService, route: ActivatedRoute, router: Router) {
    this.authService = authService;
    this.route = route;
    this.router = router;
   }

  ngOnInit(): void {
    FooterService.footer = false;
    this.subscriptions.push(this.route.data.subscribe(data => {
      if(data.blur !== undefined) {
        this.blur = data.blur;
      }
    }));
  }

  ngOnDestroy(): void {
    for(const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public selectPlan(planToSelect: Plan, scroll = true): void {
    FooterService.fadeText = 'Choose Pojects and Click Button to Finalise Your Plan';

    for(const plan of this.plans){
      plan.selected = false;
    }
    planToSelect.selected = true;
    this.selectedProjects = this.selectedProjects.slice(0, planToSelect.tokens);
    if(scroll) {
      setTimeout(() => {
        this.projects.nativeElement.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }

  public addToken(project: Project): void {
    if(!this.canAdd()) {
      return;
    } 
    if(this.selectedProjects.find((selectedProject) => {
      return project.name === selectedProject.name;
    })) {
      let index = 0;
      for(let i = 0; i < this.selectedProjects.length; i++) {
        if(this.selectedProjects[i].name === project.name) {
          index = i;
          break;
        }
      }
      this.selectedProjects.splice(index, 0, {
        ...project,
        tokens: 1,
      })
    } else {
      this.selectedProjects.push({
        ...project,
        tokens: 1,
      });
    }

    

    if (this.getRemainingTokens() < 0) {
      const currentTokens = this.getSelectedPlan().tokens;

      for(const plan of this.plans) {
        if(plan.tokens === currentTokens * 2) {
          this.selectPlan(plan, false);
        }
      }
    }

    if(!this.canAdd()) {
      this.createPlan();
    }
  }

  public getEmptyProjects(): SelectedProject[] {
    const emptyProjects: SelectedProject[] = [];
    for(let i = this.selectedProjects.length; i < 8 ; i++) {
      emptyProjects.push({
        name: '',
        tokens: 1,
        multiplier: 3,
        text: '',
        index: i,
        crisis: '',
      });
    }
    return emptyProjects;
  }

  public canAdd(): boolean {
    const totalTokens: any | undefined = this.plans.find((plan) => plan.selected)?.tokens;
    if (!totalTokens) {
      return false;
    }
    return this.selectedProjects.length + 1 <= totalTokens || window.innerWidth > 600 && totalTokens < 8;
  }

  public getRemainingTokens(): number {
    const totalTokens: any | undefined = this.plans.find((plan) => plan.selected)?.tokens;
    if (!totalTokens) {
      return 0;
    } else {
      const remaining = totalTokens - this.selectedProjects.length;
      if(remaining !== 0){
        this.showSummary = false;
        FooterService.footer = false;
        FooterService.fadeText = FooterService.fadeText = 'Choose Pojects and Click Button to Finalise Your Plan';
      }
      return remaining;
    }
  }
  
  public getSelectedProjectsWithIndex(): SelectedProject[] {
    let i = 0;
    let projects = this.selectedProjects.map((project) => {
      i++;
      return {
        ...project,
        index: i
      };
    });
    return projects;
  }
  
  public getSelectedPlanName(): string {
    return this.plans.find((plan) => plan.selected)?.name;
  }

  public getCount(name: string): number {
    let total = 0;
    for(const project of this.selectedProjects.filter((project) => project.name === name)) {
      total = total + project.tokens;
    }
    return total;
  }

  public removeProject(project: SelectedProject) {
    this.selectedProjects.splice(project.index - 1, 1);


    for (const plan of this.plans) {
      if (plan.tokens === this.getSelectedPlan().tokens - this.getRemainingTokens()) {
        this.selectPlan(plan, false);
      }
    }
  }

  public createPlan(): void {
    if(this.selectedProjects.length === this.getSelectedPlan().tokens) {
      this.dataState = !this.dataState;
      this.showSummary = true;
      setTimeout(() => {
        this.summary.title.title.nativeElement.scrollIntoView({ behavior: 'smooth' });
        FooterService.footer = true;
      });
    }
  }

  public openModal(state: boolean, project?: Project): void {
    this.infoProject = project;
    this.modal = state;
  }

  public setOpen(event: Event, plan: string, force?: boolean): void {
    if (plan === 'Kick-Starter') {
      this.kickTool = force !== undefined ? force : this.kickTool ? false : true;
      this.susTool = false;
      this.frontTool = false;
    }
    if (plan === 'Sustainer') {
      this.kickTool = false;
      this.susTool = force !== undefined ? force : this.susTool ? false : true;
      this.frontTool = false;
    }
    if (plan === 'Front-Runner') {
      this.kickTool = false;
      this.susTool = false;
      this.frontTool = force !== undefined ? force : this.frontTool ? false : true;
    }
  }

  public getToolStatus(plan: string): boolean {
    if (plan === 'Kick-Starter') {
      return this.kickTool;
    }
    if (plan === 'Sustainer') {
      return this.susTool;
    }
    if (plan === 'Front-Runner') {
      return this.frontTool;
    }
  }

  public getSelectedPlan(): Plan {
    return this.plans.find((plan) => plan.selected);
  }

  public scrollProjectsUpDown(up = true): void {
    const el = this.scrollProjects.toArray()[0].nativeElement;
    let height = el.offsetHeight;
    const style = getComputedStyle(el);
    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    up ? this.scrollPosition++ : this.scrollPosition--;
    const maxScrollPositon = Math.ceil(projects.length / 2) - 2;

    if(this.scrollPosition > maxScrollPositon) {
      this.scrollPosition = maxScrollPositon;
    }

    if(this.scrollPosition < 0) {
      this.scrollPosition = 0;
    }
    
    this.scroll.nativeElement.scrollTo(
      {
        top: height * this.scrollPosition,
      }
    );
    
  }

  public goToEcoCoins(): void {
    MissionComponent.scroll = true;
    this.router.navigate(['/overview']);
  }

  public clearAll(): void {
    this.selectedProjects = [];
  }

  public randomSelect(): void {
    this.clearAll();
    for(let i = 0; i < 8; i++) {
      this.addToken(this.projectsFirstRow[Math.floor(Math.random() * this.projectsFirstRow.length)]);
    }
  }

  public print(thing: any) {
    console.log(thing);
  }
}
