export interface CustomPurchaseDetails {
    projectId: number,
    text: string,
    value: number,
    min: number,
    max: number | undefined,
    cost: number,
    nottingHill?: boolean,
}

export const PURCHASE_DETAILS: CustomPurchaseDetails[] = [
    {
        projectId: 1,
        text: '60 trees planted in Nepal',
        value: 60,
        min: 100000,
        max: 999999,
        cost: 54,
    },
    {
        projectId: 1,
        text: '150 trees planted in Nepal',
        value: 150,
        min: 1000000,
        max: 1999999,
        cost: 135,
    },
    {
        projectId: 1,
        text: '240 trees planted in Nepal',
        value: 240,
        min: 2000000,
        max: 2999999,
        cost: 216,
    },
    {
        projectId: 1,
        text: '330 trees planted in Nepal',
        value: 330,
        min: 3000000,
        max: 3999999,
        cost: 297,
    },
    {
        projectId: 1,
        text: '420 trees planted in Nepal',
        value: 420,
        min: 4000000,
        max: 4999999,
        cost: 378,
    },
    {
        projectId: 1,
        text: '510 trees planted in Nepal',
        value: 510,
        min: 5000000,
        max: undefined,
        cost: 459,
    },
    {
        projectId: 2,
        text: '60 trees planted in Madagascar',
        value: 60,
        min: 100000,
        max: 999999,
        cost: 54,
    },
    {
        projectId: 2,
        text: '150 trees planted in Madagascar',
        value: 150,
        min: 1000000,
        max: 1999999,
        cost: 135,
    },
    {
        projectId: 2,
        text: '240 trees planted in Madagascar',
        value: 240,
        min: 2000000,
        max: 2999999,
        cost: 216,
    },
    {
        projectId: 2,
        text: '330 trees planted in Madagascar',
        value: 330,
        min: 3000000,
        max: 3999999,
        cost: 297,
    },
    {
        projectId: 2,
        text: '420 trees planted in Madagascar',
        value: 420,
        min: 4000000,
        max: 4999999,
        cost: 378,
    },
    {
        projectId: 2,
        text: '510 trees planted in Madagascar',
        value: 510,
        min: 5000000,
        max: undefined,
        cost: 459,
    },
    {
        projectId: 3,
        text: '60 trees planted in Kenya',
        value: 60,
        min: 100000,
        max: 999999,
        cost: 54,
    },
    {
        projectId: 3,
        text: '150 trees planted in Kenya',
        value: 150,
        min: 1000000,
        max: 1999999,
        cost: 135,
    },
    {
        projectId: 3,
        text: '240 trees planted in Kenya',
        value: 240,
        min: 2000000,
        max: 2999999,
        cost: 216,
    },
    {
        projectId: 3,
        text: '330 trees planted in Kenya',
        value: 330,
        min: 3000000,
        max: 3999999,
        cost: 297,
    },
    {
        projectId: 3,
        text: '420 trees planted in Kenya',
        value: 420,
        min: 4000000,
        max: 4999999,
        cost: 378,
    },
    {
        projectId: 3,
        text: '510 trees planted in Kenya',
        value: 510,
        min: 5000000,
        max: undefined,
        cost: 459,
    },
    {
        projectId: 4,
        text: '200 meters^2 of land rewilded',
        value: 200,
        min: 100000,
        max: 999999,
        cost: 60,
    },
    {
        projectId: 4,
        text: '500 meters^2 of land rewilded',
        value: 500,
        min: 1000000,
        max: 1999999,
        cost: 150,
    },
    {
        projectId: 4,
        text: '800 meters^2 of land rewilded',
        value: 800,
        min: 2000000,
        max: 2999999,
        cost: 240,
    },
    {
        projectId: 4,
        text: '1100 meters^2 of land rewilded',
        value: 1100,
        min: 3000000,
        max: 3999999,
        cost: 330,
    },
    {
        projectId: 4,
        text: '1400 meters^2 of land rewilded',
        value: 1400,
        min: 4000000,
        max: 4999999,
        cost: 420,
    },
    {
        projectId: 4,
        text: '1700 meters^2 of land rewilded',
        value: 1700,
        min: 5000000,
        max: undefined,
        cost: 510,
    },
    {
        projectId: 5,
        text: '30kg of plastic removed from the ocean',
        value: 3000,
        min: 100000,
        max: 999999,
        cost: 60,
    },
    {
        projectId: 5,
        text: '70kg of plastic removed from the ocean',
        value: 7000,
        min: 1000000,
        max: 1999999,
        cost: 140,
    },
    {
        projectId: 5,
        text: '110kg of plastic removed from the ocean',
        value: 11000,
        min: 2000000,
        max: 2999999,
        cost: 220,
    },
    {
        projectId: 5,
        text: '150kg of plastic removed from the ocean',
        value: 15000,
        min: 3000000,
        max: 3999999,
        cost: 300,
    },
    {
        projectId: 5,
        text: '190kg of plastic removed from the ocean',
        value: 19000,
        min: 4000000,
        max: 4999999,
        cost: 380,
    },
    {
        projectId: 5,
        text: '230kg of plastic removed from the ocean',
        value: 23000,
        min: 5000000,
        max: undefined,
        cost: 460,
    },
    {
        projectId: 6,
        text: '12 people provided with solar lights',
        value: 12,
        min: 100000,
        max: 999999,
        cost: 60,
    },
    {
        projectId: 6,
        text: '24 people provided with solar lights',
        value: 24,
        min: 1000000,
        max: 1999999,
        cost: 120,
    },
    {
        projectId: 6,
        text: '36 people provided with solar lights',
        value: 36,
        min: 2000000,
        max: 2999999,
        cost: 180,
    },
    {
        projectId: 6,
        text: '48 people provided with solar lights',
        value: 48,
        min: 3000000,
        max: 3999999,
        cost: 240,
    },
    {
        projectId: 6,
        text: '60 people provided with solar lights',
        value: 60,
        min: 4000000,
        max: 4999999,
        cost: 300,
    },
    {
        projectId: 6,
        text: '72 people provided with solar lights',
        value: 72,
        min: 5000000,
        max: undefined,
        cost: 360,
    },
    {
        projectId: 7,
        text: '5 cookstoves provided',
        value: 5,
        min: 100000,
        max: 999999,
        cost: 55,
    },
    {
        projectId: 7,
        text: '12 cookstoves provided',
        value: 12,
        min: 1000000,
        max: 1999999,
        cost: 132,
    },
    {
        projectId: 7,
        text: '20 cookstoves provided',
        value: 20,
        min: 2000000,
        max: 2999999,
        cost: 220,
    },
    {
        projectId: 7,
        text: '27 cookstoves provided',
        value: 27,
        min: 3000000,
        max: 3999999,
        cost: 297,
    },
    {
        projectId: 7,
        text: '34 cookstoves provided',
        value: 34,
        min: 4000000,
        max: 4999999,
        cost: 374,
    },
    {
        projectId: 7,
        text: '42 cookstoves provided',
        value: 42,
        min: 5000000,
        max: undefined,
        cost: 462,
    },
    {
        projectId: 8,
        text: '200 meters^2 of coral reef saved',
        value: 200,
        min: 100000,
        max: 999999,
        cost: 60,
    },
    {
        projectId: 8,
        text: '500 meters^2 of coral reef saved',
        value: 500,
        min: 1000000,
        max: 1999999,
        cost: 150,
    },
    {
        projectId: 8,
        text: '800 meters^2 of coral reef saved',
        value: 800,
        min: 2000000,
        max: 2999999,
        cost: 240,
    },
    {
        projectId: 8,
        text: '1100 meters^2 of coral reef saved',
        value: 1100,
        min: 3000000,
        max: 3999999,
        cost: 330,
    },
    {
        projectId: 8,
        text: '1400 meters^2 of coral reef saved',
        value: 1400,
        min: 4000000,
        max: 4999999,
        cost: 420,
    },
    {
        projectId: 8,
        text: '1700 meters^2 of coral reef saved',
        value: 1700,
        min: 5000000,
        max: undefined,
        cost: 510,
    },
    {
        projectId: 9,
        text: '200 meters^2 of coral reef saved',
        value: 200,
        min: 100000,
        max: 999999,
        cost: 60,
    },
    {
        projectId: 9,
        text: '500 meters^2 of coral reef saved',
        value: 500,
        min: 1000000,
        max: 1999999,
        cost: 150,
    },
    {
        projectId: 9,
        text: '800 meters^2 of coral reef saved',
        value: 800,
        min: 2000000,
        max: 2999999,
        cost: 240,
    },
    {
        projectId: 9,
        text: '1100 meters^2 of coral reef saved',
        value: 1100,
        min: 3000000,
        max: 3999999,
        cost: 330,
    },
    {
        projectId: 9,
        text: '1400 meters^2 of coral reef saved',
        value: 1400,
        min: 4000000,
        max: 4999999,
        cost: 420,
    },
    {
        projectId: 9,
        text: '1700 meters^2 of coral reef saved',
        value: 1700,
        min: 5000000,
        max: undefined,
        cost: 510,
    },
];


export const FREE_PURCHASES: CustomPurchaseDetails[] = [
    {
        projectId: 1,
        text: '120 trees planted in Nepal',
        value: 120,
        min: 100000,
        max: undefined,
        cost: 24,
    },
    {
        projectId: 2,
        text: '120 trees planted in Madagascar',
        value: 120,
        min: 100000,
        max: undefined,
        cost: 24,
    },
    {
        projectId: 3,
        text: '120 trees planted in Kenya',
        value: 120,
        min: 100000,
        max: undefined,
        cost: 24,
    },
    {
        projectId: 4,
        text: '80 meters^2 of land rewilded',
        value: 80,
        min: 100000,
        max: undefined,
        cost: 24,
    },
    {
        projectId: 5,
        text: '12kg of plastic removed from the ocean',
        value: 1200,
        min: 100000,
        max: undefined,
        cost: 24,
    },
    {
        projectId: 6,
        text: '4 people provided with solar lights',
        value: 4,
        min: 100000,
        max: undefined,
        cost: 24,
    },
];

export const LETTINGS_PURCHASE_DETAILS: CustomPurchaseDetails[] = [
    {
        projectId: 1,
        text: '10 trees planted in Nepal',
        value: 10,
        min: 100000,
        max: undefined,
        cost: 10,
        nottingHill: true,
    },
    {
        projectId: 1,
        text: '20 trees planted in Nepal',
        value: 20,
        min: 100000,
        max: undefined,
        cost: 18,
    },
    {
        projectId: 2,
        text: '10 trees planted in Madagascar',
        value: 10,
        min: 100000,
        max: undefined,
        cost: 10,
        nottingHill: true,
    },
    {
        projectId: 2,
        text: '20 trees planted in Madagascar',
        value: 20,
        min: 100000,
        max: undefined,
        cost: 18,
    },
    {
        projectId: 3,
        text: '10 trees planted in Kenya',
        value: 10,
        min: 100000,
        max: undefined,
        cost: 10,
        nottingHill: true,
    },
    {
        projectId: 3,
        text: '20 trees planted in Kenya',
        value: 20,
        min: 100000,
        max: undefined,
        cost: 18,
    },
    {
        projectId: 4,
        text: '28 meters^2 of land rewilded',
        value: 28,
        min: 100000,
        max: undefined,
        cost: 10,
        nottingHill: true,
    },
    {
        projectId: 4,
        text: '50 meters^2 of land rewilded',
        value: 50,
        min: 100000,
        max: undefined,
        cost: 18,
    },
    {
        projectId: 5,
        text: '5kg of plastic removed from the ocean',
        value: 500,
        min: 100000,
        max: undefined,
        cost: 10,
        nottingHill: true,
    },
    {
        projectId: 5,
        text: '9kg of plastic removed from the ocean',
        value: 900,
        min: 100000,
        max: undefined,
        cost: 18,
    },
    {
        projectId: 6,
        text: '2 people provided with solar lights',
        value: 2,
        min: 100000,
        max: undefined,
        cost: 10,
        nottingHill: true,
    },
    {
        projectId: 6,
        text: '4 people provided with solar lights',
        value: 4,
        min: 100000,
        max: undefined,
        cost: 18,
    },
    {
        projectId: 7,
        text: '1 cookstove provided',
        value: 1,
        min: 100000,
        max: undefined,
        cost: 10,
        nottingHill: true,
    },
    {
        projectId: 7,
        text: '1 cookstoves provided',
        value: 1,
        min: 100000,
        max: undefined,
        cost: 18,
    },
    {
        projectId: 8,
        text: '33 meters^2 of coral reef saved',
        value: 33,
        min: 100000,
        max: undefined,
        cost: 10,
        nottingHill: true,
    },
    {
        projectId: 8,
        text: '60 meters^2 of coral reef saved',
        value: 60,
        min: 100000,
        max: undefined,
        cost: 18,
    },
    {
        projectId: 9,
        text: '33 meters^2 of coral reef saved',
        value: 33,
        min: 100000,
        max: undefined,
        cost: 10,
        nottingHill: true,
    },
    {
        projectId: 9,
        text: '60 meters^2 of coral reef saved',
        value: 60,
        min: 100000,
        max: undefined,
        cost: 18,
    },
]

export interface Purchase {
    projectId: number;
    value: number;
    quantity: number;
}

export interface CustomBusinessPurchase extends Purchase {
    cost: number,
    date: Date | string,
    email: string,
    reference: string,
}

export interface EstateAgentPurchase extends CustomBusinessPurchase {
    buyer: string,
    address: string,
    houseCost: number,
    image: File | string,
    letting?: boolean,
    estateAgentPurchase: boolean;
}

export interface BusinessSubsription {
    ammount: number;
    purchases: CustomBusinessPurchase[],
}