import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-new-home-page',
  templateUrl: './new-home-page.component.html',
  styleUrls: ['./new-home-page.component.css']
})
export class NewHomePageComponent implements OnInit {
  private authService: AuthService;
  private seoService: SeoService;

  public projectLogos: string[] = [
    'eden-reforestation.png',
    'solarAid.png',
    'plastics.png',
    'buglife.jpg',
    '8-9.png',
  ];

  public newsletterPopup: boolean;


  constructor(authService: AuthService, seoService: SeoService) {
    this.authService = authService;
    this.seoService = seoService;
  }

  ngOnInit(): void {
    this.seoService.setSeo('Eden Greenspace | Environmental Action Beyond Carbon Offsetting', 'Fund the world’s most effective carbon removal and biodiversity projects. Become a Credible Green Business. Boost Your Sales and Employee Satisfaction.');

    setTimeout(() => {
      this.newsletterPopup = !(this.authService.getNewsletterPopup() === 'false');
    }, 10000);
  }

}
