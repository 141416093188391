import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { projects } from 'server/projects';
import { Purchase } from 'server/purchases';
import { CartService } from 'src/app/services/cart.service';
import { SeoService } from 'src/app/services/seo.service';
import { BIOS } from '../projects/projects.component';
declare const fbq: any;

interface ProjectGroup {
  id: number;
  title: string;
  text: string;
  projectId?: number;
  gallery: string[];
}

interface Gift {
  groupId: number;
  cost: number;
  value: number;
  name: string;
  line1: string;
  line2: string;
  line3: string;
  location?: string;
  flipped?: boolean;
  gallery: string[];
}

interface Location {
  name: string;
  image: string;
}

export interface CartItem extends Purchase {
  groupId: number;
  cost: number;
  name: string;
  location?: string;
  projectId: number;
}

export function getGallery(id: number, start: number, end: number, pngs: number[] = []): string[] {
  const gallery: string[] = [];
  for(let i = start; i <= end; i++) {
    const type = pngs.includes(i) ? 'png' : 'jpg'
    gallery.push(`gift-${id}-${i}.${type}`);
  }

  return gallery;
}

export const PROJECT_GROUPS: ProjectGroup[] = [
  {
    id: 1,
    title: 'Plant Trees',
    text: 'Plant XXX trees',
    gallery: getGallery(1, 1, 10),
  },
  {
    id: 2,
    title: 'Ocean Plastic Removal',
    text: 'Remove XXX bottles',
    projectId: 5,
    gallery: getGallery(2, 1, 12),
  },
  {
    id: 3,
    title: 'Rewilding Meadows',
    text: 'Rewild XXXm\u00b2',
    projectId: 4,
    gallery: getGallery(3, 1, 11),
  },
  {
    id: 4,
    title: 'Light Up Lives',
    text: 'Provide XXX light(s)',
    projectId: 6,
    gallery: getGallery(4, 1, 11, [2,3,4,5,7,8]),
  }
];

const GIFTS: Gift[] = [
  {
    groupId: 1,
    cost: 10,
    value: 10,
    name: '10 TREES',
    line1: 'Plant',
    line2: 'Plant a small forest',
    line3: '',
    gallery: getGallery(1, 1, 3),
  },
  {
    groupId: 1,
    cost: 18,
    value: 20,
    name: '20 TREES',
    line1: 'Plant',
    line2: 'Plant a medium forest',
    line3: '',
    gallery: getGallery(1, 4, 6),
  },
  {
    groupId: 1,
    cost: 40,
    value: 50,
    name: '50 TREES',
    line1: 'Plant',
    line2: 'Plant a large forest',
    line3: '',
    gallery: getGallery(1, 7, 10),
  },
  {
    groupId: 2,
    cost: 5,
    value: 100,
    name: '100 PLASTIC BOTTLES',
    line1: 'Clean our oceans of',
    line2: "That's 1kg of plastic",
    line3: "cleared from the UK's oceans.",
    gallery: getGallery(2, 1, 4),
  },
  {
    groupId: 2,
    cost: 10,
    value: 200,
    name: '200 PLASTIC BOTTLES',
    line1: 'Clean our oceans of',
    line2: "That's 2kg of plastic",
    line3: "cleared from the UK's oceans.",
    gallery: getGallery(2, 5, 8),
  },
  {
    groupId: 2,
    cost: 25,
    value: 500,
    name: '500 PLASTIC BOTTLES',
    line1: 'Clean our oceans of',
    line2: "That's 5kg of plastic",
    line3: "cleared from the UK's oceans.",
    gallery: getGallery(2, 9, 12),
  },
  {
    groupId: 3,
    cost: 10,
    value: 25,
    name: 'SMALL 25m\u00b2 MEADOW',
    line1: 'Rewild a',
    line2: 'Wildflower meadows help',
    line3: 'suffering bees survive.',
    gallery: getGallery(3, 1, 4),
  },
  {
    groupId: 3,
    cost: 18,
    value: 50,
    name: 'LARGE 50m\u00b2 MEADOW',
    line1: 'Rewild a',
    line2: 'Providing room for bee',
    line3: 'populations to grow.',
    gallery: getGallery(3, 5, 8),
  },
  {
    groupId: 3,
    cost: 40,
    value: 100,
    name: 'HUGE 100m\u00b2 MEADOW',
    line1: 'Rewild a',
    line2: 'Cultivating a large ecosystem',
    line3: 'for Bees to thrive within.',
    gallery: getGallery(3, 9, 11),
  },
  {
    groupId: 4,
    cost: 8,
    value: 1,
    name: '1 SOLAR LIGHT',
    line1: 'Provide',
    line2: 'Prevents a tonne \n of CO2 emissions.',
    line3: "Light up a family's world.",
    gallery: getGallery(4, 1, 4, [2, 3, 4]),
  },
  {
    groupId: 4,
    cost: 40,
    value: 5,
    name: '5 SOLAR LIGHTS',
    line1: 'Provide',
    line2: 'Prevents 5 tonnes \n of CO2 emissions.',
    line3: 'Lights up 5 homes.',
    gallery: getGallery(4, 5, 8, [5, 7, 8]),
  },
  {
    groupId: 4,
    cost: 80,
    value: 10,
    name: '10 SOLAR LIGHTS',
    line1: 'Provide',
    line2: 'Prevents 10 tonnes \n of CO2 emissions.',
    line3: 'Lights up a village.',
    gallery: getGallery(4, 9, 11),
  },
];

export function getGroupImage(group: number, location?: string): string {
  const projectGroup = PROJECT_GROUPS.find(pg => pg.id === group);
  
  if(projectGroup.id === 1) {
    switch (location) {
      case 'Kenya':
        return '../../../assets/kenya-logo.PNG';
      case 'Mozambique':
        return '../../../assets/moz-logo.PNG';
      case 'Madagascar':
          return '../../../assets/mada-logo.PNG';
      default:
        return '../../../assets/trees-logo.PNG';
    }
    
  } else {
    return projects.find(project => project.id === projectGroup.projectId).image;
  }
}


@Component({
  selector: 'app-gifting',
  templateUrl: './gifting.component.html',
  styleUrls: ['./gifting.component.css']
})
export class GiftingComponent implements OnInit {
  @ViewChildren("gallery") gallery: QueryList<ElementRef>;
  @ViewChildren("galleryImage") galleryImage: QueryList<ElementRef>;

  public fbq = fbq;
  //public fbq = function fbq(thing: string, thing2: string) { return thing };

  public scrollPositions = [0, 0, 0, 0];
  public scrollLiteral = [0, 0, 0, 0];

  public projectGroups = PROJECT_GROUPS;
  public gifts = GIFTS;
  public getGroupImage = getGroupImage;

  public locations: Location[] = [
    {
      name: 'Kenya',
      image: 'kenya-logo.PNG'
    },
    {
      name: 'Mozambique',
      image: 'moz-logo.PNG'
    },
    {
      name: 'Madagascar',
      image: 'mada-logo.PNG'
    },

  ];

  public cart: CartItem[] = [];
  public modal = false;
  public xHidden = false;

  private router: Router;
  private seoService: SeoService;
  
  constructor(router: Router, seoService: SeoService) {
    this.router = router;
    this.seoService = seoService;
  }

  ngOnInit(): void {
    this.seoService.setSeo('Tree Planting Gift. Buy Trees for Your Friends & Family as a Gift.', 'Send the gift of trees to your loved ones along with a personalised and signed certificate. For only £1 per tree.');
  }

  public getGifts(id: number): Gift[] {
    return this.gifts.filter(gift => gift.groupId === id);
  }

  public addToCart(gift: Gift): void {
    const cartItem = this.cart.find(cartItem => 
      cartItem.groupId === gift.groupId 
      && cartItem.cost === gift.cost 
      && cartItem.location === gift.location);
    if(cartItem) {
      cartItem.quantity = cartItem.quantity + 1;
    } else {
      this.cart.push(
        {
          groupId: gift.groupId,
          cost: gift.cost,
          value: gift.value,
          quantity: 1,
          name: gift.name,
          location: gift.location,
          projectId: this.getProjectId(gift),
        }
      );
    }

    CartService.cart = this.cart;
    this.modal = true;
  }

  private getProjectId(gift: Gift): number {
    if (gift.groupId === 1) {
      switch (gift.location) {
        case 'Kenya':
          return 3;
        case 'Mozambique':
          return 1;
        case 'Madagascar':
          return 2;
      }
    } else {
      return PROJECT_GROUPS.find((group) => group.id === gift.groupId).projectId;
    }
  }

  public getProjectLink(group: number): string {
    const projectGroup = PROJECT_GROUPS.find(pg => pg.id === group);
    
    if(projectGroup.id === 1) {
      return '/our-projects/tree-planting-in-mozambique';
    } else {
      return `/our-projects/${BIOS.find((bio) => projectGroup.projectId === bio.projectId).url}`;
    }
  }

  private getWidth(el: any): any {
    let width = el.offsetWidth;
    const style = getComputedStyle(el);
    width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    return width;
  }

  public scrollGallery(id: number, right = true): void {
    const gallery = this.gallery.toArray()[id-1].nativeElement;
    
    const width = this.getWidth(gallery.children[this.scrollPositions[id-1]]);

    const indexNext = this.scrollPositions[id - 1] + 1;
    let widthNext;
    
    if(indexNext < gallery.children.length) {
      widthNext = this.getWidth(gallery.children[indexNext]);
    }

    if(right && !widthNext) {
      return;
    }

    const indexPrev = this.scrollPositions[id - 1] - 1;
    let widthPrev;
    
    if(indexPrev > -1) {
      widthPrev = this.getWidth(gallery.children[indexPrev]);
    }

    if(!right && !widthPrev) {
      return;
    }

    const maxScrollPosition = gallery.children.length - 1;

    if(right) {
      if(this.scrollPositions[id-1] === 0) {
        let galleryWidth = gallery.offsetWidth;
        //const style = getComputedStyle(gallery);
        //galleryWidth += parseInt(style.paddingLeft) + parseInt(style.paddingRight);
        this.scrollLiteral[id-1] = (width - galleryWidth/2) + (widthNext/2);
      } else {
        this.scrollLiteral[id-1] = this.scrollLiteral[id-1] + (width + widthNext)/2;
      }
      this.scrollPositions[id-1]++;
    } else {
      this.scrollLiteral[id-1] = this.scrollLiteral[id-1] - (width + widthPrev)/2;
      this.scrollPositions[id-1]--;
    }

    if(this.scrollPositions[id-1] > maxScrollPosition) {
      this.scrollPositions[id-1] = maxScrollPosition;
    }

    if(this.scrollPositions[id-1] < 0) {
      this.scrollPositions[id-1] = 0;
    }

    if(this.scrollPositions[id-1] === 1) {

    }

    this.gallery.toArray()[id-1].nativeElement.scrollTo(
      {
        left: this.scrollLiteral[id-1],
      }
    );
  }

}
