import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Plan, SelectedProject } from '../choose-plan/choose-plan.component';
declare var Stripe: any;

export function createImpactText(project: SelectedProject): string {
  const value = project.tokens * project.multiplier;
  return project.text.replace('xxx', value.toString());
}

@Component({
  selector: 'app-plan-summary',
  templateUrl: './plan-summary.component.html',
  styleUrls: ['./plan-summary.component.css']
})
export class PlanSummaryComponent implements OnInit {
  @Input() plans?: Plan[];
  @Input() selectedProjects?: SelectedProject[];
  @Input() dataState?: boolean;
  @Input() blur = true;

  @ViewChild("title") title;

  private stripe = Stripe('pk_live_51IX4vmBDboGHB3gmCr8cmjFzg0aO9LxJHErrbE8FAtOh806Mb3PWjkPFR3A6g4z4ySI1mlfoToieenXke3iuFP1A00zZvlxg13');
  //private stripe = Stripe('pk_test_51IX4vmBDboGHB3gmxC86oDlimVDH8gS6qAZ0QFAT6BtWq2aYrO4VCosc457AOLotPFFMfM0hK7bOTlgLMxzwQgYT00rY5mAx2D');

  public displayProjects: SelectedProject[] = [];
  public userName?: string;
  public loggedIn = false;
  public existing = false;
  public message?: string;
  public loading = false;
  public retry = false;
  public tooltipOpen = false;
  
  private invoice?: string;

  private authService: AuthService;
  private router: Router;

  constructor(authService: AuthService, router: Router) {
    this.authService = authService;
    this.router = router;
  }

  ngOnInit(): void {
    this.authService.getAccountName().subscribe((account) => {
      this.loggedIn = true;
      this.existing = true;

      this.userName = 'Logged in as: ' + account.user.name;
    },
    (err) => {
      this.userName = 'Sign Up'
    });
  }

  public login(event?: string): void {
    this.authService.getAccountName().subscribe((account) => {
      this.loggedIn = true;
      this.userName = 'Logged in as: ' + account.user.name;
      if(event) {
        this.message = '';
        this.retry = false;
        this.loading = true;
        this.authService.pay({
          name: this.userName,
          plan: {
            plan: this.getSelectedPlan().name,
            projects: this.displayProjects,
          },
          paymentMethodId: event,
          invoiceId: this.invoice,
        }).subscribe((result) => {
          this.loading = false;
          if(!result.latest_invoice) {
            this.message = 'There was an error with your card. Please try again';
            this.retry = true;
          } else if(result.status === 'active') {
            this.message = 'Payment successful!'
            this.retry = false;
            this.redirect();
          } else if(result.latest_invoice.payment_intent.status === 'requires_payment_method') {
            this.invoice = result.latest_invoice.id;
            this.message = 'There was an error with your card. Please try again';
            this.retry = true;
          } else if (result.latest_invoice.payment_intent.status === 'requires_action') {
            this.message = 'Awaiting confirmation';
            this.stripe.confirmCardPayment(result.latest_invoice.payment_intent.client_secret, {
              payment_method: event,
            }).then((res) => {
              if (res.error) {
                this.invoice = result.latest_invoice.id;
                this.message = 'There was an error with your card. Please try again';
                this.retry = true;
              } else {
                if (res.paymentIntent.status === 'succeeded') {
                  this.message = 'Payment successful!'
                  this.redirect();
                }
              }
            })
          }
        });
      }
    },
    (err) => {
      this.userName = 'You must login or create an account \n to pay for your plan.'
    });
  }

  public getSelectedProjects(): SelectedProject[] {
    // Clone selectedProjects.
    const selectedProjects = [];
    for(const selectedProject of this.selectedProjects) {
      selectedProjects.push(
        {...selectedProject}
      );
    }
    
    this.displayProjects = [];
    let name: string;
    for(const selectedProject of selectedProjects) {
      if(selectedProject.name !== name) {
        name = selectedProject.name;
        this.displayProjects.push(selectedProject);
      } else {
        this.displayProjects.map((displayProject) => {
          if(displayProject.name === name) {
            displayProject.tokens++;
          }
        });
      }
    }

    return this.displayProjects;
  }

  public getSelectedPlan(): Plan {
    return this.plans.find((plan) => plan.selected);
  }

  public createImpactText(project: SelectedProject): string {
    let value = project.tokens * project.multiplier;
    
    let months: number | undefined;
    
    if(project.multiplier === 0.1) {
      if(project.tokens % 10 === 0) {
        value = project.tokens / 1;
      } else {
        value = project.tokens;
        months = 10;
      }
    }

    let returnValue = project.text.replace('xxx', value.toString());

    if(value === 1) {
      returnValue = returnValue.replace('s /', ' /');
    }

    if(months) {
      returnValue = returnValue.replace('month', months.toString() + ' months');
    }
    
    return returnValue;
  }

  public confirm() {
    this.message = '';
    this.loading = true;
    this.authService.pay({
      name: this.userName,
      plan: {
        plan: this.getSelectedPlan().name,
        projects: this.displayProjects,
      },
      paymentMethodId: undefined,
    }).subscribe((result) => {
      console.log(result);
      this.loading = false;
      if(result.status === 'active') {
        if(this.retry) {
          this.message = 'Payment successful!'
        } else {
          this.message = 'Plan updated successfully'
        }
        this.retry = false;
        this.redirect();
      } else if(!result.latest_invoice) {
        this.retry = true;
        this.message = 'Please enter payment details for your subscription';
      } else if(result.latest_invoice.payment_intent.status === 'requires_payment_method') {
        this.invoice = result.latest_invoice.id;
        this.message = 'There was an error with your card. Please try again';
        this.retry = true;
      } else {
        this.message = 'There was an error with your card. Please go to "My Account" to update your card details"';
      }
    },
    (err) => {
      //this.router.navigate(['/login']);
      this.retry = true;
      this.loading = false;
      this.message = 'Please enter payment details for your subscription';
    }
    );
  }

  private redirect() {
    this.router.navigate(['success', this.getSelectedPlan().name]);
  }

  public switch(event: boolean) {
    if(event) {
      this.userName = 'Sign Up';
    } else {
      this.userName = 'Login';
    }
  }
}
