<div *ngIf="isBusiness" class="content">
    <app-title text="Branch leaderboards"></app-title>
    <app-back-button></app-back-button>
    <div class="total-impacts">
        <div class="total-impact-container">
            <div class="total-impact-graphic">
                <img class="total-impact-image" src="../../../assets/total-trees.png">
                <div class="total-impact-value" [style]="
                    '--f:' +
                    'min(' + 
                    (25/(maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 0.5 * (maxTotalsLength >= 1 ? maxTotalsLength : 3 )) + 
                    'vw,' +
                    (400/(maxTotalsLength >= 3 ? maxTotalsLength : 3)  + 0.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 )) +
                    'px);' +
                    '--m:' + 
                    (1.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 55) +
                    '%'
                ">
                    {{ totalTrees }}
                </div>
            </div>
            <div class="total-impact-text">
                <div>Total Trees</div> <span class="nowrap">Planted
                    <i class="fa fa-question-circle token" style="font-size: min(calc(5vw), 30px)" tabindex="0"
                        tooltip='This counter allows you to track the total number of trees you have planted, across our global network of tree-planting projects. Watch it grow each time you support any of our tree-planting projects.'
                        [options]="ttConfig">
    
                    </i></span>
            </div>
        </div>
        <div class="total-impact-container">
            <div class="total-impact-graphic">
                <img class="total-impact-image" src="../../../assets/total-CO2.png">
                <div class="total-impact-value" [style]="
                    '--f:' +
                    'min(' +
                    (25/(maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 0.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 )) + 
                    'vw,' +
                    (400/(maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 0.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 )) +
                    'px);' +
                    '--m:' + 
                    (1.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 55) +
                    '%'
                ">
                    {{ totalCO2 }}
                </div>
            </div>
            <div class="total-impact-text">
                <div>Total CO2</div> <span class="nowrap">Removed
                    <i class="fa fa-question-circle token" style="font-size: min(calc(5vw), 30px)" tabindex="0"
                        tooltip='We carefully calculate the total CO2 emmissions you have removed by adding together the impact of all trees planted and solar lights provided across our tree-planting and solar lighting projects. We use an aggregate of studies carried out on our trees to ensure the most accurate CO2 figure per tree (150kg per tree).'
                        [options]="ttConfig">
                    </i></span>
    
            </div>
        </div>
        <div class="total-impact-container">
            <div class="total-impact-graphic">
                <img class="total-impact-image" src="../../../assets/total-land.png">
                <div class="total-impact-value" [style]="
                    '--f:' + 
                    'min(' +
                    (25/(maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 0.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 )) + 
                    'vw,' +
                    (400/(maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 0.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 )) + 
                    'px);' +
                    '--m:' + 
                    (1.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 55) +
                    '%'
                ">
                    {{ totalLand }}
                </div>
            </div>
            <div class="total-impact-text">
                <div>Total Land</div> <span class="nowrap">Afforested
                    <i class="fa fa-question-circle token" style="font-size: min(calc(5vw), 30px)" tabindex="0"
                        [tooltip]="'This counter shows the total land you have afforested, including all land rewilded via our Wildflower Rewilding project, and all afforested land across our tree-planting projects (1m\u00b2 per tree planted).'"
                        [options]="ttConfig">
    
                    </i></span>
    
            </div>
        </div>
    </div>
    <div class="controls">
        <div class="toggle pointer">
            <div class="toggle-item-left" [attr.data-active]="toggle === 'leaderboard'" (click)="toggle = 'leaderboard'">
                Leaderboard
            </div>
            <div class="toggle-item-right" [attr.data-active]="toggle === 'recent'" (click)="toggle = 'recent'">
                Recent
            </div>
        </div>
        <input *ngIf="toggle === 'leaderboard'" type="text" class="control" placeholder="Search Branch Name"
            (ngModelChange)="search($event)" [ngModel]="searchTerm">
    </div>
    <div class="orders" *ngIf="toggle === 'leaderboard'">
        <div class="order bold">
            <div class="cell branch heading">Branch</div>
            <div class="cell impact-tracker heading">Impact</div>
        </div>
        <div *ngFor="let item of displayLeaderboard; let i = index" class="order">
            <div class="cell branch-container">
                <img *ngIf="i < 3" class="branch-image"/>
                <div *ngIf="i > 2" class="branch-image">{{ i + 1 }}th</div>
                <div class="branch-text">{{ item.branch }}</div>
            </div>
            <div class="cell impact-tracker values" [style]="
                '--f:' 
                + 'min(' 
                + 175/maxTableLength 
                + 'px,'
                + 13/maxTableLength
                + 'vw)'
            ">
                <div class="value-item">
                    <img class="value-image" src="../../../assets/trees-logo.png"/>
                    <div class="value-text">{{ item.trees }}</div>
                </div>
                <div class="value-item">
                    <img class="value-image" src="../../../assets/plastics-logo.png"/>
                    <div class="value-text">{{ item.plastics/100 }}kg</div>
                </div>
                <div class="value-item">
                    <img class="value-image" src="../../../assets/bees-logo.png"/>
                    <div class="value-text">{{ item.rewilding }}m<sup>2</sup></div>
                </div>
                <div class="value-item">
                    <img class="value-image" src="../../../assets/solar-logo.png" />
                    <div class="value-text">{{ item.solarLighting === 1 ? item.solarLighting + ' light' : item.solarLighting + ' lights'}}</div>
                </div>
                <div class="value-item">
                    <img class="value-image" src="../../../assets/house-logo.png" />
                    <div class="value-text">{{ item.houses }}</div>
                </div>
                <div class="value-item">
                    <img class="value-image" src="../../../assets/letting-logo.png" />
                    <div class="value-text">{{ item.lettings }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="recent-orders" *ngIf="toggle === 'recent'">
        <div class="order bold">
            <div class="cell date heading">Date</div>
            <div class="cell branch-recent heading">Branch</div>
            <div class="cell impact-tracker-recent heading">Impact</div>
        </div>
        <div *ngFor="let item of recentOrders" class="order">
            <div class="cell date">
                {{ item.order.date }}
            </div>
            <div class="cell branch-recent">
                {{ item.branch }}
            </div>
            <div class="cell impact-tracker-recent values" [style]="
                    '--f:' 
                    + 'min(' 
                    + 210/maxTableLength 
                    + 'px,'
                    + 20/maxTableLength
                    + 'vw)'
                ">
                <div *ngIf="item.order.projectId === 1 || item.order.projectId === 2 || item.order.projectId === 3" class="value-item-recent">
                    <img class="value-image" src="../../../assets/trees-logo.png" />
                    <div class="value-text">{{ item.order.value }}</div>
                </div>
                <div *ngIf="item.order.projectId === 5" class="value-item-recent">
                    <img class="value-image" src="../../../assets/plastics-logo.png" />
                    <div class="value-text">{{ item.order.value/100 }}kg</div>
                </div>
                <div *ngIf="item.order.projectId === 4" class="value-item-recent">
                    <img class="value-image" src="../../../assets/bees-logo.png" />
                    <div class="value-text">{{ item.order.value }}m<sup>2</sup></div>
                </div>
                <div *ngIf="item.order.projectId === 6" class="value-item-recent">
                    <img class="value-image" src="../../../assets/solar-logo.png" />
                    <div class="value-text">{{ item.order.value === 1 ? item.order.value + ' light' : item.order.value
                        + ' lights'}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isBusiness" class="content">
    <div class="unauth-text">
        Please <span class="link pointer" routerLink="/login">login</span> to an authorised business account to access this
        page.
        <br /> <br />
    </div>
</div>
