import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-tab',
  templateUrl: './side-tab.component.html',
  styleUrls: ['./side-tab.component.css']
})
export class SideTabComponent implements OnInit {
  @Input() text: string;
  @Input() disabled = false;
  @Input() button = true;
  @Input() open = false;

  constructor() { }

  ngOnInit(): void {
  }

}
