<div *ngIf="!disable && isAdmin" class="text-center">
    <h1>Newsletter Section</h1>
    <h2>Category:</h2>
    <select [(ngModel)]="blog.category">
        <option value="newsletters">Newsletter</option>
        <option value="articles">Articles</option>
        <option value="project-updates">Project Updates</option>
        <option value="the-team">The Team</option>
    </select>
    <h2>Title:</h2>
    <input class="form-control" [(ngModel)]="blog.title">
    <h2>Author:</h2>
    <input class="form-control" [(ngModel)]="blog.author">
    <h2>Date:</h2>
    <input type="date" class="form-control" [(ngModel)]="blog.date">
    <h2>Read Time</h2>
    <input class="form-control" type="number" [(ngModel)]="blog.readTime">
    <h2>Summary:</h2>
    <textarea class="form-control" [(ngModel)]="blog.summary"></textarea>
    <ng-container *ngIf="!edit">
        <h2>Thumbnail:</h2>
        <input class="form-control" type="file" accept="image/*" (change)="thumbnailChange($event)">
    </ng-container>
    <h2>URL</h2>
    <div>Don't include 'https://edengreenspace.co.uk/articles/' at the start! Just the very last part of the url please!</div>
    <input class="form-control" [(ngModel)]="blog.url">
    <h2>Meta Title</h2>
    <input class="form-control" [(ngModel)]="blog.metaTitle">
    <h2>Meta Description</h2>
    <input class="form-control" [(ngModel)]="blog.metaDescription">
    <h2>Tags:</h2>
    <div>
        <button *ngFor="let tag of tags" [attr.data-selected]="blog.tags.includes(tag)" (click)="addTag(tag)">{{ tag }}</button>
    </div>
    <ng-container *ngIf="!edit">
        <h2>Content:</h2>
        <div *ngFor="let content of blog.content">
            <textarea *ngIf="content.type === 'text'" class="text" placeholder="Add some text" [(ngModel)]="content.data"></textarea>
            <textarea *ngIf="content.type === 'quote'" class="text" placeholder="Add a quote" [(ngModel)]="content.data"></textarea>
            <input *ngIf="content.type === 'image'" class="form-control" type="file" accept="image/*"
                (change)="imageChange($event, content)">
            <input *ngIf="content.type === 'subtitle'" class="form-control" placeholder="Add subtitle" [(ngModel)]="content.data">
            <textarea *ngIf="content.type === 'html'" class="form-control" placeholder="Add html" [(ngModel)]="content.data"></textarea>
            <button (click)="remove(content)" 
                class="btn btn-success">
                Remove
            </button>
        </div>
        <h2>Add Content:</h2>
        <button (click)="addText()" 
            class="btn btn-success">
            Add Text
        </button>
        <button (click)="addQuote()" 
            class="btn btn-success">
            Add Quote
        </button>
        <button (click)="addSubtitle()" 
            class="btn btn-success">
            Add Subtitle
        </button>
        <button (click)="addImage()" 
            class="btn btn-success">
            Add Image
        </button>
        <button (click)="addHTML()" 
            class="btn btn-success">
            Add HTML
        </button>
    </ng-container>
    <h2>{{ edit ? 'Save Changes:' : 'Upload:' }}</h2>
    <button (click)="onUpload()" 
        class="btn btn-success">
        {{ edit ? 'Save Changes' : 'Upload' }}
    </button>
    <h1>Business Section</h1>
    <h2>Account Username (email address):</h2>
    <input  class="form-control" [(ngModel)]="purchase.email">
    <h2>Choose Project</h2>
    <select (change)="projectChange($event)">
        <option *ngFor="let project of projects" [value]="project.id" [innerHTML]="project.name"></option>
    </select>
    <h2>Order Date:</h2>
    <input type="date" class="form-control" [(ngModel)]="purchase.date">
    <h2>Cost in £:</h2>
    <input type="number" class="form-control" [(ngModel)]="purchase.cost">
    <h2>Impact:</h2>
    <div>Make sure it's the right units for the project! Note: Plastics should be equiv number of bottles (100 per kg), solar lights is in terms of people (5 per light).</div>
    <input type="number" class="form-control" [(ngModel)]="purchase.value">
    <h2>Certificate:</h2>
    <input class="form-control" type="file" accept="image/*" (change)="certificateChange($event)">
    <button (click)="addPurchase()" class="btn btn-success">
        Add Purchase
    </button>
    <h1>Impact Section</h1>
    <h2>Total Impact:</h2>
    <div class="impact-row">
        <h3 class="row-item">Nepal</h3>
        <h3 class="row-item">Madagascar</h3>
        <h3 class="row-item">Kenya</h3>
        <h3 class="row-item">Rewilding (m^2)</h3>
        <h3 class="row-item">Plastics (kgs)</h3>
        <h3 class="row-item">Solar Lights (people)</h3>
    </div>
    <div class="impact-row">
        <h3 *ngFor="let impact of totalImpact" class="row-item">
            {{ impact.value }}
        </h3>
    </div>
    <h2>Requested Impact:</h2>
    <div class="impact-row">
        <h3 *ngFor="let impact of requestedImpact" class="row-item">
            {{ impact.value }}
        </h3>
    </div>
    <button (click)="completeImpact()">Completed Request</button>
    <h2>Completed Impact:</h2>
    <div *ngFor="let item of completedImpacts">
        <h3 class="row-item">{{ getDate(item._id) }}</h3>
        <div class="impact-row">
            <h3 *ngFor="let impact of item.impact" class="row-item">
                {{ impact.value }}
            </h3>
        </div>
    </div>
    <ng-container *ngIf="usersData">
        <app-dropdown dropdown="Accounts" contentMode="true">
            <input type="text" placeholder="Search Accounts" (ngModelChange)="search($event)" [ngModel]="searchTerm">
            <app-dropdown *ngFor="let user of displayUsersData" [dropdown]="user.username" contentMode="true" smallText="true">
                <h2>Last Login:</h2>
                <div>{{ formatDate(user.lastLogin) }}</div>
                <ng-container *ngIf="user.expectedOrder">
                    <h2>Expected Order:</h2>
                    <div>{{ user.expectedOrder }}</div>
                </ng-container>
                <h2>Purchases:</h2>
                <div class="purchases"> 
                    <div class="order" *ngFor="let purchase of user.purchases">
                        <h3>Date:</h3>
                        <div>{{ purchase.date }}</div>
                        <h3>Cost:</h3>
                        <div>£{{ purchase.cost }}</div>
                        <h3>Impact:</h3>
                        <div>{{ purchase.projectId === 5 ? purchase.value/100 : purchase.value }} {{ purchase.projectId ? getImpactText(purchase.projectId) : '' }}</div>
                    </div>
                </div>
                <h2>Downloads:</h2>
                <div *ngFor="let record of user.downloadRecords">
                    {{ record }}
                </div>
                <h2>Account Setup:</h2>
                <div *ngFor="let step of user.accountSteps">
                    <div *ngFor="let subStep of step.steps">
                        {{ subStep.title }}: {{ subStep.done }}
                    </div>
                </div>
            </app-dropdown>
        </app-dropdown>
    </ng-container>
    <ng-container *ngIf="bracelets">
        <app-dropdown dropdown="Bracelets" contentMode="true">
            <h1>Bracelets Section</h1>
            <h2 *ngIf="totalBracelets">Total Bracelets Sold: {{ totalBracelets }}</h2>
            <div class="order" [attr.data-sent]="bracelet.dispatched" *ngFor="let bracelet of bracelets">
                <h3>Ref:</h3>
                <div>{{ bracelet._id }}</div>
                <h3>Email:</h3>
                <div>{{ bracelet.email }}</div>
                <h3>Name:</h3>
                <div>{{ bracelet.firstName }}</div>
                <div>{{ bracelet.lastName }}</div>
                <h3>Address:</h3>
                <div>{{ bracelet.address1 }}</div>
                <div>{{ bracelet.address2 }}</div>
                <div>{{ bracelet.city }}</div>
                <div>{{ bracelet.postcode }}</div>
                <h3>Date:</h3>
                <div>{{ getDate(bracelet._id) }}</div>
                <h3>Quantity:</h3>
                <div>{{ bracelet.quantity }}</div>
                <h3>Express Delivery:</h3>
                <div>{{ bracelet.express || 'false' }}</div>
                <button *ngIf="!bracelet.dispatched" (click)="dispatch(bracelet)" class="dispatch-button">
                    Mark As Dispatched
                </button>
            </div>
        </app-dropdown>
    </ng-container>
</div>
