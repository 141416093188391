<div *ngIf="!accountCreate" class="content">
    <div class="image-container">
        <img class="background-image" src="../../../assets//business-background.png" />
        <div class="on-image">
            <h1 class="title">Corporate Climate Action Beyond Carbon Offsetting</h1>
            <img (click)="scroll(business)" class="arrow-image pointer" src="../../../assets/white-arrow.png" />
        </div>
    </div>
    <div class="text-image-container">
        <img class="text-image" src="../../../assets/eden-certified-logo.png" />
        <div class="text-container">
            <div #businessText class="text turquiose-text bold">Become <span class="italics">Eden Certified</span> by funding the world's most effective carbon removal and biodiversity projects.</div>
            <div class="text">All Eden’s projects are meticulously curated in accordance with our <span class="pointer bold orange underline" routerLink="/articles/anti-greenwashing-methodology">Anti-Greenwashing Methodology</span>, ensuring they are credible and effective. Every project must address at least one of the UN Environment Programme’s three planetary crises - climate, nature or pollution.</div>
            <div class="text">By funding quantified environmental projects, you are joining our global Community of environmentally-conscious
            individuals and businesses taking genuine action to restore our planet.
            </div>
        </div>
    </div>
    <div class="values">
        <div class="value">
            <img class="value-image" src="../../../assets/business-help.png" />
            <div class="value-title">Help Restore Our Planet</div>
            <div class="value-text">Take direct action to support the world’s most effective and reputable <a class="bold turq-text pointer"
                routerLink="/our-projects">BVCM nature-based projects</a>, from tree-planting to ocean plastic removal to landscape
            restoration.</div>
        </div>
        <div class="value">
            <img class="value-image" src="../../../assets/business-become.png" />
            <div class="value-title">Become a Credible Green Business</div>
            <div class="value-text">Trust our UN-backed methodology, and prove your impact with our tailored certificates and green marketing resources.
            <span class="bold turq-text">Directly fund biodiversity and carbon removal projects.</span>
            </div>
        </div>
        <div class="value">
            <img class="value-image" src="../../../assets/business-boost.png" />
            <div class="value-title">Boost Your Sales</div>
            <div class="value-text">Attract new customers and get ahead of competition by demonstrating corporate commitment to real environmental action.</div>
        </div>
        <div class="value">
            <img class="value-image" src="../../../assets/business-engage.png" />
            <div class="value-title">Engage Your Employees</div>
            <div class="value-text">Our new voting system will allow your employees to vote to support their favoured environmental projects, increasing
            employee engagement with your brand and values.</div>
        </div>
    </div>
    <br/><br/>
    <!--
    <h3>Our Solutions:</h3>
    <div class="text">• <span class="bold turq-text">Planetary Restoration package</span>: Complement your internal carbon reductions by supporting a range of the world’s leading
        BVCM projects, addressing pollution, climate change and environmental degradation, as recommended by all major net zero
        guidelines.
    </div>
    <div class="text">• <span class="bold turq-text">Biodiversity package</span>: Focus on restoring biodiversity by supporting projects including tree planting, landscape
        restoration and rewilding across the globe.
    </div>
    <div class="text">• <span class="bold turq-text">Carbon Removal package</span>: Become <span class="bold turq-text">carbon negative</span> by protecting and restoring carbon sinks both on land and sea. We <a class="bold turq-text" routerLink="/overview">do not condone and will never
        support carbon credits.</a>
    </div>
    <br/><br/>
    <button (click)="scroll(signupSection)">Get Started</button>
    <br /><br />
    -->
</div>
<app-partners *ngIf="!accountCreate" color="turqoise"></app-partners>
<div *ngIf="!accountCreate" class="content">
    <div class="text-title">Become <span class="bold turq-text">Eden Certified</span> by pledging
        support monthly, or per product.</div>
    <br /><br />
    <div class="products">
        <div class="product">
            <h3>Per Month</h3>
            <div class="product-title">A subscription to save the planet.</div>
            <img class="product-image" src="../../../assets/per-month.png"/>
            <pre class="product-text">
                <span class="bullet"><img class="tick" src="../../../assets/tick.png"/>Support our range of world-leading environmental projects each month.</span>
                
                <span class="bullet"><img class="tick" src="../../../assets/tick.png" />Increase biodiversity, mitigate the effects of climate change and tackle global pollution</span>
                
                <span class="bullet"><img class="tick" src="../../../assets/tick.png" />Track your progress with your personalised online account</span>
                
                <span class="bullet"><img class="tick" src="../../../assets/tick.png" />Show off your total impact with our extensive marketing suite</span>
            </pre>
        </div>
        <div class="product">
            <h3>Per Product</h3>
            <div class="product-title">Make your product stand out from the crowd.</div>
            <img class="product-image" src="../../../assets/per-product.png" />
            <pre class="product-text">
                <span class="bullet"><img class="tick" src="../../../assets/tick.png" />Create positive impact with every product you sell - eg plant 10 trees, or remove 1kg ocean plastic with each sale.</span>
                
                <span class="bullet"><img class="tick" src="../../../assets/tick.png" />Give your products green USP a using our marketing graphics</span>
                
                <span class="bullet"><img class="tick" src="../../../assets/tick.png" />Integrate your monthly sales with your personalised account</span>
                
                <span class="bullet"><img class="tick" src="../../../assets/tick.png" />Track your progress with your personalised online account</span>
                
                <span class="bullet"><img class="tick" src="../../../assets/tick.png" />Show off your total impact with our extensive marketing suite</span>
            </pre>
        </div>
    </div>
    <button (click)="scroll(signupSection)">Get Started</button>
    <br/><br/>
</div>
<!--
<div *ngIf="!accountCreate" class="content">
    <div class="subtitle">Our Values</div>
    <div class="text"><span class="bold">Behavioural change just not enough anymore.</span> Our projects go above and beyond changing the practices of individual businesses.</div>
    <div class="text">Instead, we offer the opportunity for businesses to take collective responsibility for their environmental footprint, by supporting our projects, thus helping to restore our entire planet’s environmental problems, as a <span class="italic underline">collective.</span></div>
    <div class="text">You can read more about the collective impact our projects are having <span class="orange bold pointer" routerLink="/our-projects">here</span>.</div>
    <div class="text-image-container">
        <img class="float-right" src="../../../assets/pledge.png"/>
        <div class="text"><span class="nowrap">• <span class="bold">Transparency</span></span> - we quantify all our projects in detail, and provide evidence that they have been carried out.</div>
        <div class="text">• <span class="bold">No carbon offsets</span> - offsets are an excuse not to become more sustainable, and we will never support them.</div>
        <div class="text">• <span class="bold">Global change</span> - there’s no ‘one size fits all’ solution when it comes to our entire planet; we provide a range of projects across the world’s leading climate solutions.</div>
    </div>
</div>
<div *ngIf="!accountCreate" class="content">
    <div class="subtitle">What you get</div>
    <div class="what-you-get">
        <div class="item">
            <div class="item-name">Personalised</div>
            <div class="item-name">Certificate of Impact</div>
            <img class="item-pic" src="../../../assets/business-certificate.png"/>
        </div>
        <div class="item">
            <div class="item-name">Online</div>
            <div class="item-name">Impact Tracker</div>
            <img class="item-pic" src="../../../assets/account.png"/>
        </div>
        <div class="item">
            <div class="item-name">Promotional Poster</div>
            <img class="item-pic" src="../../../assets/poster.png" />
        </div>
        <div class="item">
            <div class="item-name">Logo &</div>
            <div class="item-name">Content Package</div>
            <img class="item-pic" src="../../../assets/content-pack.png"/>
        </div>
    </div>
</div>
-->
<div *ngIf="!accountCreate" class="content turquiose">
    <div class="subtitle">£1000 Can Make a Huge Impact</div>
    <div class="impacts">
        <div class="impact">
            <img class="impact-image" src="../../../assets/3-1.png"/>
            <div class="impact-text">300 Trees Planted in Eastern Kenya</div>
        </div>
        <div class="plus"></div>
        <div class="impact">
            <img class="impact-image" src="../../../assets/4-1.png" />
            <div class="impact-text">1000m2 of Wildflower Meadow Rewilded</div>
        </div>
        <div class="plus"></div>
        <div class="impact">
            <img class="impact-image" src="../../../assets/5-1.jpg" />
            <div class="impact-text">100kg of Toxic Ocean Plastic Removed</div>
        </div>
        <div class="plus"></div>
        <div class="impact">
            <img class="impact-image" src="../../../assets/6-1.png" />
            <div class="impact-text">50 People Provided with Clean Solar Lighting</div>
        </div>
    </div>
    <button (click)="scroll(signupSection)" href="business">Find Out More</button>
    <br/><br/>
</div>
<app-reviews-transparency *ngIf="!accountCreate"></app-reviews-transparency>
<div class="content turquiose">
    <div #signup class="subtitle">{{ accountCreate ? 'Business Account' : 'Get Started' }}</div>
    <div *ngIf="!loggedIn" class="text center">Get started by {{ accountCreate ? 'creating your business account' : 'booking an introductory call'}}</div>
    <br />
    <ng-container *ngIf="!accountCreate">
    <button>
        <a class="extra-padding"
            href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1Fmxt-qlG87vJwbcwy9nYP1Ab4-AxFunX9q_V7pthCRdZP2QToElnVsGE7G6XGSecsH31NS12k"
            target="_blank">
            Book An Introductory Call
        </a>
    </button>
        <br />
        <br/>
        <br/>
    </ng-container>
</div>
<app-login *ngIf="!loggedIn && accountCreate" [loginOnly]="true" [business]="true" (loginEvent)="handleLogin()"></app-login>
<div class="content beige turquiose">
    <div *ngIf="loggedIn" class="text">Thank you for creating your business account. A member of our team will be in touch within the next 48 hours to arrange
    an introductory call and complete your set-up.</div>
</div>