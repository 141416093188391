import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FooterService } from 'src/app/services/footer.service';
import { NextSectionComponent } from '../next-section/next-section.component';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css'],
  outputs: ['nextSection'],
})
export class HowItWorksComponent extends NextSectionComponent {
  @Input() blur = true;

  @ViewChild('content') content;

  constructor() {
    super();
   }

  ngOnInit(): void {
  }

}
