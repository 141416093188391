
<div class="container">
    <!--
    <div class="blue-container" [attr.data-open]="open">
        <div class="content">
            <app-title class="title" text="Topics" large="true"></app-title>
            <div class="dropdown-container">
                <div class="dropdowns">
                    <app-dropdown dropdown="Articles" buttonMode="true" [selected]="category === 'articles'"
                        link="/blog/articles"></app-dropdown>
                    <app-dropdown dropdown="Project Updates" buttonMode="true"
                        [selected]="category === 'project-updates'" link="/blog/project-updates"></app-dropdown>
                </div>
            </div>
        </div>
    </div>
    -->
    <div class="blogs">
        <div *ngIf="latest" class="latest-blog" [href]="'/articles/' + (latest.url || latest._id)"
            [routerLink]="'/articles/' + (latest.url || latest._id)">
            <img class="latest-blog-image" defaultImage="../../../assets/loading.gif" [lazyLoad]="latest.thumbnail" />
            <div class="white-background">
                <div class="latest-category-container ">
                    <div [class]="'latest-blog-category latest-blog-category ' + latest.category">{{ getDisplayCategory(latest.category) }}</div>
                </div>
                <div class="latest-blog-title">{{ latest.title }}</div>
                <div class="blog-summary">{{ latest.summary }}</div>
                <div class="blog-date">New - {{ getDate(latest._id, latest.date) }}</div>
            </div>
        </div>
        <div class="filter">Filter:</div>
        <div class="categories">
            <div class="blog-category small-margin pointer"
                [attr.data-selected]="filterCategories.length === 0" 
                (click)="removeFilters()">All</div>
            <div 
                *ngFor="let category of blogCategories" 
                [class]="'blog-category small-margin pointer ' + category" 
                [attr.data-selected]="filterCategories.includes(category)"
                (click)="addFilter(category)"
            >{{ getDisplayCategory(category) }}</div>
            <div *ngIf="!moreFilters" class="more-filters pointer turq-text underline" (click)="moreFilters = true">More Filters</div>
        </div>
        <div *ngIf="moreFilters" class="categories">
            <div 
                *ngFor="let tag of blogTags" 
                class="blog-category small-margin pointer"
                [attr.data-selected]="filterTags.includes(tag)"
                (click)="addFilter(undefined, tag)"
            >{{ tag }}</div>
        </div>
        <div class="search-container">
            <input type="text" class="search" placeholder="Search Articles" (ngModelChange)="search($event)" [ngModel]="searchTerm">
        </div>
        <div class="blogs-container">
            <a *ngFor="let blog of blogs" class="blog" [href]="'/articles/' + (blog.url || blog._id)"
                [routerLink]="'/articles/' + (blog.url || blog._id)">
                <button *ngIf="isAdmin" class="pointer" (click)="$event.stopPropagation(); $event.preventDefault();"
                    [routerLink]="'/admin/' + blog._id">Edit</button>
                <div class="blog-inner">
                    <img class="blog-image" defaultImage="../../../assets/loading.gif" [lazyLoad]="blog.thumbnail" />
                    <div class="blog-not-image">
                        <div class="blog-title">{{ blog.title }}</div>        
                        <div class="blog-summary">{{ blog.summary }}</div>
                        <div class="blog-date">{{ getDate(blog._id, blog.date) }} - {{ blog.readTime }} minute read</div>
                        <div class="categories">
                            <div *ngFor="let tag of blog.tags" class="blog-category blog-tag">{{ tag }}</div>
                        </div>
                    </div>
                    <div [class]="'main-blog-category ' + blog.category">{{ getDisplayCategory(blog.category) }}</div>
                </div>
            </a>
        </div>
    </div>
</div>