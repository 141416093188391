import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, TreesCounter } from 'src/app/services/auth.service';

const PLANS = ['Kick-Starter', 'Sustainer', 'Front-Runner']

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  public plan: string;
  public planParam: string;
  public trees: TreesCounter;
  public treeNum: number;

  private route: ActivatedRoute;
  private authService: AuthService;

  constructor(route: ActivatedRoute, authService: AuthService) {
    this.route = route;
    this.authService = authService;
  }

  ngOnInit(): void {

    this.planParam = this.route.snapshot.params['plan'];
    if(this.planParam.includes('trees')) {
      const parts = this.planParam.split('-');
      this.planParam = parts[0];
      this.treeNum = parseInt(parts[1]);
    }
    this.plan = PLANS.find((plan) => {
      return plan === this.planParam;
    });

    if(this.planParam === 'trees') {
      this.authService.getTrees().subscribe((trees) => {
        this.trees = trees;
        
        if(trees.current < this.treeNum) {
          trees.current = this.treeNum;
        } 
      });
    }
  }

}
