import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, Blog } from 'src/app/services/auth.service';
import { SeoService } from 'src/app/services/seo.service';
import { getDate } from '../blog/blog.component';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css'],
})
export class ArticleComponent implements OnInit {
  private route: ActivatedRoute;
  private authService: AuthService;
  private seoService: SeoService;

  public getDate = getDate;

  public article: Blog;

  constructor(route: ActivatedRoute, authService: AuthService, seoService: SeoService) { 
    this.route = route;
    this.authService = authService;
    this.seoService = seoService;
  }

  ngOnInit(): void {
    this.authService.getArticle(this.route.snapshot.params['url']).subscribe((article) => {
      this.article = article;
      console.log(article);
      this.seoService.setSeo(article.metaTitle || article.title, article.metaDescription || article.summary);
    });
  }

}
