import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
  @Input() popup = false;
  public error = false;
  public message = '';

  public email = '';
  public name = '';

  public authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
  }

  ngOnInit(): void {
  }

  public signUp() {
    this.authService.newsletterSignup(this.email, this.name).subscribe((message) => {
      if(message === 'Thank you for signing up to our newsletter!') {
        this.error = false;
      } else {
        this.error = true;
      }

      this.message = message;
    })
  }

}
