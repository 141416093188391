import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  @Input() public images: string[];
  @Input() public desktop = false;
  @Input() public fullscreen?: boolean;
  @Input() contentMode = false;
  @Input() itemsPerRow: number;

  @ViewChild("gallery") private gallery: ElementRef;

  private scrollPosition = 0;
  private scrollLiteral = 0;
  private inc = 1;
  
  constructor() { }

  ngOnInit(): void {
    if(!this.contentMode) {
      this.images = this.images.map((image) => {
        if (image.includes('https://')) {
          return image;
        } else {
          return '../../../assets/' + image;
        }
      });

      if (this.desktop) {
        this.inc = 4;
        //this.scrollPosition = this.inc / 2;
      }
    } 
  }

  private getWidth(el: any): any {
    let width = el.offsetWidth;
    const style = getComputedStyle(el);
    width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    return width;
  }

  public scrollGallery(right = true): void {
    const gallery = this.gallery.nativeElement;
    
    const width = this.getWidth(gallery.children[this.scrollPosition]);

    const indexNext = this.scrollPosition + this.inc;
    let widthNext;
    
    if(indexNext < gallery.children.length) {
      widthNext = this.getWidth(gallery.children[indexNext]);
    }

    if(right && !widthNext) {
      return;
    }

    const indexPrev = this.scrollPosition - this.inc;
    let widthPrev;
    
    if(indexPrev > -1) {
      widthPrev = this.getWidth(gallery.children[indexPrev]);
    }

    if(!right && !widthPrev) {
      return;
    }

    const maxScrollPosition = this.itemsPerRow ? (gallery.children.length - this.itemsPerRow) : gallery.children.length - this.inc/2;

    if(right) {
      if (this.scrollPosition === maxScrollPosition) {
        return;
      }
      
      if(this.scrollPosition === 0 && !this.desktop) {
        let galleryWidth = gallery.offsetWidth;
        if(this.contentMode) {
          this.scrollLiteral = width;
        } else {
          this.scrollLiteral = (width - galleryWidth / 2) + (widthNext / 2);
        } 
      } else {
        this.scrollLiteral = this.scrollLiteral + (width + widthNext)/2;
      }
      this.scrollPosition = this.scrollPosition + this.inc;
    } else {
      this.scrollLiteral = this.scrollLiteral - (width + widthPrev)/2;
      this.scrollPosition = this.scrollPosition - this.inc;
    }

    if(this.scrollPosition > maxScrollPosition) {
      this.scrollPosition = maxScrollPosition;
    }

    if(this.scrollPosition < 0) {
      this.scrollPosition = 0;
    }

    this.gallery.nativeElement.scrollTo(
      {
        left: this.scrollLiteral * this.inc,
      }
    );
  }
}
