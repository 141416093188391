
<div class="content" [attr.data-hide]="hide" [attr.data-long]="loginOnly" [attr.data-color]="color" [attr.data-cart]="cart" [attr.data-business]="business && !winkworth">
    <div *ngIf="winkworth" class="reviews desk">
        <div class="review">
            <div class="quote">"The response from our vendors has been
                overwhelmingly positive - they are impressed we are doing something like this, and keen to be involved fully. It really
                gives us a different USP when out on MA's and helps to differentiate us without having to reduce fees! We are really
                pleased to be using the system, and the support and help offered along with it is great."
            </div>
            <div class="author italics">Lisa Jacobs, Winkworth Marlborough</div>
        </div>
        <div class="review">
            <div class="quote">"Our vendors love seeing that they
            can plant trees, supporting rewilding initiatives and so on when they sell their house through us. The brochures are a
            great tool for us to display the green projects and people even take pictures of the poster we’ve put in the shop
            window."
            </div>
            <div class="author italics">Charlie Graham, Rural View Estate Agents</div>
        </div>
    </div>
    <div class="form">
        <div *ngIf="loginOnly && !winkworth && !business" class="image-container">
            <img class="image" src="../../../assets/cropped-Eden-Greenspace.png"/> 
        </div> 
        <div *ngIf="winkworth" class="image-container">
            <img class="winkworth-image" src="../../../assets/winkworth-logo.png" />
        </div>
        <div *ngIf="winkworth" class="control">
            <span>Create your Winkworth office account here.</span>
        </div>
        <div class="control error">{{ error }}</div>
        <ng-container *ngIf="!cardOnly">
            
            <input *ngIf="createNew || business" class="control border" placeholder="First Name" [(ngModel)]="firstName">
            <input *ngIf="createNew || business" class="control border" placeholder="Last Name" [(ngModel)]="lastName">
        
            <input *ngIf="!edit" class="control border" [placeholder]="winkworth ? 'Office Email' : 'Email'" [(ngModel)]="username">
            <input *ngIf="business" type="number" class="control border" placeholder="Phone" [(ngModel)]="phone">
            <input *ngIf="business && !winkworth" class="control border" placeholder="Business Type" [(ngModel)]="businessType">
            <div *ngIf="business && !winkworth" class="control">Company Logo:</div>
            <input  *ngIf="business && !winkworth" class="control" type="file" accept="image/*" (change)="imageChange($event)">
            <input *ngIf="winkworth" class="control border" placeholder="Branch" [(ngModel)]="branch">
            <input *ngIf="!trees" class="control border" type="password" placeholder="Password" [(ngModel)]="password">
            <input *ngIf="createNew || business" type="password" class="control border" placeholder="Confirm Password" [(ngModel)]="confirm">
            <input *ngIf="edit" type="password" class="control" placeholder="New Password" [(ngModel)]="newPassword">
            <input *ngIf="edit" type="password" class="control" placeholder="Confirm New Password" [(ngModel)]="confirmNew">
        </ng-container>
        <input *ngIf="bracelet" class="control border" placeholder="First Name" [(ngModel)]="firstName">
        <input *ngIf="bracelet" class="control border" placeholder="Last Name" [(ngModel)]="lastName">
        <input *ngIf="trees && cardOnly" class="control border" placeholder="Email" [(ngModel)]="username">
        <input *ngIf="bracelet" class="control border" placeholder="Address Line 1" [(ngModel)]="address1">
        <input *ngIf="bracelet" class="control border" placeholder="Address Line 2" [(ngModel)]="address2">
        <input *ngIf="bracelet" class="control border" placeholder="City" [(ngModel)]="city">
        <input *ngIf="bracelet" class="control border" placeholder="Postcode" [(ngModel)]="postcode">
        <div *ngIf="winkworth" class="control row">
            <input class="checkbox" type="checkbox" [(ngModel)]="winkworthAgreed">
            <div class="tc">
                I agree to the
                <a href="https://docs.google.com/document/d/1YX7BHVH13egkoYS9lmxJXIhLjrONCuaifP_7znrJg9A" target="_blank">Terms and Conditions</a>
            </div>
        </div>
        <div *ngIf="business && !winkworth" class="control row">
            <input class="checkbox" type="checkbox" [(ngModel)]="businessAgreed">
            <div class="tc">
                I agree to the
                <a href="https://docs.google.com/document/d/1kCeJ8r2PUGchUTgLnBBx93psRfaNtGsHb0P6mqzvjwY/edit#heading=h.az4asche96kc"
                    target="_blank">Terms and Conditions</a>
            </div>
        </div>
        <div *ngIf="createNew || cardOnly || trees" class="payment-container form">
            <div id="card-element" class="control border">
                    <!-- a Stripe Element will be inserted here. -->
            </div>
            <div id="card-element-errors" role="alert"></div>
            <div *ngIf="(createNew && !cardOnly) || trees || (cardOnly && impactCreate)" class="control row">
                <input class="checkbox" type="checkbox" [(ngModel)]="agreed">
                <div class="tc">
                    I agree to the 
                    <a href="../../../assets/Eden_Greenspace_Terms_and_Conditions.pdf" target="_blank">Terms and Conditions</a>
                    and
                    <a href="../../../assets/Eden_Greenspace_Privacy_Policy.pdf" target="_blank">Privacy Policy</a>
                </div>
            </div>
            <div *ngIf="(createNew && !cardOnly) || (cardOnly && impactCreate) || bracelet || trees || (cardOnly && edit)" class="stripe-tc">
                This page is powered by Stripe.
                <a href="https://stripe.com/en-gb/checkout/legal" target="_blank">Terms and Conditions</a>
                and
                <a href="https://stripe.com/en-gb/privacy" target="_blank">Privacy Policy</a>
            <div *ngIf="bracelet" class="control row">
                <input class="checkbox" type="checkbox" [(ngModel)]="newsletter">
                <div class="tc">
                    Plant 1 more tree for free and sign up to our monthly newsletter
                </div>
            </div>
            <div *ngIf="bracelet" class="control row">
                <input class="checkbox" type="checkbox" [(ngModel)]="CartService.express">
                <div class="tc">
                    <span class="bold">Express Delivery +£2.99</span>
                </div>
            </div>
        </div>
        </div>
        <ng-container *ngIf="!cardOnly && !edit">
            <button class="control pointer clickable" (click)="login()">{{ createNew ? 'Create New Account And Pay' : trees ? (bracelet ? 'Finish and Pay' : 'Pay Now') : business ? (winkworth ? 'Create Winkworth Account' : 'Create Business Account') : 'Login' }}</button>
            <div *ngIf="!loginOnly && !trees && !business" class="control">
                <span>{{ createNew ? 'Already have an account?' : "Don't have an account?" }}</span>
                <span class="switch pointer" (click)="switch()">{{ createNew ? ' Login' : ' Sign up' }}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="edit">
            <button class="control pointer clickable" (click)="update()">Update</button>
        </ng-container>
        <ng-container *ngIf="cardOnly && !edit">
            <button class="control pointer clickable" (click)="pay()">{{ impactCreate ? 'Confirm and Pay' : 'Retry Payment' }}</button>
        </ng-container>
        <ng-container *ngIf="loginOnly && !cardOnly && !business">
            <div *ngIf="loginOnly && !trees && !business" class="control">
                <span class="switch pointer" routerLink="/forgot-password">Forgot Password?</span>
            </div>
            <div *ngIf="loginOnly && !trees && !business" class="control">
                <span>Forgot Username?
                    <i 
                        class="fa fa-question-circle token" 
                        style="font-size: min(5vw, 25px)" 
                        tabindex="0"
                        tooltip="Your username is your email address. For Winkworth accounts, it's your office email."
                        [options]="ttConfig">
                    </i>
                </span>
            </div>
            <div class="control">
                Don't have an account?
            </div>
            <div class="control">
                To create an account, first choose a plan.
            </div>
            <button class="control pointer clickable" routerLink="/">Choose your plan</button>
        </ng-container>
    </div>
    <div *ngIf="winkworth" class="bullets bold desk">
        <div class="bullet-title">Sustainable brand image</div>
        <div class="bullet">
            •&nbsp;Vendors and landlords care about sustainability - supporting green projects with every sale is a great
            instruction
            getter for your agents.
        </div>
        <div class="bullet-title">No sign-up cost</div>
        <div class="bullet">
            •&nbsp;Use a system designed specially for Winkworth and approved by Head Office, with no sign-up costs.
        </div>
        <div class="bullet-title">Most effective</div>
        <div class="bullet">
            •&nbsp;Eden’s range of green projects provides the most effective way to restore our planet & based on UN
            Environment Programme
            methodology.
        </div>
    </div>
    <div *ngIf="winkworth" class="reviews mob">
        <div class="review">
            <div class="quote">"The response from our
                vendors has been
                overwhelmingly positive - they are impressed we are doing something like this, and keen to be involved
                fully. It really
                gives us a different USP when out on MA's and helps to differentiate us without having to reduce fees! We
                are really
                pleased to be using the system, and the support and help offered along with it is great."
            </div>
            <div class="author italics">Lisa Jacobs, Winkworth Marlborough</div>
        </div>
        <div class="review">
            <div class="quote">"Our
                vendors love seeing that they
                can plant trees, supporting rewilding initiatives and so on when they sell their house through us. The
                brochures are a
                great tool for us to display the green projects and people even take pictures of the poster we’ve put in the
                shop
                window."
            </div>
            <div class="author italics">Charlie Graham, Rural View Estate Agents</div>
        </div>
    </div>
    <div *ngIf="winkworth" class="bullets bold mob">
        <div class="bullet-title">Sustainable brand image</div>
        <div class="bullet">
            •&nbsp;Vendors and landlords care about sustainability - supporting green projects with every sale is a great
            instruction
            getter for your agents.
        </div>
        <div class="bullet-title">No sign-up cost</div>
        <div class="bullet">
            •&nbsp;Use a system designed specially for Winkworth and approved by Head Office, with no sign-up costs.
        </div>
        <div class="bullet-title">Most effective</div>
        <div class="bullet">
            •&nbsp;Eden’s range of green projects provides the most effective way to restore our planet & based on UN
            Environment Programme
            methodology.
        </div>
    </div>
</div>
