import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ToggleOptions {
  displayName: string;
  name: string;
}

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent implements OnInit {
  @Input() toggleOptions: ToggleOptions[];
  @Input() fontSize = 'min(3vw, 20px)';
  @Input() lettings = false;
  @Output() toggleEvent: EventEmitter<string> = new EventEmitter();

  public toggle: string;

  constructor() { }

  ngOnInit(): void {
    this.toggle = this.toggleOptions[0].name;
    setTimeout(() => { this.toggleEvent.emit(this.toggle) })
    
  }

  public toggleSwitch(option: string): void {
    this.toggle = option;
    this.toggleEvent.emit(option);
  }

}
