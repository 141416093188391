import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  protected sanitizer: DomSanitizer

  constructor(sanitizer: DomSanitizer) {
    this.sanitizer = sanitizer;
  }

  public transform(value: any, type: string): any {
    if (type === 'url') {
      return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }

    const sanitizedContent = DOMPurify.sanitize(value, {
      WHOLE_DOCUMENT: true,
    });

    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}
