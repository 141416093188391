import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, TreesCounter } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { environment } from 'src/environments/environment';
import { getGallery } from '../gifting/gifting.component';
import { TreesEvent } from '../login/login.component';
import { BIOS } from '../projects/projects.component';
declare var Stripe: any;

@Component({
  selector: 'app-tree-planting',
  templateUrl: './tree-planting.component.html',
  styleUrls: ['./tree-planting.component.css']
})
export class TreePlantingComponent implements OnInit {
  private stripe;

  public retry = false;
  public loading = false;
  public message = '';
  public trees: TreesCounter;
  public images: string[] = getGallery(1, 1, 10);
  public CartService = CartService;

  private router: Router;
  private authService: AuthService;

  @ViewChild('pay') pay;

  constructor(router: Router, authService: AuthService) {
    this.router = router;
    this.authService = authService;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.stripe = environment.production
        ? Stripe('pk_live_51IX4vmBDboGHB3gmCr8cmjFzg0aO9LxJHErrbE8FAtOh806Mb3PWjkPFR3A6g4z4ySI1mlfoToieenXke3iuFP1A00zZvlxg13')
        : Stripe('pk_test_51IX4vmBDboGHB3gmxC86oDlimVDH8gS6qAZ0QFAT6BtWq2aYrO4VCosc457AOLotPFFMfM0hK7bOTlgLMxzwQgYT00rY5mAx2D');
    }, 200);

    this.CartService.quantity = 10;
    this.authService.getTrees().subscribe((trees) => {
      this.trees = trees;
    });
  }

  public scroll(): void {
    this.pay.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  public login(event?: TreesEvent): void {
    this.message = '';
    this.loading = true;
    this.authService.payTrees(
      {
        paymentMethodId: event.id,
        name: event.name,
        cosmo: true,
        quantity: CartService.quantity,
      },
    ).subscribe((result) => {
      console.log(result);
      if(result.status === 'succeeded') {
        this.loading = false;
        this.message = 'Payment successful!'
        this.retry = false;
        this.redirect();
      } else if(result.status === 'requires_payment_method') {
        this.loading = false;
        this.message = 'There was an error with your card. Please try again';
        this.retry = true;
      } else if (result.status === 'requires_action') {
        this.message = 'Awaiting confirmation';
        this.stripe.confirmCardPayment(result.client_secret, {
          payment_method: event.id,
        }).then((res) => {
          if (res.error) {
            this.message = 'There was an error with your card. Please try again';
            this.retry = true;
            this.loading = false;
          } else {
            if (res.paymentIntent.status === 'succeeded') {
              this.loading = false;
              this.message = 'Payment successful!'
              this.redirect();
            }
          }
        })
      } else {
        this.message = 'There was an error with your card. Please try again';
        this.retry = true;
        this.loading = false;
      }
      });
    }

  public getImage(projectId: number): string {
    return BIOS.find(bio => bio.projectId === projectId).thumbnail;
  }

  public getUrl(projectId): string {
    return '/our-projects/' + BIOS.find(bio => bio.projectId === projectId).url;
  }

  private redirect() {
    this.router.navigate(['success', `trees-${this.trees.current + 10}`]);
  }

}
