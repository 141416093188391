<div class="button-container" [attr.data-open]="open">
    <div [class]="button ? 'button pointer clickable' : 'not-button'" [attr.data-disabled]="disabled">
        {{ text }}
    </div>
    <div *ngIf="button" class="triangle pointer" [attr.data-disabled]="disabled">
    </div>
    <div *ngIf="button" class="inner-triangle pointer">
    </div>
    <div *ngIf="button" class="inner-inner-triangle pointer" [attr.data-disabled]="disabled">
    </div>
</div>
