import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { BraceletEvent } from '../login/login.component';
import { Review } from '../reviews/reviews.component';
declare var Stripe: any;

@Component({
  selector: 'app-bracelets',
  templateUrl: './bracelets.component.html',
  styleUrls: ['./bracelets.component.css']
})
export class BraceletsComponent implements OnInit {
  private stripe;
  
  public retry = false;
  public loading = false;
  public message = '';
  public checkout = false;
  public reviews: Review[];

  public CartService = CartService;
  private router: Router;
  private authService: AuthService;
  private seoService: SeoService;

  public ttConfig = {
    'placement': 'top',
    'hideDelay': 0,
  }

  constructor(router: Router, authService: AuthService, seoService: SeoService) {
    this.router = router;
    this.authService = authService;
    this.seoService = seoService;
  }

  ngOnInit(): void {
    this.checkout = this.router.url.split('/').pop() === 'checkout';
    if (!Number.isInteger(CartService.quantity) || CartService.quantity <= 0) {
      CartService.quantity = 1;
    }
    this.seoService.setSeo('Eden Bracelet', 'Every purchase of an Eden Bracelet plants 10 trees in the Great Rift Valley, Kenya.');

    // Hack
    setTimeout(() => {
      this.stripe = environment.production
        ? Stripe('pk_live_51IX4vmBDboGHB3gmCr8cmjFzg0aO9LxJHErrbE8FAtOh806Mb3PWjkPFR3A6g4z4ySI1mlfoToieenXke3iuFP1A00zZvlxg13')
        : Stripe('pk_test_51IX4vmBDboGHB3gmxC86oDlimVDH8gS6qAZ0QFAT6BtWq2aYrO4VCosc457AOLotPFFMfM0hK7bOTlgLMxzwQgYT00rY5mAx2D');
    }, 200);

    this.authService.getReviews().subscribe(reviews => this.reviews = reviews);
  }

  public login(event?: BraceletEvent): void {
    this.message = '';
    this.loading = true;
    this.authService.payBracelet(
      {
        paymentMethodId: event.id,
        email: event.email,
        firstName: event.firstName,
        lastName: event.lastName,
        address1: event.address1,
        address2: event.address2,
        city: event.city,
        postcode: event.postcode,
        quantity: CartService.quantity,
        express: CartService.express,
      }
    ).subscribe((result) => {
      console.log(result);
      if (result.status === 'succeeded') {
        this.message = 'Payment successful!'
        this.retry = false;
        this.loading = false;
        this.redirect();
      } else if (result.status === 'requires_payment_method') {
        this.message = 'There was an error with your card. Please try again';
        this.retry = true;
        this.loading = false;
      } else if (result.status === 'requires_action') {
        this.message = 'Awaiting confirmation';
        this.stripe.confirmCardPayment(result.client_secret, {
          payment_method: event.id,
        }).then((res) => {
          if (res.error) {
            this.message = 'There was an error with your card. Please try again';
            this.retry = true;
            this.loading = false;
          } else {
            if (res.paymentIntent.status === 'succeeded') {
              this.message = 'Payment successful!'
              this.loading = false;
              this.redirect();
            }
          }
        })
      } else {
        this.message = 'There was an error with your card. Please try again';
        this.retry = true;
        this.loading = false;
      }
    });
  }

  private redirect() {
    this.router.navigate(['success', 'bracelet']);
  }

  public buy() {
    if(Number.isInteger(CartService.quantity) && CartService.quantity > 0) {
      this.router.navigate(['products/eden-bracelet/checkout']);
    }
  }

}
