import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { projects } from 'server/projects';
import { Purchase } from 'server/purchases';
import { AuthService, Blog, CompletedImpact, TotalImpact, UserData} from 'src/app/services/auth.service';
import { getImpactText } from '../impact-creation/impact-creation.component';
import { BraceletBase, BraceletObject } from '../login/login.component';

interface TextSection {
  type: 'text' | 'quote' | 'subtitle' | 'html';
  data: string;
}

interface FileSection {
  type: 'image';
  data: File;
}

type BlogSection = TextSection | FileSection;

export interface BlogUpload {
  title: string;
  author: string;
  category: string;
  summary: string;
  content: BlogSection[];
  thumbnail: File;
  tags: string[];
  date: Date | string;
  url: string;
  metaTitle: string;
  metaDescription: string;
  readTime: number;
}

export interface BusinessPurchase extends Purchase {
  projectId: number,
  cost: number,
  certificate: File,
  date: Date | string,
  email: string,
  quantity: number,
}

export const TAGS = [
  'Climate Crisis',
  'Carbon Offsetting',
  'Net Zero',
  'Greenwashing',
  'Biodiversity',
  'Tree Planting',
  'Rewilding',
  'Clean Energy',
  'Pollution',
  'Triple Planetary Crisis',
  'Nature-Based Solutions',
  'Green Tech',
  'Nature',
  'COP',
  'Politics',
  'Fossil Fuel'
];


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  private authService: AuthService;
  private router: Router;
  private route: ActivatedRoute;

  public blog: BlogUpload = {
    category: 'newsletters',
    title: '',
    author: '',
    summary: '',
    content: [],
    thumbnail: null,
    tags: [],
    date: new Date(),
    url: '',
    metaTitle: '',
    metaDescription: '',
    readTime: 0,
  };

  public purchase: BusinessPurchase = {
    projectId: 3,
    cost: 0,
    value: 0,
    certificate: undefined,
    date: new Date(),
    email: '',
    quantity: 1,
  };

  public projects = projects;

  public images: string[];
  public disable = false;
  public isAdmin = false;
  public edit = false;

  public existingBlog: Blog;
  public bracelets: BraceletBase[];
  public totalBracelets: number;
  public totalImpact: TotalImpact[];
  public requestedImpact: TotalImpact[];
  public completedImpacts: CompletedImpact[];
  public disableButton = false;

  public usersData: UserData[];
  public displayUsersData: UserData[];
  public getImpactText = getImpactText;
  public searchTerm: string;

  public tags = TAGS;

  constructor(authService: AuthService, router: Router, route: ActivatedRoute) {
    this.authService = authService;
    this.router = router;
    this.route = route;
  }

  ngOnInit(): void {
    this.authService.isAdmin().subscribe((isAdmin) => this.isAdmin = isAdmin);
    if(this.route.snapshot.params['article']) {
      this.edit = true;
      this.authService.getArticle(this.route.snapshot.params['article']).subscribe((blog) => {
        this.blog.category = blog.category;
        this.blog.title = blog.title;
        this.blog.author = blog.author;
        this.blog.summary = blog.summary;
        this.blog.tags = blog.tags;

        this.blog.date = blog.date ? new Date(blog.date).toISOString().split('T')[0] : undefined; 
        this.blog.url = blog.url;
        this.blog.metaTitle = blog.metaTitle;
        this.blog.metaDescription = blog.metaDescription;
        this.blog.readTime = blog.readTime;

        this.existingBlog = blog;
      });
    };
    
    combineLatest([
      this.authService.getBracelets(),
      this.authService.getTotalBracelets(),
    ]).subscribe(([bracelets, totalBracelets]) => {
      this.bracelets = bracelets;
      this.totalBracelets = totalBracelets;
    });

    combineLatest([
      this.authService.getTotalImpact(),
      this.authService.getTotalCompletedImpact(),
      this.authService.getCompletedImpacts(),
      this.authService.getUsersPurchases()
    ]).subscribe(([totalImpact, totalCompletedImpact, completedImpacts, usersData]) => {
      this.totalImpact = totalImpact.sort((a, b) => a._id - b._id).map((impact) => {
        if(impact._id === 2) {
          impact.value = impact.value + 200;
        }
        
        if(impact._id === 6) {
          impact.value = impact.value + 10;
        }

        return impact;
      });

      this.requestedImpact = totalCompletedImpact.sort((a, b) => a._id - b._id).map((impact, i) => {
        return {
          _id: impact._id,
          value: this.totalImpact[i].value - impact.value,
        }
      });

      this.completedImpacts = completedImpacts.map((impact) => {
        return {
          _id: impact._id,
          impact: impact.impact.sort((a, b) => a._id - b._id),
        }
      });

      this.usersData = usersData;
      this.displayUsersData = [...usersData];
    });
  }

  public thumbnailChange(event): void {
    this.blog.thumbnail = event.target.files[0];
  }

  public imageChange(event, content: BlogSection): void {
    content.data = event.target.files[0];
  }

  public onUpload(): void {
    this.disable = true;

    if(this.edit) {
      this.existingBlog.category = this.blog.category;
      this.existingBlog.title = this.blog.title;
      this.existingBlog.author = this.blog.author;
      this.existingBlog.summary = this.blog.summary;
      this.existingBlog.tags = this.blog.tags;
      this.existingBlog.date = this.blog.date?.toString();
      this.existingBlog.url = this.blog.url;
      this.existingBlog.metaTitle = this.blog.metaTitle;
      this.existingBlog.metaDescription = this.blog.metaDescription;
      this.existingBlog.readTime = this.blog.readTime;

      this.authService.blogEdit(this.existingBlog).subscribe(() => {
        this.router.navigate(['/learn']);
      })
    } else {
      this.authService.blogUpload(this.blog).subscribe(() => {
        this.router.navigate(['/learn']);
      });
    }
  }

  public categoryChange(event): void {
    this.blog.category = event.target.value;
  }

  public addText(): void {
    this.blog.content.push({
      type: 'text',
      data: '',
    });
  }

  public addQuote(): void {
    this.blog.content.push({
      type: 'quote',
      data: '',
    });
  }

  public addImage(): void {
    this.blog.content.push({
      type: 'image',
      data: null,
    });
  }

  public addSubtitle(): void {
    this.blog.content.push({
      type: 'subtitle',
      data: '',
    });
  }

  public addHTML(): void {
    this.blog.content.push({
      type: 'html',
      data: '',
    });
  }

  public remove(section: BlogSection): void {
    const index = this.blog.content.indexOf(section);
    this.blog.content.splice(index, 1);
  }

  public setTags(tags: string) {
    this.blog.tags = tags.split(',').map((tag) => tag.trim());
  }

  public addTag(tag: string) {
    if (this.blog.tags.includes(tag)) {
      const index = this.blog.tags.indexOf(tag);

      this.blog.tags.splice(index, 1);
    } else {
      this.blog.tags.push(tag);
    }
  }

  public projectChange(event): void {
    this.purchase.projectId = parseInt(event.target.value);
  }

  public certificateChange(event): void {
    this.purchase.certificate = event.target.files[0];
  }

  public addPurchase(): void {
    if(!this.purchase.email) {
      return;
    }
    this.disable = true;
    this.authService.addPurchase(this.purchase).subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  public dispatch(bracelet: BraceletObject) {
    this.authService.dispatchBracelet(bracelet._id).subscribe((res) => {
      bracelet.dispatched = true;      
    });
  }

  public getDate(_id: string): string {
    return new Date(parseInt(_id.substring(0, 8), 16) * 1000).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  }

  public completeImpact(): void {
    if(this.disableButton === true) {
      return;
    }
    if(confirm("Are you sure to confirm this impact as complete?")) {
      this.authService.addCompletedImpact(this.requestedImpact).subscribe(
        () => location.reload()
      );
      this.disableButton = true;
    }
  }

  public search(event: string): void {
    this.searchTerm = event;
    this.displayUsersData = [...this.usersData.filter((item) => item.username.toLocaleLowerCase().includes(event))];
  }

  public formatDate(date: string): string {
    if (date) {
      return new Date(date).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    } else {
      return '';
    }
  }
}
