import { Component, Input, OnInit } from '@angular/core';
import { FolderContents } from 'server/types';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.css']
})
export class FolderComponent implements OnInit {
  private authService: AuthService;

  @Input() contents: FolderContents;
  @Input() name: string;

  constructor(authService: AuthService) { 
    this.authService = authService;
  }

  ngOnInit(): void {
    console.log(this.name);
  }

  public addDownloadRecord(record: string) {
    this.authService.addDownloadRecord(record).subscribe();
  }

  public print(thing: any): void {
    console.log(thing);
  }

}
