<div *ngIf="business && media " class="content" [ngSwitch]="toggle">
    <div class="title">Media Page</div>
    <div class="row">
        <div *ngIf="branch" class="subtitle">{{ businessName }} {{ branch }}</div>
        <img *ngIf="name != 'jane.king@cavendishnuclear.com' && name != 'cavnuctest.@test.com'" class="level-image" [src]="levelLogo"/>
    </div>
    <!--
        <div class="info-text">To download images, click on the desired media and then right click in the new tab to save the media onto your computer.</div>
    -->
    <app-folder class="folder" [contents]="media.top" [name]="name"></app-folder>
    <app-toggle [toggleOptions]="toggleOptions" (toggleEvent)="toggleSwitch($event)"></app-toggle>
    <app-folder class="folder" *ngSwitchCase="'project-media'" [contents]="media.projects" [name]="name"></app-folder>
    <app-folder class="folder" *ngSwitchCase="'marketing-materials'" [contents]="media.marketing" [name]="name"></app-folder>
    <app-folder class="folder" *ngSwitchCase="'eden-brand-content'" [contents]="media.branding" [name]="name"></app-folder>
</div>
<ng-container *ngIf="!business">
    <div class="unauth-text">
        Please <span class="link pointer" routerLink="/login">login</span> to an authorised account to access
        this page.
        <br /> <br />
    </div>
</ng-container>
