<div class="content" [ngSwitch]="mode">
    <div *ngIf="mode === 'email' || mode === 'reset'" class="form">
        <div  class="image-container">
            <img class="image" src="../../../assets/cropped-Eden-Greenspace.png" />
        </div>
        <div class="control error">{{ error }}</div>
        <ng-container *ngSwitchCase="'email'">
            <input class="control border" placeholder="Email Address" [(ngModel)]="username"/>
            <button class="control pointer clickable" (click)="sendPasswordEmail()">Email Password Reset Link</button>
        </ng-container>
        <ng-container *ngSwitchCase="'reset'">
            <input class="control border" placeholder="New Password" type="password" [(ngModel)]="password"/>
            <input class="control border" placeholder="Confirm Password" type="password" [(ngModel)]="confirm" />
            <button class="control pointer clickable" (click)="resetPassword()">Reset Password</button>
        </ng-container>
    </div>
    <ng-container *ngSwitchCase="'waiting'">
        <div class="waiting-text">
            Email sent successfully. Please check your inbox and follow the link to reset your password.
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'success'">
        <div class="waiting-text">
            Password has successfully been reset.
        </div>
    </ng-container>
</div>
