<div *ngIf="!checkout" class="content brown">
    <div class="row">
        <div class="images-container">
            <img class="main-image" src="../../../assets/bracelet.jpg" />
            <div class="other-images-container">
                <img class="other-images" src="../../../assets/packaging.png" />
                <img class="other-images right" src="../../../assets/ryan-wrist.png" />
                <img class="other-images" src="../../../assets/wrist.jpg" />
                <img class="mob other-images right" src="../../../assets/bracelet-tree.png" />
            </div>
        </div>
        <div class="text-container">
            <div class="title">Eden Bracelet</div>
            <br />
            <div class="text">Purchasing this bracelet plants 10 trees.</div>
            <br />
            <div class="cost">£{{ 15 * CartService.quantity }}.00</div>
            <div class="text">*FREE shipping!</div>
            <div class="buttons">
                <input class="input" type="number" min="1" [(ngModel)]="CartService.quantity"/>
                <div class="button" (click)="buy()">Buy & Plant</div>
            </div>
            <div class="subtitle">One Bracelet. 10 Trees.</div>
            <div class="text">Every purchase of an Eden Bracelet plants 10 trees in the Great Rift Valley, Kenya.</div>
            <br/>
            <div class="subtitle bold left">Features</div>
            <pre class="text">
                •&nbsp;100% sustainable materials 
                &nbsp;&nbsp;(sustainable cotton, recycled 
                &nbsp;&nbsp;paper card.)
                •&nbsp;Vegan Friendly
                •&nbsp;Unisex
                •&nbsp;Adjustable Size
            </pre>
            <div class="text">
                The Eden Bracelet was designed to channel support to one the most deforested regions on the planet and support the local people there. So far, the Greenspace community have planted over 5000 trees.
            </div>
                <br />
                <div class="text">* Free shipping arrives within 2-3 working days.</div>
                <br/>
                <div class="text">Choose <span class="bold">Express Delivery</span> at checkout for 24 hours tracked delivery.</div>
            <div class="subtitle">This is the power of collective action.</div>
            <br />
            <div class="button orange-button" routerLink="/our-projects/tree-planting-in-kenya">Find out more about this tree
                planting project</div>
                        <br />
                        <br />
        </div>
    </div>
    <div *ngFor="let review of reviews" class="review">
        <div class="rate">
            <input type="radio" id="star5" [name]="review.braceletId" [value]="5" [(ngModel)]="review.rating" disabled/>
            <label for="star5" title="text">5 stars</label>
            <input type="radio" id="star4" [name]="review.braceletId" [value]="4" [(ngModel)]="review.rating" disabled/>
            <label for="star4" title="text">4 stars</label>
            <input type="radio" id="star3" [name]="review.braceletId" [value]="3" [(ngModel)]="review.rating" disabled/>
            <label for="star3" title="text">3 stars</label>
            <input type="radio" id="star2" [name]="review.braceletId" [value]="2" [(ngModel)]="review.rating" disabled/>
            <label for="star2" title="text">2 stars</label>
            <input type="radio" id="star1" [name]="review.braceletId" [value]="1" [(ngModel)]="review.rating" disabled/>
            <label for="star1" title="text">1 star</label>
        </div>
        <div class="text bold"> {{ review.name || 'Anonymous' }}</div>
        <div class="text">{{ review.review }}</div>
    </div>
    <br/>
</div>
<div *ngIf="checkout" class="content">
    <app-title text="Checkout" large="true"></app-title>
    <div class="form">
        <div class="checkout-info">
            <img class="checkout-pic" src="../../../assets/bracelet.jpg"/>
            <div class="checkout-text">
                <span class="bold">Eden Bracelet</span> <span class="right">x{{ CartService.quantity  }}</span><br/>
                <span class="bold">Trees Planted</span> <span class="right">{{ 10 * CartService.quantity }}</span><br/><br />
                Subtotal <span class="right">£{{ 15 * CartService.quantity }}.00</span><br/>
                Shipping Cost <i class="fa fa-question-circle" style="font-size: min(calc(5vw), 20px)" tabindex="0"
                    tooltip='Standard shipping time is 2-5 working days. Express Delivery is 24 hours tracked'
                    [options]="ttConfig">
                </i> <span class="right">{{ CartService.express ? '£2.99' : 'FREE' }}</span><br/>
                Total <span class="right">£{{ (CartService.express ? (15 * CartService.quantity + 2.99) : 15 * CartService.quantity) | number:'1.2-2'}}</span><br/>
            </div>
        </div>
        <div class="info" *ngIf="message">
            {{ message }}
        </div>
        <ng-container *ngIf="loading">
            <div class="info">
                Processing request...
            </div>
        </ng-container>
        <app-login 
            [hide]="loading" 
            [loginOnly]="false"  
            [bracelet]="true" 
            [trees]="true"
            color="white"
            (braceletEvent)="login($event)"
        ></app-login>
    </div>
</div>
