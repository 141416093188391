<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Sue+Ellen+Francisco" />
<div *ngIf="planParam !== 'trees' && planParam !== 'bracelet'" class="gradient">
        <div class="bees">
            <div class="turtles">
                <div class="content">
                    <app-title text="Congratulations!" large="true"></app-title>
                    <div class="sub-content" *ngIf="plan">You have just embarked on your <span class="orange">{{ plan }}</span> eco plan.</div>
                    <div class="sub-content" *ngIf="planParam === 'password'">Your password has been changed</div>
                    <div class="sub-content" *ngIf="planParam === 'payment'">Your payment details have been updated</div>
                    <div class="sub-content" *ngIf="planParam === 'cart'">Congratulations on your purchase.</div>
                    <br>
                    <div class="sub-content small" *ngIf="planParam === 'cart'">We are delighted that you've decided to purchase an eco-gift us, together we will have more impact than we ever could have individually. Not only will your gift now start to reverse the environmental degradation inflicted upon the planet, you are now compensating for the unavoidable pollution footprint which we all have by living in modern society. Thank you for choosing to care for our planet by choosing Eden Greenspace.</div>
                    <div class="sub-content small" *ngIf="plan">We are delighted that you've decided to open an eco-plan with us, together we will have more impact than we ever could have individually. Not only will your project choices now start to reverse the environmental degradation inflicted upon the planet, you are now compensating for the unavoidable pollution footprint which we all have by living in modern society. Thank you for choosing to care for our planet by choosing Eden Greenspace.</div>
                    <div class="sub-content small" *ngIf="plan">Note: if you have changed to a different plan, the impact of your projects will begin after next month's payment.</div>
                    <div class="button pointer clickable" routerLink="/account-page">
                        My Account
                    </div>
                    <div class="background">
                    </div>
                </div>
                
            </div>
        </div>
</div>
<div *ngIf="planParam === 'bracelet'" class="gradient">
    <div class="content">
        <app-title text="Congratulations!" large="true"></app-title>
        <div class="sub-content">
            Thank you for buying an Eden Bracelet and planting 10 trees in the process!
        </div>
        <div class="sub-content">
            We'll email with your order details, and more information about the trees you've planted.
        </div>
        <div class="sub-content">
            The Eden Greenspace Team
        </div>
    </div>
</div>
<div *ngIf="planParam === 'trees'" class="gradient">
    <div class="content">
        <app-title text="Congratulations!" large="true"></app-title>
        <div class="sub-content">
            Trees are the lungs of the planet, essential to maintain a heatlthy climate. Tree planting is a natural and highly
            effective way of absorbing CO2 from the atmosphere, with each tree absorbing 150kg of CO2 over it's lifetime!
        </div>
        <div class="sub-content">
            By supporting this drive, you joined a <span class="bold">community</span> of individuals passionate about restoring our planet - <span class="bold">thank you!</span>
        </div>
        <div class="sub-content">
            The Eden Greenspace Team
        </div>
    </div>
</div>
<!--
<div *ngIf="planParam === 'trees'" class="content green">
    <div class="title">Thanks for supporting our tree planting target!</div>
    <br>
    <div class="white-background not-full-width">
        Trees are the lungs of the planet, essential to maintain a heatlthy climate. Tree planting is a natural and highly effective way of absorbing CO2 from the atmosphere, with each tree absorbing 150kg of CO2 over it's lifetime!
    </div>
    <div class="row">
        <div class="white-background half">
            By supporting this goal, you joined a <span class="bold">community</span> of individuals passionate about restoring our planet - <span class="bold">thank you!</span>
        </div>
        <div class="counter"> 
            <div class="frac">
                <span>{{ trees?.current || 0 }}</span>
                <span class="bottom">{{ trees?.goal || 0 }}</span> 
            </div>
            <div class="counter-text">Trees planted so far!</div>
        </div>
    </div>
    <div class="buttons">
        <div class="button pointer clickable" routerLink="/our-projects">
            Our Projects
        </div>
        <div class="button pointer clickable" routerLink="/overview">
            Overview
        </div>
    </div>
</div>
-->
