import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CAVENDISH } from 'server/accounts';
import { AuthService } from 'src/app/services/auth.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit {
  private authService = inject(AuthService);

  @ViewChild('signup') signupSection;
  @ViewChild('businessText') business;
  
  public loggedIn = false;
  public accountCreate = false;

  private seoService: SeoService;
  private router: Router;

  constructor(seoService: SeoService, router: Router) {
    this.seoService = seoService;
    this.router = router;
   }

  ngOnInit(): void {
    this.seoService.setSeo('Business', 'We make sustainability easy for your business. Choose from our range of climate solutions, either product or for your entire organisation.');
    this.accountCreate = this.router.url.split('/').pop() === 'create-business-account';
  }

  public scroll(element: any) {
    console.log(element);
    setTimeout(() => {
      element.nativeElement.scrollIntoView({ behavior: 'smooth' });
    });
  }

  public handleLogin()
  {
    this.loggedIn = true
    this.authService.getAccountName().subscribe(res => {
      if(CAVENDISH.includes(res.user.name)) {
        this.router.navigate(['/account-page']);
      }
    })
  }

}
