<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Sue+Ellen+Francisco" />
<div *ngIf="name" class="content">
    <div class="container">
        <app-side-tab class="tab" [open]="open" (click)="open = !open"></app-side-tab>
        <div class="blue-container" [attr.data-open]="open">
            <div class="blue" >
                <ng-container *ngIf="!noPlan">
                    <app-title text="Your Eco Plan" small="true"></app-title>
                    <div *ngIf="plan" class="plan">
                        <div class="tile">
                            <img class="plan-image" [src]="plan.image">
                            <div class="name">{{ plan.name }}</div>
                        </div>
                        <div class="tokens">
                            <div class="token-count">
                                {{ plan.tokens }} Eco-Coins /month
                            </div>
                        </div>
                    </div>
                </ng-container>
                <app-title *ngIf="!estateAgent" text="Account Details" [attr.data-margin]="noPlan"></app-title>
                <img *ngIf="business" class="company-logo" [src]="getBusinessLogo(name)">
                <img *ngIf="business && name != 'jane.king@cavendishnuclear.com' && name != 'cavnuctest.@test.com'" class="company-logo" [src]="levelLogo">
                <div class="account">Account: {{ name }}</div>
                <div class="buttons3">
                    <div *ngIf="business && businessPurchases" [attr.data-highlight]="displayed === 'dashboard'" class="button2 pointer clickable"
                        [attr.data-highlight]="displayed === 'dashboard'" (click)="displayed = 'dashboard'; open = !open; getAccountSteps()">
                        Dashboard
                    </div>
                    <div *ngIf="poll" class="button2 pointer clickable" [routerLink]="'/poll-results/' + poll">
                        Voting System
                    </div>
                    <div *ngIf="business" class="button2 pointer clickable" [attr.data-highlight]="displayed === 'media'"
                        (click)="displayed = 'media'; open = !open">
                        Media
                    </div>
                    <div *ngIf="business && businessPurchases" class="button2 pointer clickable"
                        [attr.data-highlight]="displayed === 'orders'" (click)="displayed = 'orders'; open = !open">
                        Order History
                    </div>
                    <div *ngIf="estateAgent" class="button2 pointer clickable" routerLink="/impact-creation">
                        Sales and Lettings
                    </div>
                    <div *ngIf="estateAgent" class="button2 pointer clickable" routerLink="/impact-creation/donation">
                        One-Off Donation
                    </div>
                    <div *ngIf="business && !estateAgent" class="button2 pointer clickable" routerLink="/impact-creation">
                        One-Off Donation
                    </div>
                    <div *ngIf="business" class="button2 pointer clickable" routerLink="/impact-creation/subscription">
                        Annual Contribution
                    </div>
                    <div *ngIf="business" class="button2 pointer clickable" [attr.data-highlight]="displayed === 'how-it-works'"
                        (click)="displayed = 'how-it-works'; open = !open">
                        {{ estateAgent ?  'How It Works' : 'FAQs' }}
                    </div>
                    <!--
                    <div *ngIf="winkworth" class="button2 pointer clickable" routerLink="/leaderboard">
                        Leaderboard
                    </div>
                    -->
                    <div *ngIf="!business" class="button2 pointer clickable space-bottom" routerLink="/choose-your-plan">
                        Upgrade My Plan
                    </div>
                    <div *ngIf="!business" class="button2 pointer clickable" routerLink="/choose-your-plan">
                        Change My Plan
                    </div>
                    <div *ngIf="business" class="account">
                        Need Help? <span class="orange bold underline pointer" (click)="contactUs = true">Contact Us</span>
                    </div>
                </div>
                <div class="dropdown-container">
                    <div class="dropdowns">
                        <app-dropdown contentMode="true" dropdown="Change Password" (click)="editDetails = !editDetails" smallText="true">
                            <div class="dropdown-content" *ngIf="editDetails" (click)="$event.stopPropagation()">
                                <div *ngIf="paymentError" class="project-name">
                                    {{ detailsError }}
                                </div>
                                <app-login [loginOnly]="false" edit="true" color="blue"></app-login>
                            </div>
                        </app-dropdown>
                        <app-dropdown *ngIf="!business" contentMode="true" dropdown="Change Payment Details" (click)="editPayment = !editPayment" smallText="true">
                            <div class="dropdown-content" *ngIf="editPayment" (click)="$event.stopPropagation()">
                                <div *ngIf="paymentError" class="project-name dropdown-error">
                                    {{ paymentError }}
                                </div>
                                <app-login 
                                    [loginOnly]="false"
                                    cardOnly="true" 
                                    edit="true"
                                    (loginEvent)="updatePayment($event)"
                                    color="blue"
                                ></app-login>
                            </div>
                        </app-dropdown>
                    </div>
                </div>
                <div class="buttons3">
                    <div class="logout-button button2 pointer clickable" (click)="modal = true">
                        Logout
                    </div>
                </div>
                <div class="bottom">
                    <ng-container *ngIf="!noPlan">
                        <div class="dropdown-container">
                            <div class="dropdowns">
                                <app-dropdown contentMode="true" dropdown="Cancel My Plan" [open]="cancelPlan" (click)="cancelPlan = !cancelPlan">
                                    <div class="dropdown-content" *ngIf="cancelPlan" (click)="$event.stopPropagation()">
                                        <div class="text margin-top"> Are you sure you want to cancel your plan? You will no longer be charged any fees if you select 'Yes'.</div>
                                        <div class="buttons2">
                                            <div class="button pointer clickable" (click)="cancel()">
                                                Yes
                                            </div>
                                            <div class="button pointer clickable" (click)="$event.stopPropagation(); cancelPlan = !cancelPlan">
                                                No
                                            </div>
                                        </div>
                                        <div *ngIf="cancelError" class="text">{{ cancelError }}</div>
                                    </div>
                                </app-dropdown>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="(displayed === 'dashboard' && businessPurchases) || !business" class="content impact-container">
            <!--
            <img *ngIf="estateAgent" class="roadmap" src="../../../assets/roadmap.png" />
            -->
            <div *ngIf="currentStep" class="setup-main-title">Account Setup Status:</div>
            <app-percentage class="percentage-mob" *ngIf="estateAgent" [percentage]="setupPercentage | number:'1.0-0'"></app-percentage>
            <div *ngIf="currentStep" class="account-setup">
                <app-percentage class="percentage" *ngIf="estateAgent" [percentage]="setupPercentage | number:'1.0-0'" [color]="currentStep.color"></app-percentage>
                <pre class="setup-text">
                    <span class="setup-title">{{ currentStep.title }}</span><ng-container *ngFor="let step of currentStep.steps">
<input *ngIf="step.checkbox" class="checkbox" type="checkbox" (ngModelChange)="updateAccountSteps(step)"
                            [ngModel]="step.done" [disabled]="step.done"><span [class]="(step.links ? 'underline pointer bold' : '') + (step.done ? ' strike' : '')" (click)="step.links ? displayed = 'media' : ''">{{ step.checkbox ? '' : '•'}} {{ step.title }} {{ '\n' }}</span></ng-container>            
<ng-container *ngIf="currentStep.exampleText"><div>{{ currentStep.exampleText.title }}</div>
<div class="italics centered">{{ currentStep.exampleText.quote }}</div>
<div>{{ currentStep.exampleText.brackets }}</div>
</ng-container>
                </pre>
            </div>
            <div *ngIf="warning" class="warning"> {{ warning }} </div>
            <div *ngIf="noPlan && !business" class="warning">Info: You do not have any plan selected</div>
            <div *ngIf="voucher" class="warning">These orders are examples only. Your actual orders will appear here after your first one has completed</div>
            <div *ngIf="!voucher && business" class="warning">If a new order does not appear, please wait a few seconds and refresh the page.</div>
            <ng-container *ngIf="recentBusinessPurchases">
                <app-title  text="Recent Orders"></app-title>
                <div class="recent-orders orders">
                    <div class="order bold">
                        <div class="cell date">Date</div>
                        <div class="cell cost">Cost</div>
                        <div *ngIf="estateAgent" class="cell type">Type</div>
                        <div class="cell value">Impact</div>
                        <div class="cell ref">Reference</div>
                        <div class="cell cert">Documents</div>
                    </div>
                    <div *ngFor="let order of recentBusinessPurchases" class="order">
                        <div class="cell date">{{ order.date | date }}</div>
                        <div class="cell cost">£{{ order.cost }}</div>
                        <div *ngIf="estateAgent" class="cell type">
                            <span [class]="order.estateAgentPurchase ? order.letting ? 'letting' : 'sale' : 'donation'">
                                {{ order.estateAgentPurchase ? order.letting ? 'Letting' : 'Sale' : 'Donation' }}
                            </span>
                        </div>
                        <div class="cell value">{{ order.projectId === 5 ? order.value / 100 : order.value }} {{
                            getImpactText(order.projectId) }}</div>
                        <div class="cell ref">{{ order.reference || 'None' }}</div>
                        <div class="cell cert"><span><a [href]="order.certificate" target="_blank">certificate </a></span>&nbsp;<span
                                *ngIf="order.receipt"><a [href]="order.receipt" target="_blank"> receipt</a></span></div>
                    </div>
                </div>
            </ng-container>
            <app-title text="Your Total Impact"></app-title>
            <div class="total-impacts">
                <div class="total-impact-container">
                    <div class="total-impact-graphic">
                        <img class="total-impact-image" src="../../../assets/trees-impact-logo.png">
                        <div 
                            class="total-impact-value" 
                            [style]="
                                '--f:' +
                                'min(' + 
                                (25/(maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 0.5 * (maxTotalsLength >= 1 ? maxTotalsLength : 3 )) + 
                                'vw,' +
                                (400/(maxTotalsLength >= 3 ? maxTotalsLength : 3)  + 0.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 )) +
                                'px);' +
                                '--m:' + 
                                (1.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 55) +
                                '%'
                            "
                        >
                            {{ totalTrees }}
                        </div>
                    </div>
                    <div class="total-impact-text"><div>Total Trees</div> <span class="nowrap">Planted 
                        <i 
                            class="fa fa-question-circle token" 
                            style="font-size: min(calc(5vw), 30px)"
                            tabindex="0"
                            tooltip='This counter allows you to track the total number of trees you have planted, across our global network of tree-planting projects. Watch it grow each time you support any of our tree-planting projects.' [options]="ttConfig"
                        >
                            
                        </i></span>
                    </div>
                </div>
                <div class="total-impact-container">
                    <div class="total-impact-graphic">
                        <img class="total-impact-image" src="../../../assets/co2-logo.png">
                        <div 
                            class="total-impact-value"
                            [style]="
                                '--f:' +
                                'min(' +
                                (25/(maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 0.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 )) + 
                                'vw,' +
                                (400/(maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 0.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 )) +
                                'px);' +
                                '--m:' + 
                                (1.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 55) +
                                '%'
                            "
                        >
                            {{ totalCO2 }}
                        </div>
                    </div>
                    <div class="total-impact-text"><div>Total CO2</div> <span class="nowrap">Removed
                        <i 
                            class="fa fa-question-circle token" 
                            style="font-size: min(calc(5vw), 30px)"
                            tabindex="0"
                            tooltip='We carefully calculate the total CO2 emmissions you have removed by adding together the impact of all trees planted and solar lights provided across our tree-planting and solar lighting projects. We use an aggregate of studies carried out on our trees to ensure the most accurate CO2 figure per tree (150kg per tree).' [options]="ttConfig"
                        >
                        </i></span>
                        
                    </div>
                </div>
                <div class="total-impact-container">
                    <div class="total-impact-graphic">
                        <img class="total-impact-image" src="../../../assets/rewild-logo.png">
                        <div
                            class="total-impact-value"
                            [style]="
                                '--f:' + 
                                'min(' +
                                (25/(maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 0.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 )) + 
                                'vw,' +
                                (400/(maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 0.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 )) + 
                                'px);' +
                                '--m:' + 
                                (1.5 * (maxTotalsLength >= 3 ? maxTotalsLength : 3 ) + 55) +
                                '%'
                            "
                        >
                            {{ totalLand }}
                        </div>
                    </div>
                    <div class="total-impact-text"><div>Total Land</div> <span class="nowrap">Afforested
                        <i 
                            class="fa fa-question-circle token" 
                            style="font-size: min(calc(5vw), 30px)"
                            tabindex="0"
                            [tooltip]="'This counter shows the total land you have afforested, including all land rewilded via our Wildflower Rewilding project, and all afforested land across our tree-planting projects (1m\u00b2 per tree planted).'" [options]="ttConfig"
                        >
                           
                        </i></span>
                    
                    </div>
                </div>
            </div>
            <app-title text="Project Tracker"></app-title>
            <div class="mob-impact">
                <div *ngFor="let i of getProjectIndexArray()" class="row">
                    <div [class]="i % 2 == 0 ? 'impact' : 'impact impact-reverse'" [attr.data-border-radius-top]="i === 0" [attr.data-border-radius-bottom]="i === projects.length - 1">
                        <div class="impact-project">
                            <div class="not-counter">
                                <img class="impact-image" [src]="projects[i].image">
                                <div class="impact-project-name" [innerHtml]="getImpactText(projects[i])"></div>
                                <div 
                                    class="details-button mob pointer clickable" 
                                    (click)="details = projects[i].id; detailsName = projects[i].name"
                                >
                                    Details
                                </div>
                                <a class="details-button pointer clickable" [href]="'/our-projects/' + getProjectUrl(projects[i].id)" target="_blank">
                                    Learn More
                                </a>
                            </div>
                        </div>
                        <div class="impact-value">
                            <div class="impact-value-text" 
                            [style]="
                                '--f:' +
                                'min(' + 
                                (50/(getImpactValue(projects[i]).toString().length !== 1 ? getImpactValue(projects[i]).toString().length : 2) + 0.5 * (getImpactValue(projects[i]).toString().length !== 1 ? getImpactValue(projects[i]).toString().length : 2)) + 
                                'vw,' +
                                (800/(getImpactValue(projects[i]).toString().length !== 1 ? getImpactValue(projects[i]).toString().length : 2) + 0.5 * (getImpactValue(projects[i]).toString().length !== 1 ? getImpactValue(projects[i]).toString().length : 2)) +
                                'px);'
                            "
                            >
                                {{ getImpactValue(projects[i]) }}
                            </div>
                        </div>
                    </div>
                    <div class="impact-details-background">
                        <div class="impact-details-container">
                            <div class="impact-details" [innerHtml]="getDetailsTextDT(projects[i].id)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!noPlan">
                <app-title text="Your Current Projects"></app-title>
                <app-projects *ngIf="projectsSection" account="true" [projects]="projectsSection"></app-projects>
            </ng-container>
        </div>
        <div *ngIf="business && !businessPurchases && displayed === 'dashboard'" class="content impact-container">
            <div class="business-text">Please drop us a line setting out briefly which of our business services you are interested in, and we
                will get back to
                you within 5 working days to arrange a 1-2-1 meeting with one of our business advisors.</div>
            <a class="extra-padding pointer clickable" href="mailto:contact@edengreenspace.co.uk" target="_blank">
                Email us at contact@edengreenspace.co.uk
            </a>
        </div>
        <div *ngIf="displayed === 'orders' && businessPurchases" class="content white impact-container">
            <div *ngIf="voucher" class="warning">These orders are examples only. Your actual orders will appear here after your first one has completed</div>
            <app-title text="Your Orders"></app-title>
            <div class="orders">
                <div class="order bold">
                    <div class="cell date">Date</div>
                    <div class="cell cost">Cost</div>
                    <div *ngIf="estateAgent" class="cell type">Type</div>
                    <div class="cell value">Impact</div>
                    <div class="cell ref">Reference</div>
                    <div class="cell cert" >Documents</div>
                </div>
                <div *ngFor="let order of businessPurchases" class="order">
                    <div class="cell date">{{ order.date | date }}</div>
                    <div class="cell cost">£{{ order.cost }}</div>
                    <div *ngIf="estateAgent" class="cell type">
                        <span [class]="order.estateAgentPurchase ? order.letting ? 'letting' : 'sale' : 'donation'">
                            {{ order.estateAgentPurchase ? order.letting ? 'Letting' : 'Sale' : 'Donation' }}
                        </span>
                    </div>
                    <div class="cell value">{{ order.projectId === 5 ? order.value / 100 : order.value }} {{ getImpactText(order.projectId) }}</div>
                    <div class="cell ref">{{ order.reference || 'None' }}</div>
                    <div class="cell cert"><span><a [href]="order.certificate" target="_blank">certificate </a></span>&nbsp;<span *ngIf="order.receipt"><a [href]="order.receipt" target="_blank">receipt</a></span></div>
                </div>
            </div>
        </div>
        <app-media class="media" *ngIf="displayed === 'media'"></app-media>
        <app-business-how-it-works class="media" *ngIf="displayed === 'how-it-works'"></app-business-how-it-works>
    </div>
</div>
<div *ngIf="!name" class="content">
    <br/><br/>
    <img src="../../../assets/loading.gif" />
    <br /><br />
</div>
<div *ngIf="noPlan && !projects" class="content space2"></div>

<!-- Logout Modal -->
<div *ngIf="modal" id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content content">
        <span class="close pointer" (click)="modal = false">&times;</span>
        <div class="modal-scroll">
            <p>
                Are you sure you want to logout?
            </p>
            <div class="buttons2">
                <div class="button-container">
                    <a id="learn-more" class="modal-button pointer clickable" (click)="logout()">Logout</a>
                </div>
                <div class="button-container">
                    <a id="learn-more" class="modal-button pointer clickable" (click)="modal = false">Cancel</a>
                </div>
            </div>
        </div>
    </div>
          
</div>

<!-- Details Modal -->
<div *ngIf="details" id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content content">
        <span class="close pointer" (click)="details = undefined">&times;</span>
        <div class="modal-title" [innerHtml]="detailsName"></div>
        <div class="modal-scroll">
            <p [innerHtml]="getDetailsText()">
            </p>
        </div>
    </div>
            
</div>

<!-- Info Modal -->
<div *ngIf="info" id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content content modal-wide">
        <span class="close pointer" (click)="info = false">&times;</span>
        <div class="modal-title">Welcome to your personalised {{ getBusinessName(name) }} account!</div>
        <div class="modal-scroll">
            <p>
                Welcome to your Eden Greenspace online account and Impact Creation System for estate agents. Using this system, you'll
                be able to support Eden's environmental projects and provide your vendors and landlords with a personalised Certificate
                of Impact, each time you sell a property or take on a new tenancy.
                <br /><br />
                Start off by heading to the 'How it works' page: this is your complete guide for marketing and selling your partnership
                with Eden Greenspace to vendors and landlords, from valuation right through to completion.
                <br /><br />
                Any questions? Get in touch at any time via contact@edengreenspace.co.uk.
                <br /><br />
                All the best,<br />
                The Eden Greenspace Team.
            </p>
        </div>
    </div>

</div>

<!-- Level Popup Modal -->
<div *ngIf="levelPopup" id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content content modal-wide">
        <span class="close pointer" (click)="resetPopup()">&times;</span>
        <ng-container *ngIf="level === 'sappling'">
            <img class="modal-logo" [src]="levelLogo">
            <div class="modal-scroll">
                <pre class="levels-text">
                    <span class="bold">Your business has made enough impact to be rewarded with the next level of partnership with Eden Greenspace.</span>
                    
                    So far*, you have successfully created two of these positive environmental impacts:
                    
                    • 2501 trees planted
                    • 251kg plastic cleaned
                    • 1251m2 rewilded land
                    • 255 people provided with lighting
                    
                    *(annual impact)
                    
                    <span class="bold">Thank you for contributing to the community of people passionate about restoring the planet!</span>
                    
                    <span class="bold">UNLOCKED (See your media section)</span>
                    
                    • <span class="bold">x3 new promotional posters.</span>
                    • <span class="bold">In-depth interviews with project partners on the ground.</span>
                    • <span class="bold">Sapling Level promotional post and badge.</span>
                </pre>
            </div>
        </ng-container>
        <ng-container *ngIf="level === 'tree'">
            <img class="modal-logo" [src]="levelLogo">
            <div class="modal-scroll">
                <pre class="levels-text">
                    <span class="bold">Your business has made enough impact to be rewarded with the next level of partnership with Eden Greenspace.</span>
                    
                    So far*, you have successfully created two of these positive environmental impacts:
                    
                    • 10,001 trees planted
                    • 1,000kg plastic cleaned
                    • 5,001m2 rewilded land
                    • 1005 people provided with lighting
                    
                    *(annual impact)
                    
                    <span class="bold">Thank you for contributing to the community of people passionate about restoring the planet!</span>
                    
                    <span class="bold">UNLOCKED (See your media section)</span>
                    
                    • <span class="bold">Premium photo and video library.</span>
                    • <span class="bold">Personal Eden Greenspace advisor.</span>
                    • <span class="bold">Tree Level promotional post, badge.</span>
                </pre>
            </div>
        </ng-container>
        <ng-container *ngIf="level === 'grove'">
            <img class="modal-logo" [src]="levelLogo">
            <div class="modal-scroll">
                <pre class="levels-text">
                    <span class="bold">Your business has made enough impact to be rewarded with the next level of partnership with Eden Greenspace.</span>
                    
                    So far*, you have successfully created two of these positive environmental impacts:
                    
                    • 25,001 trees planted
                    • 2,501kg plastic cleaned
                    • 12,501m2 rewilded land
                    • 2505 people provided with lighting
                    
                    *(annual impact)
                    
                    <span class="bold">Thank you for contributing to the community of people passionate about restoring the planet!</span>
                    
                    <span class="bold">UNLOCKED (See your media section)</span>
                    
                    • <span class="bold">Your own specific special projects - e.g. commission a plastic clean-up trip to the Isles of Scilly, unique rewilding projects in a set location targeting specific endangered pollinators.</span>
                    • <span class="bold">Tree-planting photos at before, 6-month and one-year stage.</span>
                    • <span class="bold">Grove Level promotional post and badge.</span>
                </pre>
            </div>
        </ng-container>
        <ng-container *ngIf="level === 'forest'">
            <img class="modal-logo" [src]="levelLogo">
            <div class="modal-scroll">
                <pre class="levels-text">
                    <span class="bold">Your business has made enough impact to be rewarded with the next level of partnership with Eden Greenspace.</span>
                    
                    So far*, you have successfully created two of these positive environmental impacts:
                    
                    • 100,001+ trees planted
                    • 10,001 kg+ plastic cleaned
                    • 50,001m2+ rewilded land
                    • 12,505+ provided with lighting
                    
                    *(annual impact)
                    
                    <span class="bold">Thank you for contributing to the community of people passionate about restoring the planet!</span>
                    
                    <span class="bold">UNLOCKED (See your media section)</span>
                    
                    • <span class="bold">Our team to come and film a documentary showing off the green impact your organisation has created.</span>
                    • <span class="bold">Tree-planting designated sites.</span>
                    • <span class="bold">Annual reports of all project donations hitting the above thresholds, detailing the impact made.</span>
                    • <span class="bold">Forest level promotional post and badge.</span>
                </pre>
            </div>
        </ng-container>
    </div>

</div>

<!-- Contact Us Modal -->
<div *ngIf="contactUs" id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content content contact-modal">
        <span class="close pointer" (click)="contactUs = false">&times;</span>
        <div class="modal-title">
            The personal contacts for your business are:
        </div>
        <div class="modal-scroll">
            <pre class="levels-text">
                <span class="bold">Samuel Abel</span>
                07460979002
                samuelabel@edengreenspace.co.uk
                
                <span class="bold">Ryan Cooper</span>
                07817332050
                ryancooper@edengreenspace.co.uk
                
                Questions about our projects? See our <span class="pointer bold underline orange" routerLink="/our-projects">Projects FAQs</span>
            </pre>
        </div>
    </div>

</div>
