import { Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { projects } from 'server/projects';
import { Poll, Vote } from 'server/types';
import { AuthService } from 'src/app/services/auth.service';
import { getDate } from '../blog/blog.component';
import { BIOS } from '../projects/projects.component';

interface ProjectText {
  id: number;
  text: string;
}

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.css']
})
export class PollsComponent implements OnInit {
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  @Input() voteEnabled = true;

  public getDate = getDate;

  private projectTexts: ProjectText[] = [
    {
      id: 4,
      text: 'You can help increase wild bee populations by creating rich wildflower meadows in the UK. Bee populations have been decimated by urbanisation and climate change over the last decade, despite their critical role as pollinators. The urban landscapes that we see as neat and tidy are viewed by bees as desert. By creating channels for bees to migrate through, you can help dwindling bee populations to thrive.'
    },
    {
      id: 5,
      text: "By choosing this project, you will remove toxic marine plastic from the oceans around the UK. Humans deposit 8 million tonnes of plastic into the ocean every year. Not only is plastic highly destructive for sea life such as whales and turtles, but indirectly accelerates climate change as these creatures are essential for keeping carbon out of the atmosphere. Help turn the tide on plastic waste by taking part in this project."
    },
    {
      id: 11,
      text: "Peatlands are our most precious terrestrial carbon sink, absorbing 30% of terrestrial carbon despite covering 3% of the earth’s surface. This allows the peat formation process to resume and provides habitat for endangered invertebrates and birds. Most importantly, this project reduces carbon emissions by turning peatland back into a carbon sink, actively addressing both the climate and nature crises in the UK."
    }
  ];

  public poll: Poll;
  public modal = false;

  public vote: Vote = {
    email: '',
    projectId: 0,
  }

  ngOnInit(): void {
    this.authService.getPoll(this.route.snapshot.params['pollId']).subscribe(poll => {
      this.poll = poll;

      if (new Date(poll.closes) < new Date()) {
        console.log("hello there");
        this.router.navigate(['/poll-results/' + poll._id]);
      }
    });
  }

  public getTitle(projectId: number): string {
    return projects.find(project => project.id === projectId).name;
  }

  public getImage(projectId: number): string {
    return BIOS.find(bio => bio.projectId === projectId).thumbnail;
  }

  public getText(projectId): string {
    return this.projectTexts.find(project => project.id === projectId).text;
  }

  public getUrl(projectId): string {
    return '/our-projects/' + BIOS.find(bio => bio.projectId === projectId).url;
  }

  public isValid(): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.vote.email);
  }

  public voteInPoll(): void {
    this.authService.voteInPoll(this.vote, this.poll._id).subscribe((_) => { 
      console.log("voted");
      this.router.navigate(['/poll-results/' + this.poll._id] ); })
  }

  public formatDate(date: Date): string {
    return this.getDate("nothing", date.toString());
  }

}
