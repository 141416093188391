export interface Project {
    id?: number,
    name: string,
    image?: string,
    multiplier: number,
    text: string,
    infoImage?: string,
    infoText?: string,
    infoImpact?: string,
    crisis: string,
}

export const projects: Project[] = [
    {
        id: 3,
        name: 'Tree Planting \n in Kenya',
        image: '../../../assets/kenya-logo.png',
        multiplier: 2,
        text: 'You will plant xxx trees /month in Kenya',
        infoImage: '../../../assets/3-1.png',
        infoText: 'By choosing to plant trees each month, you can fight climate change while supporting local communities in rural Kenya, which has experienced mass deforestation. Trees are the lungs of the planet, essential to maintaining a healthy climate. Tree planting is a natural and highly effective way of absorbing CO2 from the atmosphere, with each tree removing 150 kg of CO2 over its lifetime. Help us make an impact by planting 10 trees each month.',
        infoImpact: '1 <img class="info-logo clear-image-text" src="../../../assets/coinNEW1.png"> Coin = 2 trees',
        crisis: 'climate',
    },
    {
        id: 5,
        name: 'Ocean Plastic \n Removal in Cornwall',
        image: '../../../assets/plastics-logo.png',
        multiplier: 1,
        infoImage: '../../../assets/5-1.jpg',
        text: 'You will remove xxxkg of plastic /month',
        infoText: "By choosing this project, you will remove 1 kg of toxic marine plastic from the oceans around the UK - that's equivalent to 100 plastic bottles. Humans deposit 8 million tonnes of plastic into the ocean every year. Not only is plastic highly destructive for sea life such as whales and turtles, but indirectly accelerates climate change as these creatures are essential for keeping carbon out of the atmosphere. Help turn the tide on plastic waste by taking part in this project.",
        infoImpact: '1 <img class="info-logo clear-image-text" src="../../../assets/coinNEW1.png"> Coin = 1kg of plastic',
        crisis: 'pollution'
    },
    {
        id: 4,
        name: 'Wildflower Rewilding \n in Wales',
        image: '../../../assets/bees-logo.png',
        multiplier: 6,
        infoImage: '../../../assets/4-6.jpeg',
        text: 'You will rewild xxxm\u00b2 of land /month',
        infoText: "You can help increase wild bee populations by creating rich wildflower meadows in the UK. Bee populations have been decimated by urbanisation and climate change over the last decade, despite their critical role as pollinators. The urban landscapes that we see as neat and tidy are viewed by bees as desert. By creating channels for bees to migrate through, you can help dwindling bee populations to thrive.",
        infoImpact: '1 <img class="info-logo clear-image-text" src="../../../assets/coinNEW1.png"> Coin = 6m\u00b2 of land',
        crisis: 'nature'

    },
    {
        id: 1,
        name: 'Tree Planting \n in Nepal',
        image: '../../../assets/nepal-logo.png',
        multiplier: 2,
        infoImage: '../../../assets/1-1.png',
        text: 'You will plant xxx trees /month in Nepal',
        infoText: "By choosing to plant trees each month, you can fight climate change while supporting local communities in rural Nepal. One of the world's poorest countries, the income provided by tree planting is a lifeline for many locals here. Trees are the lungs of the planet, essential to maintaining a healthy climate. Tree planting is a natural and highly effective way of absorbing CO2 from the atmosphere, with each tree removing 150 kg of CO2 over its lifetime. Help us make an impact by planting 10 trees each month.",
        infoImpact: '1 <img class="info-logo clear-image-text" src="../../../assets/coinNEW1.png"> Coin = 2 trees',
        crisis: 'climate',
    },
    {
        id: 6,
        name: 'Solar Lighting \n in Southern Africa',
        image: '../../../assets/solar-logo.png',
        multiplier: 0.4,
        infoImage: '../../../assets/6-1.png',
        text: 'You will provide xxx people with Solar Lights /month',
        infoText: "By choosing to support this project, you can provide clean, safe lighting to some of the poorest families in the world, and at the same time reduce their carbon footprint. Families in Malawi and Zambia rely on toxic kerosene lighting which emits huge quantities of carbon dioxide into the atmosphere. By replacing this with a Solar Light, you can prevent 1.1 tonne of CO2 emissions. What’s more, Solar Lights have a lifespan of over 10 years, in which they provide families with free, sustainable energy, greater safety in the evenings, and an extended working day. Help us tackle the climate crisis while supporting some of the world's poorest families by choosing this project.",
        infoImpact: '5 <img class="info-logo clear-image-text" src="../../../assets/coinNEW1.png"> Coins = 2 People',
        crisis: 'climate',
    },
    {
        id: 2,
        name: 'Tree Planting \n in Madagascar',
        image: '../../../assets/mada-logo.png',
        multiplier: 2,
        infoImage: '../../../assets/2-1.jpg',
        text: 'You will plant xxx trees /month in Madagascar',
        infoText: "By choosing to plant trees each month, you can fight climate change while supporting local communities in Madagascar, where only 10% of its original forests remain. Trees are the lungs of the planet, essential to maintaining a healthy climate. Tree planting is a natural and highly effective way of absorbing CO2 from the atmosphere, with each tree removing 150 kg of CO2 over its lifetime. Help us make an impact by planting 10 trees each month.",
        infoImpact: '1 <img class="info-logo clear-image-text" src="../../../assets/coinNEW1.png"> Coin = 2 trees',
        crisis: 'climate',
    },
    {
        id: 7,
        name: 'Clean Cookstoves',
        image: '../../../assets/stoves-logo.png',
        multiplier: 0.2,
        text: 'You will provide xxx cookstoves per month',
        infoImage: '../../../assets/7-1.jpg',
        infoImpact: '5 <img class="info-logo clear-image-text" src="../../../assets/coinNEW1.png"> Coins = 1 Stove',
        infoText: 'By choosing this project, you can provide clean, efficient cookstoves to families in Uganda. Each stove helps to phase out the highly-polluting open fire cooking method used by 2.6 billion people worldwide. By using 66% less wood than open-fire cooking, clean cookstoves not only reduce toxic air particulates, but address a major cause of deforestation. Help reduce pollution and reduce global carbon emissions by supporting this project.',
        crisis: 'pollution',
    },
    {
        id: 8,
        name: 'Coral Reef Protection in Mexico',
        image: '../../../assets/corals-logo.png',
        multiplier: 6,
        text: 'You will save xxxm\u00b2 of coral reef per month',
        infoImage: '../../../assets/8-1.jpg',
        infoImpact: '1 <img class="info-logo clear-image-text" src="../../../assets/coinNEW1.png"> Coin = 6m\u00b2 of reef',
        infoText: 'By choosing to save coral reefs each month, you can support one of the world’s most beautiful and precious ecosystems. Despite being the most biodiverse marine ecosystems in the world on which many other marine life depend, corals are critically threatened. Half of the world’s coral reefs have been lost since 1950, and the vast majority are at risk from global warming, overfishing and unsustainable tourism. Funding this project will protect the island of Cozumel, Mexico from illegal overfishing and its world-famous corals for years to come.',
        crisis: 'nature',
    },
    {
        id: 9,
        name: 'Coral Reef Restoration in Hawaii',
        image: '../../../assets/corals-logo.png',
        multiplier: 6,
        text: 'You will save xxxm\u00b2 of coral reef per month',
        infoImage: '../../../assets/9-1.jpg',
        infoImpact: '1 <img class="info-logo clear-image-text" src="../../../assets/coinNEW1.png"> Coin = 6m\u00b2 of reef',
        infoText: 'By choosing to save coral reefs each month, you can support one of the world’s most beautiful and precious ecosystems. Despite being the most biodiverse marine ecosystems in the world on which many other marine life depend, corals are critically threatened. Half of the world’s coral reefs have been lost since 1950, and the vast majority are at risk from global warming, overfishing and unsustainable tourism. Funding this project will restore Hawaii’s ancient coral reefs through coastal restoration work on the island of Maui.',
        crisis: 'nature',
    },
    {
        id: 11,
        name: 'Peatland Restoration In Scotland',
        image: '../../../assets/peatland-logo.png',
        multiplier: 1.6,
        text: 'You will restore xxxm\u00b2 of peatland per month',
        infoImage: '../../../assets/11-1.png',
        infoImpact: '3 <img class="info-logo clear-image-text" src="../../../assets/coinNEW1.png"> Coins = 4m\u00b2 of land',
        infoText: 'Peatlands are our most precious terrestrial carbon sink, absorbing 30% of terrestrial carbon despite covering 3% of the earth’s surface. Yet peatland is being degraded across the globe, with UK peatland area diminished by 94% over the last century. Our peatland restoration project is restoring over 260 ha of peatland habitat by rewetting raised bogs in the Scottish lowlands. This allows the peat formation process to resume and provides habitat for endangered invertebrates and birds. Most importantly, this project reduces carbon emissions by turning peatland back into a carbon sink, actively addressing both the climate and nature crises in the UK.',
        crisis: 'nature',
    },
];