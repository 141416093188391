<ng-container *ngIf="option === 'overview'">
    <div class="content white-background">
        <div class="image-container">
            <img class="background-image" src="../../../assets/methodology-header.png" />
            <div class="on-image">
                <h1 class="title" large="true">Overview</h1>
            </div>
        </div>
        <div class="text-container">
            <h2>Why?</h2>
            <h3>Planetary Crisis.</h3>
            <pre class="new-text">
                Our planet is under threat. We are on track to surpass 2 degrees warming by 2100, the direct result of human-caused greenhouse gas emissions and the degradation of our natural carbon sinks.
                
                Everybody knows there is a problem. But solving it requires more than just cutting our carbon emissions
                
                In order to limit warming to 1.5°C, halt biodiversity loss and promote sustainable consumption, we need to take drastic action to stop polluting, bring back biodiversity and restore our natural carbon sinks.
                
                We need to restore our planet.
            </pre>
            <br />
            <br/>
            <h2>How?</h2>
            <h3>Anti-Greenwashing Methodology</h3>
            <div class="quote">‘What if saving the planet were as simple as planting a tree?’</div>
            <div class="quote">‘Offset your carbon footprint and become climate positive.’</div>
            <pre class="new-text">
                Sounds familiar, right? Unfortunately, restoring the planet is not that easy.

                We cannot ‘offset’ our way out of the climate crisis - this only <span class="underline turq-text bold pointer" routerLink="/about/offsetting">puts off real change to our lifestyles</span>.
                
                We cannot just tree-plant our way out of the environmental crisis - trees are only one small part of restoring our environment.
                
                Instead, our sector-leading <a class="link" href="/articles/anti-greenwashing-methodology" routerLink="/articles/anti-greenwashing-methodology">Anti-Greenwashing Methodology</a> ensures that businesses are able to fund a range of environmental projects that are trustworthy, highly-effective, and create quantified impact on the ground, as monitored by our specialist team.

                This holistic and anti-greenwashing approach to environmental repair is the only long-term, science-based method to restore our planet and limit global warming to 1.5°C in line with the <a class="link" href="https://unfccc.int/process-and-meetings/the-paris-agreement" target="_blank">Paris Agreement</a>.
            </pre>
            <br />
            <h2>What?</h2>
            <h3>An Environmental Platform for the Planet.</h3>
            <pre class="new-text">
                Our methodology is entirely based on UN Environment Programme <a class="bold turq-text pointer underline" target="_blank" href="https://www.unep.org/">(UNEP) guidelines</a>.
                
                The UNEP have identified <a class="bold turq-text underline" target="_blank" href="https://unfccc.int/blog/what-is-the-triple-planetary-crisis">three crises</a> facing our planet: <span class="bold">climate, environment, pollution</span>.
                
                Eden Greenspace is the only <span class="bold">non-profit organisation</span> that lets climate-conscious individuals and businesses fund a range of projects which address each of these issues.
            </pre>
        </div>
        <div class="buttons">
            <button routerLink="/articles/anti-greenwashing-methodology">Anti-Greenwashing Methodology</button>
            <button routerLink="/business">Act Now</button>
        </div>
        <br/><br/>
        <div class="content new-green">
            <br />
            <br/>
            <img class="big-image clear-image-text" src="../../../assets/methodology-crisis.png" />
            <br />
            <br />
            <button routerLink="/our-projects">Our Projects</button>
            <br />
        </div>
    </div>
    <!--
    <div class="content beige">
        <div #ecoCoins class="left-sub-title">How it works</div>
        <div class="text"><span class="bold">There’s no one size fits all solution to the three planetary crises.</span> Supporting a range of projects is the only long-term, sustainable way to restore our planet.</div>
        <div class="text">We allow individuals to do this using our unique <span class="underline bold">Eco-Coins. </span><img class="coin-image" src="../../../assets/coinNEW1.png" /></div>
        <div class="row">
            <div class="flex">
                <div class="project-container">
                    <div class="projects">
                        <a *ngFor="let project of projectExamples" class="project" [routerLink]="project.url" target="_blank">
                            <img class="info-button pointer">
                            <div class="counter info-offset">
                                <img class="coin" src="../../../assets/coin3.png">
                                <div class="count">
                                    {{ project.id }}
                                </div>
                            </div>
                            <div class="not-counter">
                                <img class="project-image" [src]="project.image">
                                <div class="project-name" [innerHtml]="project.name"></div>
                            </div>
                            <div class="project-impact">{{ project.infoImpact }}</div>
                        </a>
                    </div>
                    <div class="projects">
                        <div>
                            &nbsp;
                        </div>
                        <a class="project" [routerLink]="project.url" target="_blank">
                            <img class="info-button pointer">
                            <div class="counter info-offset">
                                <img class="coin" src="../../../assets/coin3.png">
                                <div class="count">
                                    {{ project.id }}
                                </div>
                            </div>
                            <div class="not-counter">
                                <img class="project-image" [src]="project.image">
                                <div class="project-name" [innerHtml]="project.name"></div>
                            </div>
                            <div class="project-impact">{{ project.infoImpact }}</div>
                        </a>
                        <div id="view-our-projects" routerLink="/our-projects" class="button pointer clickable">
                            View Projects
                        </div>
                    </div>
                </div>
                <div class="center">

                </div>
            </div>
            <div class="eco-coins">
                <div class="text">Eco-Coins represent one unit of environmental impact, and can be spent across any of our projects.</div>
                <div class="text">
                    {{ 'With one Eco-Coin, you can: ' }}
                    <div class="tab">• Plant 10 <span class="nowrap">trees &larr; <span class="nature">Nature Crisis</span></span>{{ '\n' }}</div>
                    <div class="tab">• Remove 1kg of plastic from the <span class="nowrap">ocean &larr; <span class="pollution">Pollution Crisis</span></span>{{ '\n' }}</div>
                    <div class="tab">• Help fund one solar <span class="nowrap">light &larr; <span class="climate">Climate Crisis</span></span></div>
                </div>
            </div>
        </div>
        <div class="text">To learn more about our projects, and how you can buy Eco-Coins, take a look at <span class="bold orange-no-highlight pointer underline" routerLink="/choose-your-plan">our plans</span>.</div>
    </div>
    <div class="content beige">
        <div class="left-sub-title">Eco-Coins Values</div>
        <div class="text">In case you’d forgotten the value of each Eco-Coin, or you’d like to see them all side-by-side, we’ve put them together
        in a table for you.</div>
        <div class="text">Remember that each Eco-Coin is worth £2, and you can spend as little (or as many!) as you'd like across any of our projects, when you
        sign up a <span class="link" routerLink="/choose-your-plan">monthly subscription plan</span>.</div>
        <img class="big-image clear-image-text" src="../../../assets/table.png" />
        <br/><br/>
    </div>
    -->
    <div class="content">
        <h1>Our Pledge</h1>
        <div class="pledge-container">
            <div class="pledge">
                <div class="text">What you see is what you get. As a not-for-profit, we guarantee that <span class="bold">100% of
                        the money donated</span> to our environmental projects goes directly into funding restoration work on the
                    ground.</div>
                <div class="text">How do we fund our curation work? We charge our corporate clients an additional 17.5% fee on all
                    donations, to access our online account, marketing suite and receive expert advice.</div>
                <div class="text">All donations received through Eden’s platform can be viewed on our <span
                        class="pointer bold clickable underline orange" routerLink="/about/impact">Public Impact Registry</span>.
                </div>
            </div>
            <img class="image" src="../../../assets/pie-chart.png" />
        </div>
        <h1>Impact Made Transparent</h1>
        <br/><br/><br/>
        <div class="values">
            <div class="value">
                <img class="value-image" src="../../../assets/meth-evidence.png"/>
                <button><a class="button-link" target="_blank"
                    href="https://docs.google.com/spreadsheets/d/1A7nLmHQWvBZgTqCxm1EUVCQlY1agC3DLpNkOFhR-hT4/edit#gid=1742437033 ">Evidence of Impact</a></button>
            </div>
            <div class="value">
                <img class="value-image" src="../../../assets/meth-impact.png" />
                <button><a class="button-link"
                    href="/about/impact" routerLink="/about/impact">Total Impact</a></button>
            </div>
            <div class="value">
                <img class="value-image" src="../../../assets/meth-info.png" />
                <button ><a class="button-link" href="/our-projects" routerLink="/our-projects">Projects Info and FAQs</a></button>
            </div>
        </div>
        <br/>
        <br />
    </div>
</ng-container>
<div *ngIf="option === 'impact'" class="content new-green">
    <app-title text="The Impact Of Our Community"></app-title>
    <div class="text">We always provide <a class="bold turq-text underline" target="_blank" href="https://docs.google.com/spreadsheets/d/1A7nLmHQWvBZgTqCxm1EUVCQlY1agC3DLpNkOFhR-hT4/edit#gid=1742437033 ">evidence</a> of the impact our projects have, including <span class="nowrap"><span class="bold">regular updates. </span> 
        <i 
            class="fa fa-question-circle token" 
            style="font-size: min(calc(15px + 2vw), 25px)"
            (click)="$event.stopPropagation(); tooltipOpen = !tooltipOpen"
            (focusout)="tooltipOpen = false"
            tabindex="0"
        >
            <span class="tooltiptext" [attr.data-open]="tooltipOpen" (mousedown)="$event.preventDefault()">
                You can see updates for all the projects you support in your <span class="bold turq-text pointer" routerLink="/account-page">Account.</span>
            </span>
        </i>
    </span>
    </div>
    <div class="text">But sometimes the numbers speak for themselves:</div>
    <app-total-impacts></app-total-impacts>
    <br/><br/><br/>
    <div class="impacts">
        <div *ngFor="let project of projects" class="impact">
            <div class="impact-text-container">
                <div class="impact-text">{{ getImpactText(project) }}</div>
                <div class="impact-value turq-text bold">{{ getImpactValue(project) | number }}</div>
            </div>
            <img class="impact-image" [src]="project.image"/>
        </div>
    </div>
    <br/>
    <div class="buttons">
        <button routerLink="/articles/anti-greenwashing-methodology">Anti-Greenwashing Methodology</button>
        <button> 
            <a 
                href="/business" 
                class="button-link"
                target="_blank"
                routerLink="/business"
            >
                Act Now
            </a>
        </button>
    </div>
    <br />
</div>
<div *ngIf="option === 'story'" class="content">
    <app-title class="title-space" text="Our Story" large="true"></app-title>
    <div class="text">In late 2020 co-founders Ryan and Sam were in the midst of the Covid 19 pandemic. They saw the effects of covid
    lockdowns cause our natural environments to regrow, and a global reduction in CO2 emissions.<br/><br/>But they were frustrated to see this glimmer of hope disappear as the pandemic subsided and normal life resumed. Once
    again, the planet was being forgotten.</div>
    <div class="text">That’s why Ryan and Sam created Eden Greenspace - a platform designed to restore the damage humans are doing to our
    planet, and bring nature back into our lives for good.<br/><br/>Right from the start, they wanted to avoid the greenwashing messages that ‘planting a tree’ was the one-size-fits-all
    solution which would save the planet.<br/><br/>Instead of just planting trees, they spent over a year researching the best environmental projects across the world,
    everything from rainforest protection to rewilding to ocean clean up projects. They then decided that a great way to
    choose projects would be to start with projects that address one of the UN Environment Programme’s <span class="link" routerLink="/about/overview">three planetary
    crises</span>.</div>
    <div class="text">By bringing all these projects together, the goal was to make real environmental action easy and accessible for ordinary
    people, who might never usually support environmental causes.<br/><br/>From there, it just kept growing, and they’re still working hard to spread awareness of these amazing projects, and
    bringing individuals and businesses together to support them.</div>
    <br/><br />
    <img class="big-image clear-image-text" src="../../../assets/ryan-sam.jpg" />
    <div class="text">Ryan and Sam in Madagascar, investigating the <span class="link" routerLink="/our-projects/tree-planting-in-madagascar">first tree planting project</span> we signed.</div>
    <br /><br />
    <div class="buttons">
        <button id="choose-your-plan" routerLink="/our-projects">
            Projects
        </button>
        <button routerLink="/about/overview">
            Overview
        </button>
    </div>
    <br/><br />
</div>
<div *ngIf="option === 'projects'" class="content">
    <app-title class="title-space" text="How we select our projects" large="true"></app-title>
    <div class="text">Before Eden Greenspace creates a project, founders Ryan and Sam spend months personally investigating the project sites
    to ensure they meet our list of 7 key criteria in order to become <span class="italics">Eden Greenspace Certified.</span>
    But selecting which projects to support is not an easy task, as not only do many so-called ‘environmental restoration
    projects’ actually engage in greenwashing, but the breadth of the ecological crisis is so vast that it is often
    difficult to know where to start.</div>
    <div class="text">That’s why we always start by taking advice from the UNEP -</div>
    <div class="left-sub-title">1. Must directly address one of the UNEP’s Three Planetary Crises</div>
    <div class="text">The most important criterion for our projects is that they must directly address one of the UN Environment Programme’s
        ‘<a class="link" target="_blank" href="https://unfccc.int/blog/what-is-the-triple-planetary-crisis">three planetary crises</a>’ - the climate crisis,
        the nature crisis, and the pollution crisis.
    </div>
    <div class="text">This is because the science shows that it is not enough to simply solve one problem on the other side of the world. To
        truly address these crises requires a <span class="italics">holistic approach</span> - supporting a diverse range of initiatives across all parts of
        the globe, where each one addresses one or more of these three core problems - climate, nature and pollution. For
        example, our <a class="link" routerLink="/our-projects/tree-planting-in-nepal" target="_blank">tree-planting projects</a> address the climate and nature crisis, and our <a class="link " routerLink="/our-projects/marine-plastic-removal-around-the-uk" target="_blank">marine plastic removal
        project</a> addresses the pollution crisis.
    </div>
    <div class="text">This is the fundamental starting point for everything we do at Eden Greenspace - making sure that we are channelling
        support to a diverse range of projects. Only in this way can we truly claim to be working towards our broader mission,
        to <span class="turq-text bold">restore our planet</span>.
    </div>
    <img class="big-image clear-image-text" src="../../../assets/project-criteria.png"/>
    <br/><br/><br/>
    <div class="left-sub-title">2. Quantification</div>
    <div class="text">The positive impact of our projects must be clearly quantified at every stage. With lots of social enterprises and
        charities, customers feel as if they end up throwing money down a black hole of charitable ‘impact’.<br/><br/>By specifically choosing projects that have a quantified unit of environmental effect, such as planting a tree, or
        removing 1kg of marine plastic from the ocean, we can track the exact amount of environmental repair which your money is
        paying for.<br /><br />As an individual, you can take action by buying one of our tree-planting <a routerLink="/products/eden-bracelet" href="/products/eden-bracelet" class="underline bold turq-text">Eden Bracelets</a>: each bracelet directly funds the planting of 10 trees in the Great Rift Valley, Kenya.
        <br/><br/>As a business, you can create a precise amount of quantified, positive impact across any of our projects. Monthly, or
        for each product you sell, you can plant trees, rewild land, remove ocean plastic and more. For out more on our <a routerLink="/business" href="/business" class="underline bold turq-text">Business page</a>.

    </div>
    <br /><br /><br />
    <div class="left-sub-title">3. Anti-Offsetting</div>
    <div class="text">We are strongly opposed to carbon offsetting as a practice, and none of our projects create, buy or sell carbon offsets.
        CO2 absorbed by supporting our projects is not related to an offsetting accreditation scheme.
    </div>
    <div class="text">Why is this?</div>
    <div class="text">Because 95% of carbon offsets don’t actually offset carbon emissions. For example, when you buy an offset which supports
        the funding of a renewable energy project, such as a wind farm, no <span class="italics">extra</span> carbon is absorbed. The money from the offset
        enables the building of the wind farm instead of another coal-fired power station. This is better than nothing, but
        because no extra carbon is absorbed from the atmosphere, <span class="italics">your carbon footprint is not offset</span>.
    </div>
    <div class="text">More than this, offsetting often provides an excuse for individuals and companies to continue their destructive emitting
        habits. That’s why companies like Shell and BP buy and sell offsets in huge numbers, in order to continue using fossil
        fuels.
    </div>
    <div class="text">Learn more about why we don’t use offsets <a class="link" target="_blank" href="https://policy.friendsoftheearth.uk/insight/dangerous-distraction-offsetting-con#:~:text=Carbon%20offsetting%20and%20nature%20offsetting,carbon%20emissions%20and%20restoring%20nature">here</a>.
    </div>
    <img class="big-image clear-image-text" src="../../../assets/anti-offsetting.png" />
    <br /><br /><br />
    <div class="left-sub-title">4. Effectiveness</div>
    <div class="text">We know that individuals and businesses have a lot of costs to manage. That’s why it’s very important to us that we make
        every £ spent on our projects count.
        <br/><br/>We thoroughly investigate every aspect of our projects to assess their real effectiveness, before we integrate them onto
        the Eden Greenspace platform. We measure the effectiveness of our projects in several ways.
        <br /><br />- We do our homework. Our projects are strategically chosen to address one particular aspect of the <a class="bold turq-text underline" target="_blank" href="https://unfccc.int/blog/what-is-the-triple-planetary-crisis">triple planetary crisis</a>, for example, address one source of carbon emissions, or one form of pollution.
        <br /><br />- We don’t just rely on pictures. Ryan and Sam personally visit the projects sites to inspect the work being done,
        whenever it is logistically viable to do so.
        <br /><br />- All project partners are the best in the business. Our projects partners often have decades of experience, run global
        operations and are some of the biggest in the world (e.g. Eden Reforestation Projects or Solar Aid).
        <br /><br />- Ongoing and Scalable. We do not support small, ‘ad hoc’ initiatives. All our projects run for years at a time, and
        have the capacity to scale up, as our community grows.
        <br /><br />- <span class="bold">Not a single cent can be spent on our platform without direct action being taken.</span> Unlike other organisations, we do
        not fund vague aims such as ‘raising awareness’ or ‘lobbying for the planet’. We only fund direct actions on the ground:
        10 trees planted, 5kg ocean plastic removed, 1 clean cookstove built.
    </div>
    <img class="big-image clear-image-text" src="../../../assets/effectiveness.png" />
    <br /><br /><br />
    <div class="left-sub-title">5. Must addresses multiple SDGs</div>
    <div class="text">We choose the best environmental restoration projects which integrate a holistic approach to the ecological crisis,
    targeting multiple SDGs through the same action. This complements the broader requirement to address one of the UNEP’s
    three planetary crises.<br/><br/>The UN’s 17 <a class="link" target="_blank" href="https://sdgs.un.org/goals">Sustainable Development Goals</a> (SDGs) are the blueprint to achieve a better
    and more sustainable future for the planet and humanity. The goals address the global challenges we face, including
    those related to poverty, inequality, climate change, environmental degradation, peace and justice. The 17 Goals are all
    interconnected, and in order to leave no one behind, it is important that we achieve them all by 2030.<br /><br />For example, our tree planting projects around the world employ local communities to plant forests on a massive scale
    and steward the trees to full maturity; creating jobs, protecting ecosystems, and helping mitigate climate change.
    </div>
    <div class="sdgs">
        <img class="sdg" src="../../../assets/sdg1.png"/>
        <img class="sdg" src="../../../assets/sdg11.png" />
        <img class="sdg" src="../../../assets/sdg13.png" />
        <img class="sdg" src="../../../assets/sdg15.png" />
    </div>
    <br /><br /><br />
    <div class="left-sub-title">6. Transparency and Credibility</div>
    <div class="text">A key hurdle for projects to overcome in our vetting process is providing detailed evidence of how the projects
    operates, what impact is made, and how they can prove that impact. We look at everything from financials to impact
    reports to pictures and videos of the sites.</div>
    <div class="text">Location is particularly important, as we use the geo-coordinates of the project sites, as well as pictures, videos and
    reports, to track what is happening on the ground for each project.</div>
    <div class="text">Crucially, the project sites must be available for inspection by the Eden Greenspace team, so that we can audit the
    working conditions of our partners and keep a direct evidence log of the impact of the projects.</div>
    <div class="text">Lastly, we like the projects to be audited by external regulators and accreditations, such as the International
    Standards Organisation (ISO), and 3rd party agencies such as Trustpilot, so that the project is examined from multiple
    angles thereby ensuring its credibility.</div>
    <div class="text">You can find out more about how we monitor each project, its location and impact, <a class="link" routerLink="/our-projects" target="_blank">here</a>.</div>
    <img class="iso-image" src="../../../assets/iso.jpg" />
    <div class="text">The ISO standard is a great indicator for high standards of operation. We look for this in our projects, as well as
    using it for our very own <a class="link" routerLink="/business" target="_blank">business services</a>.</div>
    <br /><br /><br />
    <div class="left-sub-title">7. Global presence</div>
    <div class="text">When expanding our project sites, we are aiming to incorporate projects from both in the UK (such as our marine plastic
    cleanup project and rewilding projects which are ongoing in Cornwall), and across the globe.</div>
    <div class="text">We aim to align with the environmental restoration projects which tackle the most vulnerable regions of the world that
    are in desperate need of support.</div>
    <div class="text">For example, we have targeted tree planting in three locations around the world (Madagascar, Nepal and Kenya) planting
    in regions of these countries which have experienced severe deforestation and subsequent ecosystem degradation.</div>
    <div class="text">This global approach is the best way to address the UNEP’s three <span class="italics">planetary crises</span>, because - as the name suggests -
    these problems are <span class="bold italics">global problems</span>. Our planet’s natural ecosystems are all interdependent, so there’s no point solving
    a problem in one place, if it is getting worse elsewhere.</div>
    <div class="text">As we continue to grow, we intend to expand our projects to address these crisis in all corners of the globe. You can
    see the locations of all our current projects across the world, <a class="link" routerLink="/our-projects" target="_blank">here</a>.</div>
    <img class="iso-image" src="../../../assets/global-presence.png" />
    <div class="text"></div>
    <br /><br /><br />
</div>
<!--
<div class="content beige">
    <app-title #ecoCoins text="Eco-Coins" large="true"></app-title>
    <div class="row">
        <div class="flex">
            <div class="project-container">
                <div class="projects">
                    <div *ngFor="let project of projectExamples"class="project">
                        <img class="info-button pointer">
                        <div class="counter info-offset">
                            <img class="coin" src="../../../assets/coin3.png">
                            <div class="count">
                                {{ project.id }}
                            </div>
                        </div>
                        <div class="not-counter">
                            <img class="project-image" [src]="project.image">
                            <div class="project-name" [innerHtml]="project.name"></div>
                        </div>
                        <div class="project-impact">{{ project.infoImpact }}</div>
                    </div>
                </div>
                <div class="projects">
                    <div>
                        &nbsp;
                    </div>
                    <div class="project">
                        <img class="info-button pointer">
                        <div class="counter info-offset">
                            <img class="coin" src="../../../assets/coin3.png">
                            <div class="count">
                                {{ project.id }}
                            </div>
                        </div>
                        <div class="not-counter">
                            <img class="project-image" [src]="project.image">
                            <div class="project-name" [innerHtml]="project.name"></div>
                        </div>
                        <div class="project-impact">{{ project.infoImpact }}</div>
                    </div>
                    <div id="view-our-projects" routerLink="/our-projects" class="button pointer clickable">
                        View Projects
                    </div>
                </div>
            </div>
            <div class="center">
                
            </div>
        </div>
        <div class="eco-coins">
            <div class="text">Our Eco-Coins allow you to track the level of impact which your money makes across all our different projects.</div>
            <div class="text">The impact of each Eco-Coin is the same, as each coin is worth £2, but achieves different results depending on <a class="link bold orange-no-highlight pointer" routerLink="/choose-your-plan" target="_blank">which projects you spend them on.</a></div>
            <div class="text now">{{ 'For example, 1 Eco-Coin will: \n' }}<div class="tab">{{ '• Plant 10 trees, or \n' }}</div> <div class="tab">{{ '• Remove 1kg of plastic from the ocean, and so on.' }}</div></div>
        </div>
    </div>
</div>
-->
<ng-container *ngIf="option === 'offsetting'">
    <div class="content">
        <app-title #offsets text="Why we don’t sell you carbon offsets" large="true"></app-title> 
        <div class="text">What are <span class="italics">carbon offsets</span>? Carbon offsetting is a system where you can pay someone else to reduce their carbon emissions to compensate for your own carbon emissions. The idea is that your emissions are ‘cancelled out’ by a reduction elsewhere.</div>
        <div class="text">Many so-called ‘environmental’ companies will tempt you into <span class="italics">buying</span> carbon offsets as a way to ‘remove your carbon footprint’ with the click of a button.</div>
        <div class="text">We don’t.</div>
        <div class="text">Why? For two reasons.</div>
        <div class="text">
            <img class="float-right" src="../../../assets/paltry.png"/>Firstly, almost all carbon offsets <span class="bold">don’t actually offset</span>. For example, when you buy an offset which supports the funding of a renewable energy project, such as a wind farm, no <span class="italics">extra</span> carbon is absorbed. The money from the offset enables the building of the wind farm instead of another coal-fired power station. This is better than nothing, but because no <span class="italics">extra</span> carbon is absorbed from the atmosphere, <span class="italics">your carbon footprint is not offset</span>.
        </div>
        <div class="text">95% of carbon offsets do not offset, and this is <a class="bold turq-text pointer" target="_blank" href="https://policy.friendsoftheearth.uk/insight/dangerous-distraction-offsetting-con#:~:text=Carbon%20offsetting%20and%20nature%20offsetting,carbon%20emissions%20and%20restoring%20nature">widely acknowledged</a>. An offset that doesn’t offset doesn’t work.</div>
        <div class="text"><img class="float-left" src="../../../assets/apple.png"/>Secondly, offsets send the wrong message, and <span class="bold">distract from the real problem</span>. They allow big companies like <a class="bold turq-text pointer" target="_blank" href="https://www.apple.com/uk/newsroom/2021/10/apple-charges-forward-to-2030-carbon-neutral-goal-adding-9-gigawatts-of-clean-power-and-doubling-supplier-commitments/#:~:text=While Apple is already carbon,a net-zero climate impact.&text=In total%2C Apple has reduced,over the past five years">Apple</a> and <a class="bold turq-text pointer" target="_blank" href="https://www.bp.com/en/global/corporate/news-and-insights/press-releases/bernard-looney-announces-new-ambition-for-bp.html">BP</a>, each with huge carbon footprints, to say that they are going ‘net zero’ or ‘carbon neutral’. </div>
        <div class="text">The reality is that every company, just like every one of us in fact, have a carbon footprint. The only way to reduce our carbon footprint, and move towards a more sustainable future, is by either:</div>
        <div class="text">1. <span class="bold">Cutting out activities which emit carbon</span> - not taking flights, choosing a diet with less meat, and so on.</div>
        <div class="text">And in the long-term</div>
        <div class="text">2. <span class="bold">Swapping these activities with carbon-<span class="italics">free</span> alternatives</span> - electric cars and renewable energy.</div>
        <div class="text">Carbon offsets don’t work, and provide an excuse not to change our ways - both as a society, and as individuals. Ultimately, restoring our planet to a healthy state is by taking <span class="italics">personal responsibility</span>.</div>
    </div>
    <div class="content beige">
        <br/><br/>
        <div class="sub-title">Where do we fit in?</div>
        <div class="text">Our goal at Eden Greenspace is not to tell you how to live your life, or pretend that we can
            ‘offset your impact’. To promise that would be a lie. </div>
        <div class="text">Instead, our projects go above and beyond individual lifestyles, they are about restoring our
            entire planet’s environmental problems, as a <span class="italics">collective</span>. You can read more about
            the collective impact our projects are having <span class="bold turq-text pointer"
                routerLink="/our-projects">here</span>.</div>
        <div class="buttons">
            <button id="choose-your-plan" routerLink="/business">
                Take Action Now
            </button>
            <button routerLink="/faqs">
                FAQs
            </button>
        </div>
        <br/><br /><br />
    </div>
</ng-container>
<div *ngIf="option === 'team'" class="content">
    <app-title text="Meet the Team" large="true"></app-title>
    <div class="team-row">
        <div class="images instas">
                <img class="insta" src="../../../assets/ryan.jpg"/>
    
                <div class="team-text">
                    Hi I’m Ryan , Co-founder at Eden Greenspace. The Covid lockdown in the U.K. was devastating to me like it was for us all, but I saw the good which lockdown was doing for the environment. I started Eden Greenspace to ensure the environmental healing which the lockdown has started will be continued; coming together as a collective to tackle the fight against climate change head on. I truly believe that we all care about our planet, but every person has a different way of expressing it. Being part of Eden Greenspace allows us to support the projects that we each feel will be most effective at fighting climate change and protecting the environment. We all need to work together and support the projects we care the most about, spreading our support in order to maximise the future impact that we will all have together. 🌲⛵️🌲
                </div>
        
            </div>
        <div class="images instas">
            <img class="insta" src="../../../assets/sam.jpg"/>

            <div class="team-text">
                    Hi I'm Sam, Co-Founder at Eden Greenspace. I was lucky enough to grow up in the largely untouched countryside of New Zealand, and one of things that struck me first when I came to the UK was how built-up and industrialised this part of the world is. My hope is that Eden Greenspace will provide a way for ordinary people to focus a little less on the modern world we have built, and a bit more on appreciating and protecting our planet. Planting trees and re-wilding our open spaces is a great way to reintegrate nature into our lives. But the best way to support nature is to support it together, as a collective. If we take action together, we can fix the environmental problems we’ve caused as a society, and made a planet that is sustainable for future generations. ⁣
            </div>

        </div>
        <div class="images instas">
            <img class="insta" src="../../../assets/sasha.jpeg"/>
            <div class="team-text">
                    My name is Sasha, and I am the Head of Media & Communications at Eden Greenspace. Having witnessed first-hand the impact
                    of the climate crisis, growing up in Mozambique, I want to be actively involved in the efforts to tackle the problem at
                    the root cause. Eden Greenspace offers the opportunity to be involved in this fight, not only to those who need to take
                    ownership of their contribution, but also to people who want to contribute to our planets longevity in their day-to-day
                    life. I hope to use my science background (Biology Bsc, Durham University) to advance awareness and action against the
                    climate crisis, and I have confidence in our collective success. The solutions offered by Eden Greenspace are evidence
                    of the bright future completely within reach when we all choose to help.
            </div>
        </div>
        <div class="images instas">
            <img class="insta" src="../../../assets/matt.jpg" />
            <div class="team-text">
                I'm Matt, CTO at Eden Greenspace. I believe that everyone has a responsibility to do what they can for the environment. That's why for the 
                past few years, I have been using my skills as a web developer to help create a platform that allows individuals and businesses to easily 
                fight the climate crisis as part of their daily lives or business models. I am very proud of the community Eden Greenspace has built and
                the amazing impact we have made so far, but it is still not enough. I will continue to help Eden Greenspace by adapting our website and adding
                new features that allow more people to make more of a difference as one united community. Let's work together and save our future!
            </div>
        </div>
    </div>
</div>
