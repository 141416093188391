import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {
  @Input() open = false;
  @Input() dropdown: string;
  @Input() text: string;
  @Input() contentMode = false;
  @Input() buttonMode = false;
  @Input() link: string;
  @Input() selected = false;
  @Input() smallText = false;

  constructor() { }

  ngOnInit(): void {
  }

}
