<div class="content">
    <h1 class="brown">Let's Restore Our Planet</h1>
    <h2>Environmental action beyond carbon offsetting: fund the world’s most effective carbon removal and biodiversity projects.</h2>
    <h3>A Not-for-Profit Organisation</h3>
    <button routerLink="business">Act Now</button>
    <div class="crises">
        <div class="crisis" routerLink="our-projects/tree-planting-in-nepal">
            <img class="crisis-logo"  src="../../../assets/trees-logo.png"/>
            <video class="crisis-video" [muted]="'muted'" playsinline muted autoplay loop>
                <source src="https://eden-blog-images.s3.eu-west-2.amazonaws.com/web-assets/home-video.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <div class="crisis-text">Climate</div>
            <div class="crisis-text">Projects</div>
        </div>
        <div class="crisis" routerLink="our-projects/rewilding-projects-uk">
            <img class="crisis-logo" src="../../../assets/bees-logo.png" />
            <video class="crisis-video" [muted]="'muted'" playsinline muted autoplay loop>
                <source src="https://eden-blog-images.s3.eu-west-2.amazonaws.com/web-assets/home-page-vid-rewild.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <div class="crisis-text">Nature</div>
            <div class="crisis-text">Projects</div>
        </div>
        <div class="crisis" routerLink="our-projects/marine-plastic-removal-around-the-uk">
            <img class="crisis-logo" src="../../../assets/plastics-logo.png" />
            <video class="crisis-video" [muted]="'muted'" playsinline muted autoplay loop>
                <source src="https://eden-blog-images.s3.eu-west-2.amazonaws.com/web-assets/home-page-vid-plastics.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <div class="crisis-text">Pollution</div>
            <div class="crisis-text">Projects</div>
        </div>
    </div>
    <br /><br /><br />
</div>
<div class="content grey">
    <div class="mob partner-text">World-Leading Charity Partners:</div>
    <div class="partner-container">
        <div class="desk partner-text">World-Leading Charity Partners:</div>
        <app-carousel class="mob" [contentMode]="true" [itemsPerRow]="2">
            <img *ngFor="let logo of projectLogos" class="gallery-image" [src]="'../../../assets/' + logo"/>
        </app-carousel>
        <app-carousel class="desk car" [images]="projectLogos" [desktop]="true"></app-carousel>
    </div>
</div>
<app-partners color="beige"></app-partners>
<div class="content blue-grey">
    <h1>Our Solutions and Their Impact</h1>
    <app-total-impacts></app-total-impacts>
    <br /><br />
    <button routerLink="about/impact">Learn More</button>
    <br /><br />
</div>
<app-problem></app-problem>
<app-reviews-transparency></app-reviews-transparency>
<!-- The Modal -->
<div *ngIf="newsletterPopup" id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
        <span class="close pointer" (click)="newsletterPopup = false">&times;</span>
        <app-newsletter popup="true"></app-newsletter>
    </div>

</div>