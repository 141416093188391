<div class="content-container">
    <div *ngIf="cart.length" class="content">
        <div class="title">Cart</div>
        <div class="cart">
            <div *ngFor="let cartItem of cart" class="cart-item">
                <img class="cart-image" [src]="getGroupImage(cartItem.groupId, cartItem.location)"/>
                <div class="cart-text">
                    <div class="cart-impact">£{{ cartItem.cost }}</div>
                    <div class="cart-impact orange">{{ cartItem.name }}</div>
                    <div *ngIf="cartItem.location" class="cart-impact">Location: {{ cartItem.location }}</div>
                    <div class="cart-impact">Quantity: {{ cartItem.quantity }}</div>
                </div>
                <img class="cross" src="../../../assets/cross.png" (click)="removeFromCart(cartItem)"/>
            </div>
            <div class="cart-Total">Total: £{{ getTotalCost() }}</div>
        </div>
        <div class="sub-title">
            Your plan also comes with:
        </div>
        <div class="white">
            <div class="point">
                • Receive a personalised certificate
            </div>
            <div class="point">
                • Details of your tree species and planting location
            </div>
            <div class="point">
                • QR code to your own online account, where you can view the trees you've planted, and plant more!
            </div>
        </div>
        <div class="small-title">Add a personalised message:</div>
        <textarea class="personal-message" placeholder="Add message here" [(ngModel)]="personalMessage"></textarea>
        <div class="small-title">Who is the gift for?</div>
        <textarea class="recipient" placeholder="Add name of recipient here" [(ngModel)]="recipient"></textarea>
        <div class="checkbox-container">
            <input class="checkbox" type="checkbox" [(ngModel)]="newsletter">
            <div class="point">Sign up to our monthly newsletter for news on all things sustainability?</div>
        </div>
        <div class="user">{{ userName }}</div>
        <div class="info" *ngIf="message">
            {{ message }}
        </div>
        <ng-container *ngIf="loading">
            <div class="info">
                Processing request...
            </div>
        </ng-container>
        <ng-container *ngIf="loggedIn && !message && !loading">
            <div class="info">
                We will automatically use your previous payment method.
            </div>
            <div class="button pointer clickable" (click)="confirm()">
                Pay Now
            </div>
        </ng-container>
    </div>
    <app-login
        *ngIf="(!loggedIn || retry) && cart.length"
        [loginOnly]="false"
        [switchOnInit]="true"
        (switchEvent)="switch($event)"
        (loginEvent)="login($event)"
        [cardOnly]="retry"
        cart="true"
    ></app-login>
    <div *ngIf="!cart.length" class="content">
        <div class="title">Cart</div>
        <div class="empty-text">There are no items in your cart.</div>
        <div class="empty-text">Go to our gifting page to add items.</div>
        <div class="button pointer clickable" routerLink="/eco-gifts">Gifting</div>
    </div>
    <img *ngIf="cart.length" class="bees" src="../../../assets/bees2.PNG"/>
    <img *ngIf="cart.length" class="turtles" src="../../../assets/turtles.png"/>
</div>