import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-total-impacts',
  templateUrl: './total-impacts.component.html',
  styleUrls: ['./total-impacts.component.css']
})
export class TotalImpactsComponent implements OnInit {
  private authService: AuthService;

  public trees = 200 * 0.15;
  public land = 200;
  public plastic = 0;
  public co2 = 50 * 0.22;

  constructor(authService: AuthService) {
    this.authService = authService;
  }

  ngOnInit(): void {
    this.authService.getTotalImpact().subscribe(
      (totalImpactData) => {
        for (const impact of totalImpactData) {
          if (impact._id < 4) {
            this.trees = this.trees + impact.value;
            this.land = this.land + impact.value;
            this.co2 = this.co2 + 0.150 * impact.value;
          }

          if (impact._id === 4) {
            this.land = this.land + impact.value;
          }

          if (impact._id === 5) {
            this.plastic = this.plastic + impact.value;
          }

          if (impact._id === 6) {
            this.co2 = this.co2 + 0.22 * impact.value;
          }

          if (impact._id === 7) {
            this.co2 = this.co2 + 14.5 * impact.value;
          }
        }

        this.co2 = Math.floor(this.co2);
      },
    );
  }
}
