import { EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { FooterService } from 'src/app/services/footer.service';

@Injectable()
export abstract class NextSectionComponent implements OnInit {
  @Output() nextSection: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  public showNext(fadeText?: string): void {
    if(fadeText) {
      FooterService.fadeText = fadeText;
    }
    this.nextSection.emit();
  }

}
