<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Sue+Ellen+Francisco" />
<div [class]="blur ? 'content blur pad' : 'content pad'">
    <app-title #title text="My Tailored Plan" medium="true"></app-title>
    <div class="sub-content">
        <div class="content">
            <div class="plan-summary" *ngIf="getSelectedPlan()">
                <div class="summary-text">You have chosen the <span class="bold">{{ getSelectedPlan().name }}</span> plan.</div>
                <div class="summary-text">This gives you <span class="bold">{{ getSelectedPlan().tokens }} Eco-Coins </span>per month!</div>
            </div>
            <!--
            <div class="headers">
                <div class="header">Choices</div>
                <div class="header">Impact</div>
            </div>
            <div class="data">
                <div class="rows">
                    <ng-container *ngFor="let project of getSelectedProjects()">
                        <div class="row">
                            <div class="first">
                                <div class="count">{{project.tokens}}</div>
                                <img class="token-logo" src="../../../assets/coinNEW1.png">
                                <div class="text">coin /month on</div>
                                <div class="project">
                                    <img class="image" [src]="project.image">
                                    <div class="project-name">{{ project.name }}</div>
                                </div>
                                <div class="equals">=</div>
                            </div>
                            <div class="second">{{ createImpactText(project) }}</div>
                        </div>
                    </ng-container>
                </div>
            </div>
            -->
            <div class="scroll">
                <div *ngFor="let project of getSelectedProjects()" class="impact">
                    <div class="project">
                        <div class="not-counter">
                            <img class="image" [src]="project.image">
                            <div class="project-name" [innerHtml]="project.name"></div>
                        </div>
                    </div>
                    <div class="new-info">
                        <div class="coins">Number of Eco-Coins: {{ project.tokens }}</div>
                        <div>{{ createImpactText(project)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="!existing" class="sub-title">
                Your plan also comes with:
            </div>
            <div class="white" *ngIf="!existing">
                <div class="point">
                    • A range of world-leading climate and ecological projects to choose from.
                </div>
                <div class="point">
                    • Regular updates, including evidence of your impact.
                </div>
                <div class="point">
                    • A transparent way to <span class="orange">track </span>your impact using <span class="nowrap">Eco-Coins <i 
                        class="fa fa-question-circle token" 
                        style="font-size: min(calc(15px + 2vw), 25px)"
                        (click)="$event.stopPropagation(); tooltipOpen = !tooltipOpen"
                        (focusout)="tooltipOpen = false"
                        tabindex="0"
                    >
                        <span class="tooltiptext" [attr.data-open]="tooltipOpen" (mousedown)="$event.preventDefault()">
                            Each Eco-Coin has a different impact
                            depending on the projects you pick.
                        </span>
                    </i>
                    </span>
                </div>
                <div class="point">
                    • A subscription to our monthly newsletter.
                </div>
                <div class="point">
                    • A community of people devoted to climate action.
                </div>
            </div>
        </div>
    </div>
    <div class="user">{{ userName }}</div>
    <ng-container *ngIf="loggedIn && !message && !loading">
        <div class="info">
            If you have chosen a different plan, your monthly cost will increase/decrease!
        </div>
        <div class="button pointer clickable" (click)="confirm()">
            Update My Plan
        </div>
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="info">
            Processing request...
        </div>
    </ng-container>
    <div class="info" *ngIf="message">
        {{ message }}
    </div>
</div>
<app-login 
    [loginOnly]="false"
    [switchOnInit]="true"
    (switchEvent)="switch($event)"
    *ngIf="(!loggedIn || retry) && !blur"
    (loginEvent)="login($event)"
    [cardOnly]="retry"
></app-login>

