import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  public static footer = true;
  public static fadeText?: string;

  constructor() { }
}
