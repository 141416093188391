import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-percentage',
  templateUrl: './percentage.component.html',
  styleUrls: ['./percentage.component.scss']
})
export class PercentageComponent implements OnInit {
  @Input() percentage: number;
  @Input() color = 'red';

  ngOnInit() {
    console.log(this.color);
  }
}
