<div *ngIf="contents.folders" class="content">
    <app-dropdown 
        *ngFor="let folder of contents.folders" 
        contentMode="true" 
        [dropdown]="folder.name" 
        smallText="true"
        [open]="folder.open || false"
    >
        <app-folder [contents]="folder" [name]="name"></app-folder>
    </app-dropdown>
</div>
<div *ngFor="let section of contents.sections" class="content">
    <div *ngIf="section.name" class="subsubtitle">{{ section.name }}</div>
    <div class="images">
        <a *ngFor="let image of section.images" class="image-container">
            <div *ngIf="image.label" class="label">{{ image.label }}</div>
            <img class="image" defaultImage="../../../assets/loading.gif" [lazyLoad]="image.location || image" />
            <a class="link" [href]="image.location || image" target="_blank" (click)="addDownloadRecord(image.location || image)">Download</a>
        </a>
        <div *ngFor="let pdf of section.pdfs" class="image-container">
            <div *ngIf="pdf.label" class="label">{{ pdf.label }}</div>
            <iframe class="pdf" [src]="(pdf.location || pdf) | safeHtml : 'url'"></iframe>
            <a class="link" [href]="pdf.location || pdf" target="_blank" (click)="addDownloadRecord(pdf.location || pdf)">Download</a>
        </div>
        <a *ngFor="let video of section.videos" [href]="video" target="_blank" class="image-container">
            <div *ngIf="video.label" class="label">{{ video.label }}</div>
            <video class="video" controls>
                <source [src]="(video.location || video) + '#t=0.1' | safeHtml : 'url'" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <a class="link" [href]="video.location || video" target="_blank" (click)="addDownloadRecord(video.location || video)">Download</a>
        </a>
        <ng-container *ngIf="name != 'jane.king@cavendishnuclear.com' && name != 'cavnuctest.@test.com'">
            <div *ngFor="let content of section.lockedContent" class="image-container">
                <img class="image locked" defaultImage="../../../assets/loading.gif" [lazyLoad]="content.image" />
                <div class="locked-text">{{ content.text }}</div>
            </div>
        </ng-container>
    </div>
</div>
