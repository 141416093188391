import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { getBusinessName } from 'server/accounts';
import { WINKWORTH_REMINDER_LAUCH_DATE } from 'server/constatnts';
import { projects } from 'server/projects';
import { BusinessSubsription, CustomBusinessPurchase, CustomPurchaseDetails, EstateAgentPurchase, FREE_PURCHASES, LETTINGS_PURCHASE_DETAILS, PURCHASE_DETAILS } from 'server/purchases';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { ToggleOptions } from '../toggle/toggle.component';
declare var Stripe: any;

export function getImpactText(project: number): string {
  return [
    {
      id: 1,
      text: 'Trees planted in Nepal',
    },
    {
      id: 2,
      text: 'Trees planted in Madagascar',
    },
    {
      id: 3,
      text: 'Trees planted in Kenya',
    },
    {
      id: 4,
      text: 'm\u00b2 of rewilded land',
    },
    {
      id: 5,
      text: 'kgs of plastic removed from the sea',
    },
    {
      id: 6,
      text: 'People provided with solar lights',
    },
    {
      id: 7,
      text: 'Clean cookstoves provided',
    },
    {
      id: 8,
      text: 'm\u00b2 of coral reef saved',
    },
    {
      id: 9,
      text: 'm\u00b2 of coral reef saved',
    },
    {
      id: 11,
      text: 'm\u00b2 of peatland restored',
    },
  ].find(impact => impact.id === project).text;
}

@Component({
  selector: 'app-impact-creation',
  templateUrl: './impact-creation.component.html',
  styleUrls: ['./impact-creation.component.css']
})
export class ImpactCreationComponent implements OnInit {
  private stripe;

  public purchase: EstateAgentPurchase = {
    cost: 0,
    date: undefined,
    email: '',
    buyer: '',
    address: '',
    houseCost: 0,
    projectId: 3,
    quantity: 1,
    value: 0,
    image: null,
    reference: '',
    estateAgentPurchase: false,
  };

  public businessSubscription: BusinessSubsription = {
    ammount: 0,
    purchases: projects.map(project => { 
        return {
          cost: 0,
          date: new Date(),
          email: '',
          quantity: 1,
          reference: "Quarterly Contribution",
          projectId: project.id,
          value: 0,
        }
      }
    )
  }

  public purchaseDetails: string;

  public projects = projects;

  public message?: string;
  public loading = false;
  public checkout = false;
  public isEstateAgent = false;
  public isBusiness = false;
  public isSubscription = false;
  public account: string;
  public error = '';
  public voucher = false;
  public lettingsOptions: CustomPurchaseDetails[];

  public toggleOptions: ToggleOptions[] = [
    {
      name: 'sales',
      displayName: 'Sales',
    },
    {
      name: 'lettings',
      displayName: 'Lettings',
    },
  ];

  public toggle = 'sales';
  public getBusinessName = getBusinessName;
  public useExistingPaymentMethod = false;
  public card: string;
  
  public lastOrder: number;
  public reminder = false;
  public expectedDate: Date;
  public addedExpected = false;
  public quaterlyContributionAmount: number | undefined;

  private filteredLettings: CustomPurchaseDetails[];

  private authService: AuthService;
  private router: Router;
  private route: ActivatedRoute;

  constructor(authService: AuthService, router: Router, route: ActivatedRoute) {
    this.authService = authService;
    this.router = router;
    this.route = route;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.stripe = environment.production
        ? Stripe('pk_live_51IX4vmBDboGHB3gmCr8cmjFzg0aO9LxJHErrbE8FAtOh806Mb3PWjkPFR3A6g4z4ySI1mlfoToieenXke3iuFP1A00zZvlxg13')
        : Stripe('pk_test_51IX4vmBDboGHB3gmxC86oDlimVDH8gS6qAZ0QFAT6BtWq2aYrO4VCosc457AOLotPFFMfM0hK7bOTlgLMxzwQgYT00rY5mAx2D');
    }, 200);

    combineLatest ([
      this.authService.isEstateAgent(),
      this.authService.isBusiness(),
      this.authService.getAccountName(),
      this.authService.getDefaultCard(),
      this.authService.isWinkworth(),
      this.route.params,
      this.authService.getQuaterlyContributionAmmount()
    ]).subscribe(([isEstateAgent, isBusiness, res, card, winkworth, params, qca]) => {
      this.isEstateAgent = isEstateAgent && !params['type'];
      this.isSubscription = params['type'] === 'subscription';
      this.isBusiness = isBusiness;
      this.account = res.user.name;
      this.card = card;
      this.quaterlyContributionAmount = qca;

      if(this.card) {
        this.useExistingPaymentMethod = true;
      }

      if (!this.isEstateAgent) {
        this.setImpactValue(this.purchase.cost.toString());
      } else {
        this.purchase.estateAgentPurchase = true;

        this.projects = this.projects.filter(project => project.id != 11);
      }

      if (this.account !== 'nottinghill@winkworth.co.uk') {
        this.filteredLettings = LETTINGS_PURCHASE_DETAILS.filter((details) => !details.nottingHill);
      } else {
        this.filteredLettings = LETTINGS_PURCHASE_DETAILS;
      }

      this.lettingsOptions = this.filteredLettings.filter((details) => details.projectId === 3);

      if(winkworth) {
        this.authService.getPlan().subscribe((res) => {
          if(res[0].purchases) {
            this.lastOrder = Math.floor((new Date().getTime() 
              - new Date(res[0].purchases
                .sort((a, b) => {
                  return -a.date.toString().localeCompare(b.date.toString());
                })[0].date
              ).getTime()) / (1000 * 3600 * 24));
          } else {
            this.lastOrder = Math.floor((new Date().getTime() 
              - Math.max(
                new Date(parseInt(res[0]._id.toString().substring(0, 8), 16) * 1000).getTime(), 
                new Date(WINKWORTH_REMINDER_LAUCH_DATE).getTime(),
              )) / (1000 * 3600 * 24));
          }
        });
      }
      
    });
    //this.authService.hasVoucher().subscribe((hasVoucher) => { this.voucher = !hasVoucher });
  }

  public projectChange(event): void {
    this.purchase.projectId = parseInt(event.target.value);
    this.lettingsOptions = this.filteredLettings.filter((details) => details.projectId === this.purchase.projectId);
    if(this.toggle === 'lettings') {
      this.selectLettingsPurchase(this.lettingsOptions[0]);
    }

    if(!this.isEstateAgent) {
      this.setImpactValue((this.purchase.cost || 0).toString());
    }
  }

  public selectPurchase(): void {
    if(!this.purchase.date) {
      this.error = 'Please select the date of sale';
      return;
    }

    if (this.isEstateAgent && this.toggle === 'sale' && !this.purchase.houseCost) {
      this.error = 'Please enter the sale price';
      return;
    }

    if (this.isEstateAgent && !this.purchase.buyer) {
      this.error = 'Please enter the name of the buyer';
      return;
    }

    if (this.isEstateAgent && !this.purchase.address) {
      this.error = 'Please enter the house address';
      return;
    }

    if (this.isEstateAgent && !this.purchase.image) {
      this.error = 'Please upload an image';
      return;
    }

    if (this.isEstateAgent && this.toggle === 'sales') {
      let details: CustomPurchaseDetails;

      if (this.voucher) {
        details = FREE_PURCHASES.find((details) =>
          this.purchase.houseCost >= details.min
          && this.purchase.projectId === details.projectId
        );
      } else {
        details = PURCHASE_DETAILS.find((details) =>
          this.purchase.houseCost >= details.min
          && (!details.max || this.purchase.houseCost <= details.max)
          && this.purchase.projectId === details.projectId
        );
      }

      if (!details) {
        this.error = 'House price is too small';
        return;
      }

      if (details) {
        this.purchase.cost = details.cost;
        this.purchase.value = details.value;
        this.purchaseDetails = details.text;
      }
    } 

    if(!this.isEstateAgent && !this.purchase.value) {
      this.error = 'Contribution is too small for the project';
      return;
    }

    this.checkout = true;
  }

  public setHouseCost(value: string): void {
    if(value === '£' || value === '') {
      this.purchase.houseCost = undefined;
    } else {
      this.purchase.houseCost = value ? parseInt(value.replace(/\D/g, '')) || 0 : 0;
    }
  }

  public setImpactValue(value: string): void {
    if (value === '£' || value === '') {
      this.purchase.cost = undefined;
      this.purchase.value = 0;
      this.purchaseDetails = this.purchase.value.toString() + ' ' + getImpactText(this.purchase.projectId);
    } else {
      this.purchase.cost = value ? parseInt(value.replace(/\D/g, '')) || 0 : 0;

      let multiplier = 1;
      switch (this.purchase.projectId) {
        case 1:
        case 2:
        case 3:
          multiplier = 1/0.9;
          break;
        case 4:
          multiplier = 10/3.5;
          break;
        case 5:
          multiplier = 0.5;
          break;
        case 6:
          multiplier = 0.2;
          break;
        case 7:
          multiplier = 1/12;
          break;
        case 8:
        case 9:
          multiplier = 10/3;
          break;
        case 11:
          multiplier = 2/3
      }
      console.log(multiplier);
      this.purchase.value = Math.floor(this.purchase.cost * multiplier);

      this.purchaseDetails = this.purchase.value.toString() + ' ' + getImpactText(this.purchase.projectId);

      if (this.purchase.projectId === 5) {
        this.purchase.value = this.purchase.value * 100;
      }
    }
  }

  public setSubscriptionPrice(value: string, projectId: number): void {
    const purchase = this.businessSubscription.purchases.find(p => p.projectId === projectId);

    if (value === '£' || value === '') {
      purchase.cost = 0;
      purchase.value = 0;
    } else {
      let multiplier = 1;
      switch (purchase.projectId) {
        case 1:
        case 2:
        case 3:
          multiplier = 1 / 0.9;
          break;
        case 4:
          multiplier = 10 / 3.5;
          break;
        case 5:
          multiplier = 50;
          break;
        case 6:
          multiplier = 0.2;
          break;
        case 7:
          multiplier = 1 / 12;
          break;
        case 8:
        case 9:
          multiplier = 10 / 3;
        case 11:
          multiplier = 2 / 3
      }
      purchase.cost = value ? parseInt(value.replace(/\D/g, '')) || 0 : 0;

      purchase.value = Math.floor(purchase.cost * multiplier);
    }
  }

  public getProjectPurchaseCost(projectId: number): number {
    return this.businessSubscription.purchases.find(p => p.projectId === projectId).cost;
  }

  public pay(event?: string) {
    this.message = '';
    this.loading = true;

    if(!this.isEstateAgent) {
      this.purchase.address = this.projects.find((project) => project.id === this.purchase.projectId).name.replace(' \n', '');
    }

    const payFunction: (paymentMethod: any, purchase: EstateAgentPurchase) => Observable<any> = (this.isEstateAgent ? this.authService.createImpactPay : this.authService.genericImpactPay).bind(this.authService);

    payFunction(
      event,
      {...this.purchase},
    ).subscribe((result) => {
      console.log(result);
      if (result.status === 'paid') {
        this.message = 'Payment successful!'
        this.loading = false;
        this.redirect();
      } else if (result.payment_intent.status === 'requires_payment_method') {
        this.message = 'There was an error with your card. Please try again';
        this.loading = false;
      } else if (result.payment_intent.status === 'requires_action') {
        this.message = 'Awaiting confirmation';
        this.stripe.confirmCardPayment(result.payment_intent.client_secret, {
          payment_method: event,
        }).then((res) => {
          if (res.error) {
            this.message = 'There was an error with your card. Please try again';
            this.loading = false;
          } else {
            if (res.paymentIntent.status === 'succeeded') {
              this.message = 'Payment successful!'
              this.loading = false;
              this.redirect();
            }
          }
        })
      } else {
        this.message = 'There was an error with your card. Please try again';
        this.loading = false;
      }
    }, () => {
      this.message = 'Unexpected Error. Please try again later';
      this.loading = false;
    });
  }

  public createSubscription(event?: string) {
    this.message = '';
    this.loading = true;

    this.authService.createBusinessSubscription(
      event,
      { ...this.businessSubscription },
    ).subscribe((result) => {
      console.log(result);
      if (result.status === 'active') {
        this.message = 'Payment successful!'
        this.loading = false;
        this.redirect();
      } else if (result.latest_invoice.payment_intent.status === 'requires_payment_method') {
        this.message = 'There was an error with your card. Please try again';
        this.loading = false;
      } else if (result.latest_invoice.payment_intent.status === 'requires_action') {
        this.message = 'Awaiting confirmation';
        this.stripe.confirmCardPayment(result.latest_invoice.payment_intent.client_secret, {
          payment_method: event,
        }).then((res) => {
          console.log(res);
          if (res.error) {
            this.message = 'There was an error with your card. Please try again';
            this.loading = false;
          } else {
            if (res.paymentIntent.status === 'succeeded') {
              this.message = 'Payment successful!'
              this.loading = false;
              this.redirect();
            }
          }
        })
      } else {
        this.message = 'There was an error with your card. Please try again';
        this.loading = false;
      }
    }, () => {
      this.message = 'Unexpected Error. Please try again later';
      this.loading = false;
    });
  }

  public payFree() {
    this.message = '';
    this.loading = true;
    this.authService.createImpactFree(
      { ...this.purchase },
    ).subscribe((result) => {
      console.log(result);
      this.redirect();
    });
  }

  public imageChange(event): void {
    this.purchase.image = event.target.files[0];
  }

  private redirect(): void {
    this.router.navigate(['/account-page']);
  }

  public toggleSwitch(option: string): void {
    this.toggle = option;

    if(option === 'lettings') {
      this.purchase.cost = this.lettingsOptions[0].cost;
      this.purchase.value = this.lettingsOptions[0].value;
      this.purchaseDetails = this.lettingsOptions[0].text;
      this.purchase.letting = true;
    } else {
      this.purchase.letting = false;
    }
  }

  public selectLettingsPurchase(details: CustomPurchaseDetails): void {
    this.purchase.cost = details.cost;
    this.purchase.value = details.value;
    this.purchaseDetails = details.text;
  }

  public getProjectName(projectId: number): string {
    return projects.find((project) => project.id === projectId).name;
  }

  public addExpectedOrder(): void {
    this.authService.addExpectedOrder(this.expectedDate).subscribe(() => {
      this.addedExpected = true;
    });
  }

  public getSubscriptionImpactText(projectId: number): string {
    const purchase = this.businessSubscription.purchases.find(p => p.projectId === projectId);

    let value = purchase.value;

    if(projectId === 5) {
      value = Math.floor(value/100);
    }

    return value.toString() + ' ' + getImpactText(purchase.projectId);
  }

  public getQuaterlyAmmount(): string {
    let total = 0;

    for(const purchase of this.businessSubscription.purchases) {
      total = total + purchase.cost;
    }

    this.businessSubscription.ammount = Math.round(((total/4) * 100))/100;
    return this.businessSubscription.ammount.toFixed(2);
  }

  public getNonEmptyPurchases(): CustomBusinessPurchase[] {
    return this.businessSubscription.purchases.filter(purchase => purchase.cost > 0);
  }

}
