<div *ngIf="!link" class="dropdown-wrapper">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
</div>
<a *ngIf="link" [href]="link" class="dropdown-wrapper" [attr.data-button-mode]="buttonMode">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
</a>
<ng-template #content>
    <div *ngIf="!buttonMode" class="dropdown pointer clickable" (click)="open = !open && !buttonMode" [attr.data-selected]="selected"
        [attr.data-smallText]="smallText">
        <div *ngIf="!buttonMode" class="arrow">{{ open ? '▽' : '▷' }}</div>
        <div [innerHtml]="dropdown"></div>
    </div>
    <div *ngIf="buttonMode" class="button-mode dropdown pointer clickable" [attr.data-selected]="selected" [attr.data-smallText]="smallText">
        <div [innerHtml]="dropdown"></div>
    </div>
    <div *ngIf="open && !contentMode" class="text-wrapper">
        <div class="text" [innerHtml]="text"></div>
    </div>
    <ng-content *ngIf="open && contentMode"></ng-content>
</ng-template>