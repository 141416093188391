<div class="content">
    <ng-container *ngIf="!account">
        <app-title text="Our Projects"></app-title>
        <br /><br />
        <iframe  class="maps" src="https://www.google.com/maps/d/u/0/embed?mid=1selxENxJDJjaoqkK8NHG6eW816eg5RE&ehbc=2E312F"></iframe>
        <br/><br/><br />
    </ng-container>
    <!--
    <a 
        *ngFor="let bio of bios" 
        class="projects"
        [routerLink]="[bio.id]"
    >
        {{ bio.name }}
    </a>
    -->
    <div class="projects scroll">
        <div id="projects-project" *ngFor="let project of bios" [class]="project.projectId ? 'project clickable pointer' : 'empty'" [routerLink]="project.projectId ? (account ? ['/our-projects', project.url] : [project.url]) : undefined">
            <div class="project-name" [innerHtml]="project.name"></div>
            <div class="images">
                <img *ngIf="project.thumbnail && !project.coverOnly" class="image" [src]="'../../../assets/' + project.thumbnail">
                <img *ngIf="project.coverOnly" class="image" [src]="'../../../assets/' + project.coverOnly">
                <img class="logo" [src]="getLogo(project.projectId)">
            </div>
            <div *ngIf="project.tokens" class="project-name cost">{{ project.tokens }}<img class="token-logo" src="../../../assets/coinNEW1.png">Coin{{project.tokens === 1 ? '' : 's'}} /month committed</div>
        </div>
    </div>
    <br /><br />
    <button routerLink="/articles/anti-greenwashing-methodology">Anti-Greenwashing Methodology</button>
    <br /><br />
    <app-title text="General FAQs"></app-title>
    <div class="sub">Project specific FAQs are on each project's page.</div>
    <div class="dropdowns">
        <app-dropdown class="dropdown" dropdown="How can you prove my impact?" [contentMode]="true" [smallText]="true">
        <pre class="text">How do you know that what we <span class="italic">say</span> we’re doing is what we’re <span class="italic">really</span> doing? This is a question we get a lot. Luckily, we take extra care to make sure everything we do is above board.

<span class="bold">1. Projects Research and Selection</span>

It starts with choosing the projects. We research each project for 5-6 months, including making personal visits to the sites ourselves. All our projects must conform with our <span class="link" routerLink="/about/projects">7 selection criteria</span> before we incorporate them onto our platform. All our projects:

• Address at least one of the <span class="link" routerLink="/about/overview" target="_blank">Three Planetary Crises</span> set out by the UN Environment Programme.

• Address several of the UN’s <a class="link" href="https://sdgs.un.org/goals" target="_blank">Sustainable Development Goals</a>.

• Are the best in their area - we work only with the largest and most reputable charities and NGOs, such as <a class="link" href="https://www.edenprojects.org/" target="_blank">Eden Reforestation Projects</a> and <a class="link" href="https://solar-aid.org/" target="_blank">Solar Aid</a>.

• Are visited personally by Founders Ryan and Sam at at least one of their sites, to assess their operations directly.

• Are <span class="bold">quantifiable</span> - not a single penny can be spent in support of any of our projects, without it being quantifiable in terms of number of trees planted, grams of plastic removed from the ocean, or metres squared rewilded, and so on.

• Are thoroughly researched - our Research Team investigate each partner’s financial structure, their administrative structure, the transparency of their practices, whether there are hypocritical practices (such as excessive flying), and their cost-effectiveness.

<span class="bold">2. Visuals and Maps</span>

We’ve gone the extra mile to make it easy to see what are projects do on the ground, and track each individual impact
that <span class="italics bold">you</span> - the Greenspace Community - are having.

• Our comprehensive <span class="link" routerLink="/our-projects" target="_blank">project bios and project-specific FAQs</span> detail the operations of each project, including pictures and videos from each project.

• Each project bio also includes specially made GIS maps of the precise sites where the amazing tree-planting and rewilding is taking place.

• Lastly, you can see pictures, videos and reels by Ryan and Sam taken on their regular site visits, on the Eden Greenspace <a class="link" href=" https://www.instagram.com/edengreenspace/?hl=en" target="_blank">Instagram</a>.

<span class="bold">3. Impact Records</span>

Lastly, we record and document every action our community takes.

To be as transparent as possible, we publish all the impact records - including certificates, dates and locations for all the impact our community has made, to date.

Anyone can view these records at any time, <a class="link" href="https://docs.google.com/spreadsheets/d/1A7nLmHQWvBZgTqCxm1EUVCQlY1agC3DLpNkOFhR-hT4/edit#gid=1599391297" traget="_blank">here</a>. 

<div class="buttons">
    <button routerLink="/articles/anti-greenwashing-methodology">Anti-Greenwashing Methodology</button>
</div>
</pre>
    </app-dropdown>
    <app-dropdown class="dropdown" dropdown="Why did you start Eden Greenspace?" [contentMode]="true"
        [smallText]="true">
        <pre class="text">In late 2020 co-founders Ryan and Sam were in the midst of the Covid 19 pandemic. They saw the effects of covid
lockdowns cause our natural environments to regrow, and a global reduction in CO2 emissions.

But they were frustrated to see this glimmer of hope disappear as the pandemic subsided and normal life resumed. Once again, the planet was being forgotten.

That’s why Ryan and Sam created Eden Greenspace - a platform designed to restore the damage humans are doing to our planet, and bring nature back into our lives for good.
    
Right from the start, they wanted to avoid the greenwashing messages that ‘planting a tree’ was the one-size-fits-all solution which would save the planet.
    
Instead of just planting trees, they spent over a year researching the best environmental projects across the world, everything from rainforest protection to rewilding to ocean clean up projects. They then decided that a great way to choose projects would be to start with projects that address one of the UN Environment Programme’s <span class="link" routerLink="/about/overview">three planetary crises</span>.

By bringing all these projects together, the goal was to make real environmental action easy and accessible for ordinary people, who might never usually support environmental causes.
    
From there, it just kept growing, and they’re still working hard to spread awareness of these amazing projects, and bringing individuals and businesses together to support them.
</pre>
        <img class="big-image clear-image-text" src="../../../assets/ryan-sam.jpg" />
    </app-dropdown>
    <app-dropdown class="dropdown" dropdown="Why don’t you just plant trees?" [contentMode]="true" [smallText]="true">
        <pre class="text">
Tree-planting, when done right, is a fantastic way of restoring the planet. It is a form of rewilding, it stabilises the soil and brings back wildlife. It’s also great for the climate, because trees sequester CO2.

But <span class="bold">there’s no ‘one size fits all’ solution to restoring our planet</span>. The only long-term, sustainable way to restore our planet is by tackling the problem from multiple angles.

That’s why at Eden Greenspace, we do things differently. We make it possible to support a <span class="bold italic">range</span> of environmental projects which tackle all <a class="link" href="https://unfccc.int/blog/what-is-the-triple-planetary-crisis" target="_blank">three planetary crises which the UNEP have identified</a>.
</pre>
        <img class="big-image clear-image-text" src="../../../assets/methodology-crisis.png" />
        <pre class="text">
You can learn more about our projects <span class="link" routerLink="/our-projects">here</span>.
</pre>
    </app-dropdown>
    <app-dropdown class="dropdown" dropdown="What is carbon offsetting and why don’t you support it?"
        [contentMode]="true" [smallText]="true">
        <div class="text">What are <span class="italics">carbon offsets</span>? Carbon offsetting is a system where you
            can
            pay someone else to reduce their carbon emissions to compensate for your own carbon emissions. The idea is
            that
            your emissions are ‘cancelled out’ by a reduction elsewhere.</div>
        <div class="text">Many so-called ‘environmental’ companies will tempt you into <span
                class="italics">buying</span>
            carbon offsets as a way to ‘remove your carbon footprint’ with the click of a button.</div>
        <div class="text">We don’t. Read Eden’s stance on carbon offsetting <span class="turq-text bold underline pointer" routerLink="/articles/carbon-offsetting-threat-climate-action">here</span>.</div>
    </app-dropdown>
    <app-dropdown class="dropdown" dropdown="What is the ‘Triple Planetary Crisis’ and why does it matter?"
        [contentMode]="true" [smallText]="true">
        <pre class="text">
We’ve said it before and we’ll say it again: <span class="italic">restoring our planet is not as simple as planting a tree</span>.

It’s much bigger than that. There are a whole range of problems ranging from air pollution to biodiversity loss to rising sea levels, which require a range of solutions.

So where should we start?

When we founded Eden Greenspace, we took our lead from the UNEP, or <span class="italic">United Nations Environment Programme</span>. They have identified <a class="link" href="https://unfccc.int/blog/what-is-the-triple-planetary-crisis](https://unfccc.int/blog/what-is-the-triple-planetary-crisis" target="_blank">three crises</a> facing our planet: climate, environment and pollution.

• <span class="climate">Climate crisis</span> - Perhaps the biggest crisis, climate change refers to the “long-term shifts in temperatures and weather patterns that in the long run will completely alter the ecosystems that support life on the planet.” Human actions have caused subtle changes to our climate to become a huge threat. To combat this crisis, we need to reduce our CO2 emissions and restore the ecosystems which naturally absorb CO2 from the atmosphere (such as via <span class="link" routerLink="/our-projects/tree-planting-in-kenya" target="_blank">tree-planting</span>).

• <span class="nature">Nature crisis</span> - Our planet’s ecosystems are under extreme strain from agricultural expansion, over-fishing and the effects of global warming. The result is the 1 in 8 species are facing extinction. But it’s affecting humans too - the nature crisis is impacting our food supplies, access to clean water, and is increasing weather-related disasters. Protecting endangered species and restoring our natural ecosystems are great ways to combat this crisis (for example, see our <span class="link" routerLink="/our-projects/rewilding-projects-uk" target="_blank">‘save the bees’ rewilding project</span>).

• <span class="pollution">Pollution crisis</span> - Our planet is being polluted in multiple ways. Our air is polluted by industrial emissions, often fossil fuels. Our fields and rivers are polluted by chemicals and waste (often due to a <span class="link" routerLink="/our-projects/rewilding-projects-uk" target="_blank">lack of natural pollinators</span>). Our oceans our polluted by plastic waste and microplastics - read about our <span class="link" routerLink="/our-projects/marine-plastic-removal-around-the-uk" target="_blank">marine plastic removal project</span> to see how you can address this crisis.

Eden Greenspace was started with the goal of providing a <span class="bold italic">range of environmental projects</span> that, collectively, will drive us towards the restoration of our planet.

We think that these three crisis are a good - if sobering - place for the change to begin.

            <img class="big-image clear-image-text" src="../../../assets/methodology-crisis.png" />
<pre class="text">
You can learn more about our projects <span class="link" routerLink="/our-projects">here</span>.
</pre>
        </pre>
    </app-dropdown>
    <app-dropdown class="dropdown" dropdown="What is a Not-for-Profit?" [contentMode]="true" [smallText]="true">
        <div class="pledge-container">
            <div class="pledge">
                <div class="text">What you see is what you get. As a not-for-profit, we guarantee that <span class="bold">100%
                        of
                        the money donated</span> to our environmental projects goes directly into funding restoration work on
                    the
                    ground.</div>
                <div class="text">How do we fund our curation work? We charge our corporate clients an additional 17.5% fee on
                    all
                    donations, to access our online account, marketing suite and receive expert advice.</div>
                <div class="text">All donations received through Eden’s platform can be viewed on our <span
                        class="pointer bold clickable underline orange" routerLink="/about/impact">Public Impact
                        Registry</span>.
                </div>
            </div>
            <img class="pledge-image" src="../../../assets/pie-chart.png" />
        </div>
        </app-dropdown>
    </div>
</div>

