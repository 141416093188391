<!--
<div class="content">
    <div class="title divider">How It Works</div>
    <div class="container">
        <div class="info"> <span class="number">1</span><span class="info-text">Choose your plan <br> to get eco coins</span></div>
        <div class="info"> <span class="number">2</span> <span class="info-text">Assign eco coins to <br> different green projects</span></div>
        <div class="info"> <span class="number">3</span> <span class="info-text">Make an impact</span></div>
    </div>
    <div class="container">
        <div class="item">
            <div class="visuals">
                <div class="plan">
                    <div class="tile">
                        <div class="name">Kickstarter Plan</div>
                        <img class="plan-image" src="../../../assets/Kick-starter3.png">
                    </div>
                    <div class="tokens">
                        <div class="token-count">
                            2
                        </div>
                        <img class="token-logo" src="../../../assets/coinNEW1.png">
                        <div class="token-count">
                            coins /month
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item">
            <div class="visuals">
                <div class="projects">
                    <div class="project upper">
                        <div class="counter">
                            <img class="coin" src="../../../assets/coin3.png">
                            <div class="count">
                                1
                            </div>
                        </div>
                        <div class="not-counter">
                            <div class="project-name">Plant trees</div>
                            <img class="image" src="../../../assets/reforest-no-background.png">
                        </div>
                    </div>
                    <div class="project lower">
                        <div class="counter">
                            <img class="coin" src="../../../assets/coin3.png">
                            <div class="count">
                                1
                            </div>
                        </div>
                        <div class="not-counter">
                            <div class="project-name">Protect Rainforest</div>
                            <img class="image" src="../../../assets/coolearth-no-back.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item">
            <div class="visuals">
                <div class="space-even">
                    <div class="tokens impact">
                        Plant 7 trees /month <br> with each
                        <img class="token-logo intext" src="../../../assets/coinNEW1.png">
                        coin! <br>
                    </div>
                    <div class="tokens impact">
                        Protect 1 acre of rainforest <br>
                        /month with each
                        <img class="token-logo intext" src="../../../assets/coinNEW1.png">
                        coin!
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="text">Each eco coin has a different positive impact on the planet depending on the projects you pick.</div>
        <div class="text">Choose from the world's most innovative green projects.</div>
        <div class="text">Tailor your impact to what matters most to you.</div>
    </div>
</div>
-->
<div #content [class]="blur ? 'content blur' : 'content'">
    <app-title text="How It Works" large="true"></app-title>
    <img class="big-pic ver" src="../../../assets/how-it-works-mob.png"/>
    <img class="big-pic hor" src="../../../assets/how-it-works-desk.png"/>
</div>

