<div class="content">
    <div *ngIf="!popup" data-template-page-bg=""
        style="background:#e8e4df none no-repeat center/cover;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;height: auto;width: 100%;background-color: #e8e4df;background-position: center;background-size: cover;position: relative;background-image: none;background-repeat: no-repeat;min-height: 100vh;box-sizing: border-box !important;margin: 0 !important;padding: 0 !important;">
        <div class="wrapper"
            style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;height: auto;min-height: 100vh;display: flex;flex-direction: column;box-sizing: border-box !important;">
            <div class="templateSection templateHeader"
                style="background:transparent none no-repeat center/cover;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;display: flex;flex-shrink: 0;justify-content: center;padding-right: 0px;padding-left: 0px;background-color: transparent;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 54px;padding-bottom: 0;box-sizing: border-box !important;">
                <div class="headerContainer contentContainer"
                    style="background:#FFFFFF none no-repeat center/cover;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;max-width: 780px;width: 100%;flex: 0 0 auto;background-color: #FFFFFF;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 36px;padding-bottom: 18px;box-shadow: none;box-sizing: border-box !important;">
                    <div class="mcnImageBlock mcnImageBlockInner"
                        style="padding: 9px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                        <div class="mcnImageContent"
                            style="display: flex;padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;justify-content: center;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
    
    
                            <img alt=""
                                src="https://mcusercontent.com/d36628931159a87705367b8f9/images/ea333430-a528-e5b4-ba13-6a049d79129c.png"
                                width="182.28"
                                style="max-width: 100%;align-self: center;flex: 0 0 auto;box-shadow: none;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;border: 0;height: auto;outline: none;text-decoration: none;vertical-align: bottom;box-sizing: border-box !important;"
                                class="mcnImage">
    
    
                        </div>
                    </div>
                    <div width="100%" class="mcnTextBlock"
                        style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                        <div class="mcnTextBlockInner"
                            style="display: flex;padding: 9px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
    
                            <div
                                style="width: 100%;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                <div class="mcnTextContent"
                                    style="padding-top: 0;padding-right: 9px;padding-bottom: 9px;padding-left: 9px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;word-break: break-word;flex-flow: column;color: #808080;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;box-sizing: border-box !important;">
                                    <div
                                        style="text-align: center;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                        <span
                                            style="font-size: 46px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><span
                                                style="color: #008000;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><strong
                                                    style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><span
                                                        style="font-family: arial,helvetica neue,helvetica,sans-serif;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">Subscribe
                                                        and plant a tree!</span></strong></span></span><br
                                            style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                        <span
                                            style="font-size: 19px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><span
                                                style="font-family: arial,helvetica neue,helvetica,sans-serif;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">We'll
                                                plant a tree in your name when you subscribe to our monthly
                                                newsletter.</span></span></div>
    
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
            <div class="templateSection templateBody"
                style="background: none center center / cover no-repeat transparent; display: flex; flex-shrink: 0; justify-content: center; padding: 0px 0px 0px; border-top: 0px; border-bottom: 0px; box-sizing: border-box !important;">
                <div class="sectionFlexColumn"
                    style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;align-items: center;display: flex;flex-direction: column;width: 100%;box-sizing: border-box !important;">
                    <div class="bodyContainer contentContainer"
                        style="background:#FFFFFF none no-repeat center/cover;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;max-width: 780px;width: 100%;flex: 0 0 auto;background-color: #FFFFFF;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0;padding-bottom: 45px;box-shadow: none;box-sizing: border-box !important;">
                        <div class="mcnImageBlock mcnImageBlockInner"
                            style="padding: 9px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                            <div class="mcnImageContent"
                                style="display: flex;padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;justify-content: center;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
    
    
                                <img alt=""
                                    src="https://mcusercontent.com/d36628931159a87705367b8f9/images/00fb5fbf-18fc-400b-afea-d3c61b2fd16b.jpg"
                                    width="496"
                                    style="max-width: 100%;align-self: center;flex: 0 0 auto;border-radius: 5%;box-shadow: none;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;border: 0;height: auto;outline: none;text-decoration: none;vertical-align: bottom;box-sizing: border-box !important;"
                                    class="mcnImage">
    
    
                            </div>
                        </div>
                        <div width="100%" class="mcnTextBlock"
                            style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                            <div class="mcnTextBlockInner"
                                style="display: flex;padding: 9px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
    
                                <div
                                    style="width: 100%;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                    <div class="mcnTextContent"
                                        style="padding-top: 0;padding-right: 9px;padding-bottom: 9px;padding-left: 9px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;word-break: break-word;flex-flow: column;color: #808080;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;box-sizing: border-box !important;">
                                        <p
                                            style="text-align: center;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;margin: 10px 0;padding: 0;color: #808080;font-family: Helvetica;font-size: 16px;line-height: 150%;box-sizing: border-box !important;">
                                            <span
                                                style="font-size: 15px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><span
                                                    style="color: #FF8C00;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><strong
                                                        style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">Over
                                                        90% of Kenya has been deforested. We're trying to
                                                        help.</strong></span><br
                                                    style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                                <br
                                                    style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                                <span
                                                    style="font-family: lucida sans unicode,lucida grande,sans-serif;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><span
                                                        style="color: #000000;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">By
                                                        subscribing to our free eco newsletter, you can plant a tree in the
                                                        Kijabe Forest, Kenya and make a&nbsp;</span><a
                                                        href="https://edengreenspace.co.uk/overview" target="_blank"
                                                        style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;color: #00ADD8;font-weight: normal;text-decoration: underline;box-sizing: border-box !important;"><span
                                                            style="color: #FF8C00;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><u
                                                                style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><strong
                                                                    style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">collective
                                                                    impact</strong></u></span></a><span
                                                        style="color: #000000;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">&nbsp;to
                                                        restore Kenya's natural ecosystems.</span><br
                                                        style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                                    <br
                                                        style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                                    <span
                                                        style="color: #000000;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">We
                                                        provide </span><a href="https://edengreenspace.co.uk/overview"
                                                        target="_blank"
                                                        style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;color: #00ADD8;font-weight: normal;text-decoration: underline;box-sizing: border-box !important;"><span
                                                            style="color: #FF8C00;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><u
                                                                style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><strong
                                                                    style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">evidence</strong></u></span></a><span
                                                        style="color: #000000;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">&nbsp;for
                                                        all the impact our community makes, and you can learn more about
                                                        this project&nbsp;and the rest of our environmental
                                                        projects&nbsp;</span><a href="https://edengreenspace.co.uk/our-projects"
                                                        target="_blank"
                                                        style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;color: #00ADD8;font-weight: normal;text-decoration: underline;box-sizing: border-box !important;"><span
                                                            style="color: #FF8C00;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><u
                                                                style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><strong
                                                                    style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">here</strong></u></span></a>.&nbsp;</span><br
                                                    style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                                <br
                                                    style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                                <span
                                                    style="color: #FF8C00;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><strong
                                                        style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><span
                                                            style="font-family: lucida sans unicode,lucida grande,sans-serif;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">Subscribe
                                                        </span></strong></span><span
                                                    style="color: #000000;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><span
                                                        style="font-family: lucida sans unicode,lucida grande,sans-serif;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">to
                                                        our newsletter&nbsp;below and we'll send you an email with more
                                                        details about your tree and the impact it is
                                                        having!</span></span></span><br
                                                style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                            <br
                                                style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                            <span
                                                style="font-family: lucida sans unicode,lucida grande,sans-serif;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><span
                                                    style="color: #008000;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><span
                                                        style="font-size: 14px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;"><strong
                                                            style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">Thanks
                                                            for helping us restore our planet.<br
                                                                style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                                            The Eden Greenspace Team&nbsp;</strong></span></span>
                                                <font color="#336633"
                                                    style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">
                                                    <strong
                                                        style="-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box !important;">🌳</strong>
                                                </font>
                                            </span></p>
    
                                    </div>
                                </div>
    
                            </div>
                        </div>
                        <div class="content white">
                            <div [class]="popup ? 'popup-form' : 'form'">
                                <!--
                                        <div *ngIf="!popup" class="image-container">
                                        <img class="image" src="../../../assets/cropped-Eden-Greenspace.png"/> 
                                        <<div *ngIf="!popup" class="image-container">/div>
                                    -->
                                <div *ngIf="popup" class="popup-control">
                                    <div>Stay up to date with the</div>
                                    <div>latest <span class="bold">Eco News!</span></div>
                                </div>
                                <div *ngIf="popup" class="image-container">
                                    <img class="newsletter-image" src="../../../assets/newsletter.jpg" />
                                </div>
                                <div *ngIf="message" [class]="'control' + (error ? ' error' : ' success')">{{ message }}</div>
                                <input class="control border" placeholder="Full Name" [(ngModel)]="name">
                                <input class="control border" placeholder="Email" [(ngModel)]="email">
                                <button class="control pointer clickable" (click)="signUp()">Sign Up to Our Newsletter</button>
                                <div *ngIf="!popup" class="control">
                                    Want to make an impact?
                                </div>
                                <div *ngIf="!popup" class="control">
                                    Every plan comes with a subscription to our Newsletter. Choose your plan now to get started.
                                </div>
                            
                                <button *ngIf="!popup" class="control pointer clickable" routerLink="/">Choose your plan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="popup" [class]="popup ? 'popup-form' : 'form'">
        <!--
            <div *ngIf="!popup" class="image-container">
            <img class="image" src="../../../assets/cropped-Eden-Greenspace.png"/> 
            <<div *ngIf="!popup" class="image-container">/div>
        -->
        <div *ngIf="popup" class="popup-control">
            <div>Stay up to date with the</div>
            <div>latest <span class="bold">Eco News!</span></div>
        </div>
        <div *ngIf="popup" class="image-container">
            <img class="newsletter-image" src="../../../assets/newsletter.jpg" />
        </div>
        <div *ngIf="message" [class]="'control' + (error ? ' error' : ' success')">{{ message }}</div>
        <input class="control border" placeholder="Full Name" [(ngModel)]="name">
        <input class="control border" placeholder="Email" [(ngModel)]="email">
        <button class="control pointer clickable" (click)="signUp()">Sign Up to Our Newsletter</button>
        <div *ngIf="!popup" class="control">
            Want to make an impact?
        </div>
        <div *ngIf="!popup" class="control">
            Every plan comes with a subscription to our Newsletter. Choose your plan now to get started.
        </div>
        
        <button *ngIf="!popup" class="control pointer clickable" routerLink="/">Choose your plan</button>
    </div>
</div>
