import { Component, OnInit } from '@angular/core';
import { AuthService, LeaderBoardItem, RecentOrder } from 'src/app/services/auth.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css']
})
export class LeaderboardComponent implements OnInit {
  public leaderboard: LeaderBoardItem[];
  public displayLeaderboard: LeaderBoardItem[];
  public recentOrders: RecentOrder[];
  public totalTrees: string;
  public totalCO2: string;
  public totalLand: string;
  public maxTotalsLength = 0;
  public maxTableLength = 0;
  public ttConfig = {
    'placement': 'top',
    'hideDelay': 0,
  }
  public toggle = 'leaderboard';
  public searchTerm: string;
  public isBusiness = false;

  private authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
   }

  ngOnInit(): void {
    this.authService.isEstateAgent().subscribe((isBusiness) => { this.isBusiness = isBusiness });
    this.authService.getLeaderboard().subscribe((leaderboard) => {
      this.leaderboard = [...leaderboard];
      this.displayLeaderboard = leaderboard;

      console.log(leaderboard);

      let trees = 0;
      let CO2 = 0;
      let land = 0;

      for(const item of leaderboard) {
        //item.rewilding = 6666666;
        //item.solarLighting = 100000;
        //item.plastics = 500000000;

        trees = trees + item.trees;
        CO2 = CO2 + (0.150 * item.trees) + (1.1 * item.solarLighting);
        land = land + item.trees + item.rewilding;

        const maxImpact = Math.max(
          item.trees.toString().length, 
          (item.plastics/100).toString().length + 2,
          item.rewilding.toString().length + 2,
          item.solarLighting.toString().length + 6,
        );

        if(maxImpact > this.maxTableLength) {
          this.maxTableLength = maxImpact;
        }
      }

      this.totalTrees = trees.toString();
      this.totalCO2 = (CO2.toFixed(1)).toString() + 't';
      this.totalLand = land.toString() + 'm\u00b2';
      this.maxTotalsLength = Math.max(this.totalTrees.length, this.totalCO2.length, this.totalLand.length);

    });

    this.authService.getRecentOrders().subscribe((res) => {
      this.recentOrders = res;
    });
  }

  public search(event: string): void {
    this.searchTerm = event;
    this.displayLeaderboard = [...this.leaderboard.filter((item) => item.branch.toLocaleLowerCase().includes(event.toLocaleLowerCase()))];
  }

}
