import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { environment } from 'src/environments/environment';
declare var Stripe: any;

export interface TreesEvent {
  id: string;
  name: string;
}

export interface BraceletBase {
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  postcode: string;
  quantity: number;
  express: boolean;
}

export interface BraceletObject extends BraceletBase {
  _id: string;
  dispatched?: boolean;
}

export interface BraceletEvent extends BraceletBase {
  id: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Input() cardOnly = false;
  @Input() edit = false;
  @Input() loginOnly = true;
  @Input() switchOnInit = false;
  @Input() color?: string;
  @Input() cart = false; 
  @Input() trees = false;
  @Input() bracelet = false; 
  @Input() business = false;
  @Input() impactCreate = false;
  @Input() hide = false;
  @Output() loginEvent: EventEmitter<string> = new EventEmitter();
  @Output() treesEvent: EventEmitter<TreesEvent> = new EventEmitter();
  @Output() braceletEvent: EventEmitter<BraceletEvent> = new EventEmitter();
  @Output() switchEvent: EventEmitter<boolean> = new EventEmitter();
  
  public username: string;
  public firstName: string;
  public lastName: string;
  public phone: string;
  public businessType: string;
  public branch: string;
  public password: string;
  public newPassword: string;
  public confirm: string;
  public confirmNew: string;
  public createNew = false;
  public error: string = '';
  public agreed = false;
  public address1: string;
  public address2: string;
  public city: string;
  public postcode: string;
  public newsletter: boolean;
  public CartService = CartService;
  public winkworth = false;
  public winkworthAgreed = false;
  public businessAgreed = false;
  public businessLogo: File;

  public ttConfig = {
    'placement': 'top',
    'hideDelay': 0,
  }

  private authService: AuthService;
  private router: Router;
  private stripe;
  private card?;
  private loading = false;

  constructor(authService: AuthService, router: Router) {
    this.authService = authService;
    this.router = router;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.stripe = environment.production
        ? Stripe('pk_live_51IX4vmBDboGHB3gmCr8cmjFzg0aO9LxJHErrbE8FAtOh806Mb3PWjkPFR3A6g4z4ySI1mlfoToieenXke3iuFP1A00zZvlxg13')
        : Stripe('pk_test_51IX4vmBDboGHB3gmxC86oDlimVDH8gS6qAZ0QFAT6BtWq2aYrO4VCosc457AOLotPFFMfM0hK7bOTlgLMxzwQgYT00rY5mAx2D');
    }, 200);

    this.winkworth = this.router.url.split('/').pop() === 'create-winkworth-account';

    if(this.winkworth) {
      this.business = true;
      this.businessType = 'Estate Agent';
    }

    if(this.cardOnly || this.switchOnInit) {
      this.switch();
    }

    if(this.trees) {
      this.mountStripe();
    }
  }

  public async login() {
    if(this.loading) {
      return;
    }
    this.loading = true;
    
    let isError = false;
    if((this.createNew || this.trees || (this.cardOnly && this.impactCreate)) && !this.agreed) {
      this.error = 'Please agree to our Terms and Conditions and Privacy Policy';
      this.loading = false;
      return;
    }
    if((this.createNew || this.business) && this.password !== this.confirm) {
      this.error = 'Passwords do not match';
      this.loading = false;
      return;
    }
    if((this.createNew || this.business) && !this.password?.length) {
      this.error = 'Please enter a password';
      this.loading = false;
      return;
    }
    if((this.createNew || this.trees || this.business) && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.username)) {
      this.error = 'Email is invalid';
      this.loading = false;
      return;
    }
    if((this.createNew || this.business || this.bracelet) && !this.firstName) {
      this.error = 'Please enter your first name';
      this.loading = false;
      return;
    }
    if ((this.createNew || this.business || this.bracelet) && !this.lastName) {
      this.error = 'Please enter your last name';
      this.loading = false;
      return;
    }

    if(this.business && !this.phone) {
      this.error = 'Please enter your phone number';
      this.loading = false;
      return;
    }

    if(this.business && !this.businessType) {
      this.error = 'Please enter your business type';
      this.loading = false;
      return;
    }

    if (this.business && !this.winkworth && !this.businessAgreed ) {
      this.error = 'Please agree to our terms and conditions';
      this.loading = false;
      return;
    }

    if (this.business && !this.winkworth && !this.businessLogo) {
      this.error = 'Please upload your company logo';
      this.loading = false;
      return;
    }

    if(this.winkworth && !this.branch) {
      this.error = 'Please enter your branch';
      this.loading = false;
      return;
    }

    if(this.winkworth && !this.winkworthAgreed) {
      this.error = 'Please agree to our terms and conditions';
      this.loading = false;
      return;
    }

    if(this.bracelet) {
      if(!this.address1) {
        this.error = 'Please enter address line 1';
        this.loading = false;
        return;
      }

      if(!this.address2) {
        this.error = 'Please enter address line 2';
        this.loading = false;
        return;
      }

      if(!this.city) {
        this.error = 'Please enter city';
        this.loading = false;
        return;
      }

      if(!this.postcode) {
        this.error = 'Please enter postcode';
        this.loading = false;
        return;
      }
    }

    if(this.trees && !this.bracelet) {
      if (!Number.isInteger(CartService.quantity) || CartService.quantity < 2) { 
        this.error = 'Quantity must be at least 2 and whole number';
        this.loading = false;
        return;
      }
    }
    
    if(this.createNew || this.trees) {
      await this.stripe
          .createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
              name: this.username,
            },
          }).then((result) => {
            if(result.error) {
              this.error = 'Invalid card details.'
              isError = true;
            }
          });
      if(isError){
        this.loading = false;
        return;
      }
    }
    if(!this.trees && !this.business) {
      await this.authService.login(this.username, this.password, this.createNew, this.firstName, this.lastName).subscribe(async (res) => {
        if(this.createNew) {
          await this.stripe
          .createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
              name: this.username,
            },
          }).then((result) => {
            this.loginEvent.emit(result.paymentMethod.id);
          });
        } else {
          this.loginEvent.emit();
          if(this.loginOnly) {
            this.router.navigate(['/account-page']);
          }
        }
      },
      (err) => {
        console.log(err);
        this.error = err.error;
      });
    } else if(this.trees) {
      if (this.bracelet) {
        if(this.newsletter) {
          this.authService.newsletterSignup(this.username, this.firstName).subscribe();
        }
        await this.stripe
          .createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
              name: this.username,
            },
          }).then((result) => {
            this.braceletEvent.emit({
              id: result.paymentMethod.id,
              email: this.username,
              firstName: this.firstName,
              lastName: this.lastName,
              address1: this.address1,
              address2: this.address2,
              city: this.city,
              postcode: this.postcode,
              quantity: 1,
              express: CartService.express,
            });
          });
      } else {
        await this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.username,
          },
        }).then((result) => {
          this.treesEvent.emit({
            id: result.paymentMethod.id,
            name: this.username,
          });
        });
      }
    } else if(this.business) {
      if(this.winkworth) {
        await this.authService.login(
          this.username,
          this.password,
          false,
          this.firstName,
          this.lastName,
          true,
          this.phone,
          this.businessType,
          null,
          this.branch,
        ).subscribe(
          (res) => {
            this.router.navigate(['account-page']);
          },
          (err) => {
            console.log(err);
            if (err.status === 403) {
              this.error = 'This email address is unauthorized'
            } else {
              this.error = err.error;
            }
          });
      } else {
          await this.authService.login(
          this.username, 
          this.password, 
          true,
          this.firstName, 
          this.lastName,
          true,
          this.phone,
          this.businessType,
          this.businessLogo,
        ).subscribe(
          (res) => {
            this.loginEvent.emit();
          },
          (err) => {
            console.log(err);
            this.error = err.error;
        });
      }
    }
    this.loading = false;
  }

  public async pay() {
    if (this.loading) {
      return;
    }
    this.loading = true;

    let isError = false;
    if (this.cardOnly && this.impactCreate && !this.agreed) {
      this.error = 'Please agree to our Terms and Conditions and Privacy Policy';
      this.loading = false;
      return;
    }

    await this.stripe
          .createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
              name: this.username,
            },
          }).then((result) => {
            if(result.error) {
              this.error = 'Invalid card details.'
              isError = true;
            }
          });
      if(isError){
        this.loading = false;
        return;
      }
    await this.authService.getAccountName().subscribe(async (account) => {
    await this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: account.user.name,
        },
      }).then((result) => {
        this.loginEvent.emit(result.paymentMethod.id);
      });
    });
    this.loading = false;
  }

  public async update() {
    if(this.cardOnly) {
      let isError = false;
      await this.stripe
          .createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
              name: this.username,
            },
          }).then((result) => {
            if(result.error) {
              this.error = 'Invalid card details.'
              isError = true;
            }
          });
      if(isError){
        return;
      }
      this.authService.getAccountName().subscribe((account) => {
      this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: account.user.name,
          },
        }).then((result) => {
          this.loginEvent.emit(result.paymentMethod.id);
        });
      });
    } else {
      if(!this.confirmNew?.length) {
        this.error = 'Please enter a new password';
        return;
      }
      if(!this.confirmNew?.length) {
        this.error = 'Please enter a new password';
        return;
      }
      if(this.confirmNew !== this.newPassword) {
        this.error = 'New passwords do not match';
        return;
      }
      if(!this.password?.length) {
        this.error = 'Please enter a password';
        return;
      }
      this.authService.updatePassword(this.password, this.newPassword).subscribe(
        (res) => {
        //redirect
        this.router.navigate(['success', 'password']);
        },
        (error) => {
          console.log(error);
          this.error = error.error;
        });
    }
  }

  public switch() {
    this.createNew = !this.createNew;
    this.switchEvent.emit(this.createNew);
    
    if(this.createNew || this.cardOnly) {
      this.mountStripe();
    }
  }

  private mountStripe(): void {
    setTimeout(() => {
      const elements = this.stripe.elements();
      const style =  {
        style: {
          base: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '17px',
            backgroundColor: 'white',
          },
          invalid: {  'color': 'red', },
        }
      };
      this.card = elements.create('card', style);
      this.card.mount('#card-element');
    }, 200);
  }

  public imageChange(event): void {
    this.businessLogo = event.target.files[0];
  }

}
