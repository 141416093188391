import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { BIOS } from '../projects/projects.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public text: number;
  public dropdown = false;
  public aboutSelect = false;
  public actionSelect = false;
  public projectSelect = false;
  public mobAboutSelect = false;
  public mobProjectSelect = false;
  public mobActionSelect = false;

  public bios = BIOS;

  public CartService = CartService;

  ngOnInit(): void {
  }

}
