<div class="content">
    <div class="title">Help Us Plant Trees!</div>
    <div class="main-white-background">
        <div class="row wide">
            <div class="border">
                <div class="text center">Our tree planting drive in Kenya!</div>
                <span class="button pointer clickable" (click)="scroll()">Plant Trees</span>
            </div>
            <div class="counter"> 
                <span>{{ trees?.current || 0 }}</span>
                <!--
                <div class="frac">
                    <span>{{ trees?.current || 0 }}</span>
                    <span class="bottom">{{ trees?.goal || 0 }}</span> 
                </div>
                -->
                <div class="counter-text">Trees planted so far!</div>
            </div>
        </div>
        <!--
        <app-carousel class="gallery" [images]="images"></app-carousel>
        -->
        <div class="white-background-top">
            <div class="option">
                <img class="option-image" [src]="'../../../assets/' + getImage(3)" />
                <div class="option-title">Tree Planting In Kenya</div>
                <div class="bottom">
                    <div class="option-text">By planting trees, you can fight climate change while supporting local communities in rural Kenya,
                    which has experienced mass deforestation. Trees are the lungs of the planet, essential to maintaining a healthy climate.
                    Tree planting is a natural and highly effective way of absorbing CO2 from the atmosphere, with each tree removing 150 kg
                    of CO2 over its lifetime.</div>
                    <div class="buttons">
                        <a class="button pointer clickable" [href]="getUrl(3)" target="_blank">Learn More About This Project Here</a>
                    </div>
                </div>
            </div>
        </div>
        <!--
        <img class="logo" src="../../../assets/mangrove-swamp.png" />
        -->
    </div>
    <!--
    <div class="row narrow">
        <div class="button pointer clickable" routerLink="/our-projects/tree-planting-in-kenya">View This Project</div>
        <div class="button pointer clickable" routerLink="/overview">Overview</div>
    </div>

    <a class="project-image-container" routerLink="/our-projects/tree-planting-in-kenya" target="_blank">
        <div class="project-image-text">Tree Planting in Kenya</div>
        <div class="project-image-text-container">
            <img class="project-image" src="../../../assets/1-1.jpg"/>
            <div class="logos">
                <img class="logo" src="../../../assets/trees-logo.PNG"/>
            </div>
        </div>
    </a>
    -->
</div>
<div class="login-container">
    <div #pay class="sub-title">Just 90p, To Plant A Tree!</div>
    <div class="buttons">
        <div class="text">Number of trees:</div>
        <input class="input" type="number" min="2" [(ngModel)]="CartService.quantity" />
        <div class="small-text">&nbsp; (Minimum 2 trees)</div>
    </div>
    <div class="text bold">Total: £{{ CartService.quantity * 0.9 | number:'1.2-2'}}</div>
    <div class="info" *ngIf="message">
        {{ message }}
    </div>
    <ng-container *ngIf="loading">
        <div class="info">
            Processing request...
        </div>
    </ng-container>
    <app-login
        *ngIf="!loading"
        [loginOnly]="false"
        color="white"
        [trees]="true"
        (treesEvent)="login($event)"
    ></app-login>
</div>
