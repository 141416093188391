import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeScreenComponent } from './components/home-screen/home-screen.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ChoosePlanComponent } from './components/choose-plan/choose-plan.component';
import { HttpClientModule } from '@angular/common/http';
import { PlanSummaryComponent } from './components/plan-summary/plan-summary.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { LoginComponent } from './components/login/login.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './services/interceptor.service';
import { AccountComponent } from './components/account/account.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { BioComponent } from './components/bio/bio.component';
import { SuccessComponent } from './components/success/success.component';
import { FooterComponent } from './components/footer/footer.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FaqComponent } from './components/faq/faq.component';
import { MissionComponent } from './components/mission/mission.component';
import { ProblemComponent } from './components/problem/problem.component';
import { FadeComponent } from './components/fade/fade.component';
import { ButtonComponent } from './components/button/button.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { SideTabComponent } from './components/side-tab/side-tab.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { GiftingComponent } from './components/gifting/gifting.component';
import { CartComponent } from './components/cart/cart.component';
import { TitleComponent } from './components/title/title.component';
import { AdminComponent } from './components/admin/admin.component';
import { BlogComponent } from './components/blog/blog.component';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ArticleComponent } from './components/article/article.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PoundPipe } from './pipes/pound.pipe';
import { TreePlantingComponent } from './components/tree-planting/tree-planting.component';
import { BusinessComponent } from './components/business/business.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ImpactCreationComponent } from './components/impact-creation/impact-creation.component';
import { NumberDirective } from './directives/numbers-only.directive';
import { BraceletsComponent } from './components/bracelets/bracelets.component';
import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { PasswordComponent } from './components/password/password.component';
import { MediaComponent } from './components/media/media.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FolderComponent } from './components/folder/folder.component';
import { BusinessHowItWorksComponent } from './components/business-how-it-works/business-how-it-works.component';
import { TrustpilotComponent } from './components/trustpilot/trustpilot.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { PercentageComponent } from './components/percentage/percentage.component';
import { NewHomePageComponent } from './components/new-home-page/new-home-page.component';
import { PartnersComponent } from './components/partners/partners.component';
import { ReviewsTransparencyComponent } from './components/reviews-transparency/reviews-transparency.component';
import { UnitsPipe } from './pipes/units.pipe';
import { TotalImpactsComponent } from './components/total-impacts/total-impacts.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { PollsComponent } from './components/polls/polls.component';
import { PollResultsComponent } from './components/poll-results/poll-results.component';
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
  declarations: [
    AppComponent,
    HomeScreenComponent,
    NavbarComponent,
    ChoosePlanComponent,
    PlanSummaryComponent,
    HowItWorksComponent,
    LoginComponent,
    AccountComponent,
    ProjectsComponent,
    BioComponent,
    SuccessComponent,
    FooterComponent,
    DropdownComponent,
    FaqComponent,
    MissionComponent,
    ProblemComponent,
    FadeComponent,
    ButtonComponent,
    NewsletterComponent,
    SideTabComponent,
    GiftingComponent,
    CartComponent,
    TitleComponent,
    AdminComponent,
    BlogComponent,
    ArticleComponent,
    SafeHtmlPipe,
    PoundPipe,
    TreePlantingComponent,
    BusinessComponent,
    CarouselComponent,
    ImpactCreationComponent,
    NumberDirective,
    BraceletsComponent,
    LeaderboardComponent,
    PasswordComponent,
    MediaComponent,
    ToggleComponent,
    FolderComponent,
    BusinessHowItWorksComponent,
    TrustpilotComponent,
    BackButtonComponent,
    PercentageComponent,
    NewHomePageComponent,
    PartnersComponent,
    ReviewsTransparencyComponent,
    UnitsPipe,
    TotalImpactsComponent,
    ReviewsComponent,
    PollsComponent,
    PollResultsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TooltipModule,
    LazyLoadImageModule,
    NgApexchartsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
  private previousUrl = '';

  constructor(router: Router, viewportScroller: ViewportScroller) {
    /*
    router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd)
    ).subscribe(e => {
      // here you'll have your own logic, this is just an example.
      if (!(router.url.includes('blog') && this.previousUrl.includes('blog'))) {
        viewportScroller.scrollToPosition([0, 0]);
      }

      this.previousUrl = router.url;
    });
    */

  }
}
