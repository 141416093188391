import { Component } from '@angular/core';
import { Event, RouterEvent, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { FooterService } from './services/footer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'greenspace';

  public FooterService = FooterService;

  constructor(public router: Router) {
    router.events.pipe().subscribe((e: Event) => {
      if(e instanceof RouterEvent) {
        if (e.url === '/') {
          //FooterService.footer = false;
          //FooterService.fadeText = 'Click Button To Find Out More'
        } else {
          FooterService.footer = true;
        }
      }
    });
  }
}
