<app-how-it-works [blur]="false"></app-how-it-works>
<div [class]="blur ? 'content blur' : 'content'">
    <app-title #title text="Plans and Projects" medium="true"></app-title>
    <div *ngIf="getSelectedPlan()" class="mob-plans-container">
        <div  class="mob-plans">
            <div class="mob-tile">
                <img class="mob-plan-image" [src]="getSelectedPlan().image">
            </div>
            <div class="mob-plans-text">
                <div class="mob-cost">
                    {{ getSelectedPlan().cost }} /month
                </div>
                <div class="tokens">
                    <div class="token-count">
                        x{{ getSelectedPlan().tokens }}
                    </div>
                    <img class="mob-token-logo" src="../../../assets/coinNEW1.png">
                    <div class="token-count">
                        to spend
                    </div>
                    &nbsp;
                </div>
            </div>
        </div>
        <div class="change-plan pointer clickable" (click)="changePlanModal = true">Change Plan</div>
    </div>
    <div class="info-text center-text mob">Learn more about Eco-Coins <span class="link pointer" (click)="goToEcoCoins()">here.</span></div>
</div>
<div *ngIf="!blur" class="content-projects">
    <div #projects class="info">
        <span class="bold">{{ getSelectedPlan()?.name }} plan: {{ selectedProjects.length }}/{{ getSelectedPlan()?.tokens || 0 }} Eco-Coins</span> used.
    </div>
    <div class="plans-projects-container">
        <div class="plans">
            <div class="info underline">Subscription Packages</div>
            <div *ngFor="let plan of plans" [id]="plan.name" class="plan pointer clickable" [attr.data-selected]="plan.selected"
                (click)="selectPlan(plan)">
                <div class="tile">
                    <img class="plan-image" [src]="plan.image">
                </div>
                <div [class]="'plan-text-container ' + plan.name">
                    <div class="tokens">
                        <div class="token-count">
                            {{ plan.tokens }}
                        </div>
                        <img class="token-logo clear-image-text" src="../../../assets/coinNEW1.png">
                        <div class="token-count">
                            coins* with
                        </div>
                        &nbsp;
                    </div>
                    <div class="plan-text">
                        <span [innerHtml]="plan.tagline"></span>
                    </div>
                    <div class="cost">
                        {{ plan.cost }} /month
                    </div>
                </div>
            </div>
        </div>
        <div class="projects-container">
            <div class="info">Your chosen projects:</div>
            <div class="layers scroll">
                <div class="slider">
                    <ng-container *ngFor="let project of getSelectedProjectsWithIndex()">
                        <div class="layer">
                            <img class="bar" [src]="project.image">
                            <img class="cross pointer" src="../../../assets/cross.png" (click)="removeProject(project)">
                        </div>
                        <!--
                            <div *ngIf="project.index === 2" class="two">
                                &nbsp;
                            </div>
                            -->
                    </ng-container>
                    <!--
                        <div class="two">
                            &nbsp;
                        </div>
                        <div class="four">
                            &nbsp;
                        </div>
                        <div class="eight">
                            &nbsp;
                        </div>
                        -->
                    <div [class]="'bar ' + (project.index > 1 ? (project.index > 3  ? 'fr' : 'su') : 'ks')"
                        *ngFor="let project of getEmptyProjects()" [attr.data-empty]="true">
                    </div>
                </div>
            </div>
            <div class="buttons">
                <div class="bar-button pointer clickable" (click)="clearAll()">Clear All</div>
                <div class="shuffle-container">
                    <img class="shuffle" src="../../../assets/shuffle.png"/>
                    <div class="bar-button pointer clickable" (click)="randomSelect()">Choose for Me</div>
                </div>
            </div>
            <div class="sub-title">Click on projects below</div>
            <img id="project-scroll-top" class="scroll-arrow" src="../../../assets/down-arrow.svg" (click)="scrollProjectsUpDown(false)"/>
            <div #projectScroll class="projects scroll scroll-br">
                <div id="home-project" #project class="project" *ngFor="let project of projectsFirstRow" (click)="addToken(project)" [attr.data-disabled]="canAdd()">
                    <img id="info" class="info-button pointer" (click)="$event.stopPropagation(); openModal(true, project)"/>
                    <div [class]="'counter info-offset' + (getCount(project.name) ? '' : ' fade')">
                        <img class="coin" src="../../../assets/coin3.png">
                        <div class="count">
                            {{ getCount(project.name) }}
                        </div>
                    </div>
                    <div class="not-counter">
                        <img class="image" [src]="project.image">
                        <div class="project-name" [innerHtml]="project.name"></div>
                    </div>
                    <!--
                    <i *ngIf="!project.info" class="arrow down" (click)="openModal(true, project)"></i>
                    <i *ngIf="project.info" class="arrow up" (click)="project.info = !project.info"></i>
                    -->
                    <div class="button pointer clickable" (click)="$event.stopPropagation(); openModal(true, project)">More Details</div>
                    <div class="project-impact" [innerHtml]="project.infoImpact"></div>
                </div>
            </div>
            <img id="project-scroll-bottom" class="scroll-arrow" src="../../../assets/up-arrow.svg" (click)="scrollProjectsUpDown()"/>
        </div>
        <!--
        <div class="levels">
            <div class="kickstarter">
                Kickstarter
            </div>
            <div class="sustainer">
                Sustainer
            </div>
            <div class="front-runner">
                Front-runner
            </div>
        </div>
        -->
    </div>
    <div class="info-text desk">
        *Eco-Coins are the way we quantify the impact of all our projects. Learn more about Eco-Coins <span
            class="link pointer" (click)="goToEcoCoins()">here.</span>
    </div>
    <app-button 
        id="create-tailored-plan"
        text="Create My Tailored Plan"
        (click)="createPlan()" 
        [disabled]="!!getRemainingTokens() || !getSelectedPlanName()"
    ></app-button>
</div>
<app-plan-summary #summary *ngIf="getSelectedPlanName() && !blur" [blur]="!showSummary" [plans]="plans" [selectedProjects]="selectedProjects" [dataState]="true"></app-plan-summary>

<!-- The Modal -->
<div *ngIf="modal" id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content content">
        <span class="close pointer" (click)="openModal(false, undefined)">&times;</span>
        <div class="modal-title" [innerHtml]="infoProject.name"></div>
        <div class="modal-impact" [innerHtml]="infoProject.infoImpact"></div>
        <div class="modal-scroll">
            <img *ngIf="infoProject.infoImage" class="info-image" [src]="infoProject.infoImage">
            <div class="crisis">This project addresses the <span class="link pointer" routerLink="/overview"> {{infoProject.crisis}} crisis</span>.</div>
            <p>
                {{ infoProject.infoText }}
            </p>
            <div class="button-container">
                <a id="learn-more" class="modal-button pointer clickable" *ngIf="infoProject.id" [routerLink]="['/our-projects', getProjectUrl(infoProject.id)]" target="_blank">Learn More</a>
            </div>
        </div>
    </div>
      
</div>

<div *ngIf="changePlanModal" class="modal mob">
    <!-- Modal content -->
    <div class="plan-modal-content content">
        <span class="close pointer" (click)="changePlanModal = false">&times;</span>
        <app-title text="Choose Plan" small="true"></app-title>
        <div *ngFor="let plan of plans" class="modal-plans">
            <div class="mob-plans pointer" (click)="selectPlan(plan); changePlanModal = false">
                <div class="mob-tile">
                    <img class="mob-plan-image" [src]="plan.image">
                </div>
                <div class="mob-plans-text">
                    <div class="mob-cost">
                        {{ plan.cost }} /month
                    </div>
                    <div class="tokens">
                        <div class="token-count">
                            x{{ plan.tokens }}
                        </div>
                        <img class="mob-token-logo" src="../../../assets/coinNEW1.png">
                        <div class="token-count">
                            to spend
                        </div>
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-info-text">
            Eco-Coins are the way we quantify the impact of all our projects.
        </div>
    </div>

</div>