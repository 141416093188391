<div class="content">
    <img id="logo clear-image-text" class="logo pointer" (click)="dropdown = false" routerLink="/" src="../../../assets/logo-new.png"/>
    <div class="links">
        <!--
        <div class="link" routerLink="account">FAQs</div>
        -->
        <div class="link pointer" (mouseenter)="actionSelect = true" (mouseleave)="actionSelect = false">
            Action
            <div class="dropdown-select" [attr.data-open]="actionSelect">
                <div class="dropdown-item" (click)="dropdown = false; actionSelect = false" routerLink="products/eden-bracelet">Bracelets</div>
                <div class="dropdown-item" routerLink="business" (click)="actionSelect = false">Business</div>
                <div class="dropdown-item" (click)="dropdown = false; actionSelect = false" routerLink="choose-your-plan">Individual</div>
            </div>
        </div>
        <div class="link pointer" (mouseenter)="projectSelect = true" (mouseleave)="projectSelect = false" routerLink="our-projects">
            Projects
            <div class="dropdown-select" [attr.data-open]="projectSelect">
                <div
                    *ngFor="let bio of bios"
                    class="dropdown-item" 
                    (click)="dropdown = false; projectSelect = false; $event.stopPropagation()" 
                    [routerLink]="'our-projects/' + bio.url"
                >{{ bio.name }}</div>
            </div>
        </div>
        <div id="mission" class="link pointer" (mouseenter)="aboutSelect = true" (mouseleave)="aboutSelect = false">
            About
            <div class="dropdown-select" [attr.data-open]="aboutSelect">
                <div class="dropdown-item" routerLink="about/overview" (click)="aboutSelect = false">Overview</div>
                <a class="dropdown-item" href="learn">Articles and News</a>
                <div class="dropdown-item" routerLink="about/impact" (click)="aboutSelect = false">Our Impact</div>
                <div class="dropdown-item" routerLink="about/story" (click)="aboutSelect = false">Our Story</div>
                <div class="dropdown-item" routerLink="about/projects" (click)="aboutSelect = false">How we select our projects</div>
                <div class="dropdown-item" routerLink="about/team" (click)="aboutSelect = false">The Team</div>
            </div>
        </div>
        <div class="login-container">
            <img id="logo clear-image-text" class="cart pointer" (click)="dropdown = false" routerLink="/"
                src="../../../assets/login-logo.png" />
            <div id="account" class="link pointer" routerLink="account-page">Login</div>
        </div>
        <!--
        <img id="cart" *ngIf="CartService.cart.length" src="../../../assets/cart2.png" class="cart pointer" routerLink="cart"/>
        <img id="cart" *ngIf="!CartService.cart.length" src="../../../assets/cart.png" class="cart pointer" routerLink="cart"/>
        -->
    </div>
    <div class="icon-container">
        <img 
            class="link pointer icon" 
            src="../../../assets/navbar-icon.png"
            (click)="dropdown = !dropdown"
        >
    </div>
</div>
<div *ngIf="dropdown" class="dropdown">
    <div class="dropdown-content">
        <div id="mission" class="link pointer" (click)="mobActionSelect = !mobActionSelect" routerLink="overview">Action</div>
        <div class="dropdown-mob-container" [attr.data-open]="mobActionSelect">
            <div class="dropdown-item-mob" (click)="mobActionSelect = false; dropdown = false" routerLink="products/eden-bracelet">Bracelets</div>
            <div class="dropdown-item-mob" routerLink="business" (click)="mobActionSelect = false; dropdown = false">Business</div>
            <div class="dropdown-item-mob" (click)="mobActionSelect = false; dropdown = false" routerLink="choose-your-plan">Individual</div>
        </div>
        <div id="projects" class="link pointer" (click)="dropdown = false" routerLink="our-projects">Projects</div>
        <div id="mission" class="link pointer" (click)="mobAboutSelect = !mobAboutSelect" routerLink="overview">About</div>
        <div class="dropdown-mob-container" [attr.data-open]="mobAboutSelect">
            <a class="dropdown-item-mob" href="learn">Articles and News</a>
            <div class="dropdown-item-mob" routerLink="about/overview" (click)="mobAboutSelect = false; dropdown = false">Overview</div>
            <div class="dropdown-item-mob" routerLink="about/impact" (click)="mobAboutSelect = false; dropdown = false">Our Impact</div>
            <div class="dropdown-item-mob" routerLink="about/story" (click)="mobAboutSelect = false; dropdown = false">Our Story</div>
            <div class="dropdown-item-mob" routerLink="about/projects" (click)="mobAboutSelect = false; dropdown = false">How we select our projects</div>
            <div class="dropdown-item-mob" routerLink="about/team" (click)="mobAboutSelect = false; dropdown = false">The Team</div>
        </div>
        <div id="account"class="link pointer" (click)="dropdown = false" routerLink="account-page">Login</div>
        <!--
        <img id="cart" *ngIf="CartService.cart.length"(click)="dropdown = false" src="../../../assets/cart2.png" class="cart pointer" routerLink="cart"/>
        <img id="cart" *ngIf="!CartService.cart.length" (click)="dropdown = false" src="../../../assets/cart.png" class="cart pointer" routerLink="cart"/>
        -->
    </div>  
</div>

