<div class="toggle" [style]="'--f: ' + fontSize" [attr.data-lettings]="lettings">
    <div 
        [attr.data-lettings]="lettings"
        *ngFor="let option of toggleOptions" 
        class="toggle-item pointer" 
        [attr.data-active]="toggle === option.name" 
        (click)="toggleSwitch(option.name)"
    >
        {{ option.displayName }}
    </div>
</div>
