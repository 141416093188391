import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project, projects } from 'server/projects';
import { AuthService } from 'src/app/services/auth.service';
import { SeoService } from 'src/app/services/seo.service';
import { ImpactText } from '../account/account.component';

export const TOTAL_IMPACT_TEXTS: ImpactText[] = [
  {
    id: 1,
    text: 'Trees planted \n in Nepal',
  },
  {
    id: 2,
    text: 'Trees planted \n in Madagascar',
  },
  {
    id: 3,
    text: 'Trees planted \n in Kenya',
  },
  {
    id: 4,
    text: 'Rewilded land \n to save bees (m\u00b2)',
  },
  {
    id: 5,
    text: 'Plastic removed \n from the sea (kgs)',
  },
  {
    id: 6,
    text: 'People provided \n with lighting ',
  },
  {
    id: 7,
    text: 'Clean cookstoves \n provided ',
  },
  {
    id: 8,
    text: 'Saved coral reef \n in Mexico (m\u00b2)',
  },
  {
    id: 9,
    text: 'Saved coral reef \n in Hawaii (m\u00b2)',
  },
  {
    id: 11,
    text: 'm\u00b2 of peatland restored',
  },
];

interface ProjectExample extends Project {
  url: string;
}

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {
  @ViewChild('ecoCoins') ecoCoins;
  @ViewChild('offsets') offsets;

  public static scroll: boolean;
  public tooltipOpen = false;

  public projectExamples: ProjectExample[] = [
    {
      id: 2,
      name: `<span class="project-bold">PLANT TREES</span> \n in Kenya's Great Rift Valley`,
      image: '../../../assets/kenya-logo.png',
      multiplier: 10,
      text: 'You will plant xxx trees /month in Kenya',
      infoImage: '../../../assets/3-1.png',
      infoText: 'By choosing to plant trees each month, you can fight climate change while supporting local communities in rural Kenya, which has experienced mass deforestation. Trees are the lungs of the planet, essential to maintaining a healthy climate. Tree planting is a natural and highly effective way of absorbing CO2 from the atmosphere, with each tree removing 150 kg of CO2 over its lifetime. Help us make an impact by planting 10 trees each month.',
      infoImpact: '1 Eco-Coin = 10 trees /month',
      crisis: '',
      url: '/our-projects/tree-planting-in-kenya',
    },
    {
      id: 4,
      name: '<span class="project-bold">CLEAN PLASTIC</span> \n from oceans around the UK',
      image: '../../../assets/plastics-logo.png',
      multiplier: 1,
      infoImage: '../../../assets/5-1.jpg',
      text: 'You will remove xxxkg of plastic /month',
      infoText: "By choosing this project, you will remove 1 kg of toxic marine plastic from the oceans around the UK - that's equivalent to 100 plastic bottles. Humans deposit 8 million tonnes of plastic into the ocean every year. Not only is plastic highly destructive for sea life such as whales and turtles, but indirectly accelerates climate change as these creatures are essential for keeping carbon out of the atmosphere. Help turn the tide on plastic waste by taking part in this project.",
      infoImpact: '1 Eco-Coin = 1kg of plastic /month',
      crisis: '',
      url: '/our-projects/marine-plastic-removal-around-the-uk',
    },
  ];

  public project: ProjectExample = {
    id: 2,
    name: '<span class="project-bold">SAVE BEES</span> \n from declining across the UK',
    image: '../../../assets/bees-logo.png',
    multiplier: 6,
    infoImage: '../../../assets/4-6.jpeg',
    text: 'You will rewild xxxm\u00b2 of land /month',
    infoText: "You can help increase wild bee populations by creating rich wildflower meadows in the UK. Bee populations have been decimated by urbanisation and climate change over the last decade, despite their critical role as pollinators. The urban landscapes that we see as neat and tidy are viewed by bees as desert. By creating channels for bees to migrate through, you can help dwindling bee populations to thrive.",
    infoImpact: '1 Eco-Coin = 6m\u00b2 of land /month',
    crisis: '',
    url: '/our-projects/rewilding-projects-uk',
  };

  public projects = projects;

  public totalImpactValues: ImpactText[];

  public option: string;

  private authService: AuthService;
  private seoService: SeoService;
  private route: ActivatedRoute;
  
  constructor(authService: AuthService, seoService: SeoService, route: ActivatedRoute) {
    this.authService = authService;
    this.seoService = seoService;
    this.route = route;
  }

  ngOnInit(): void {
    this.seoService.setSeo('Overview', 'We created Eden Greenspace to provide simple ways to protect our environment and fight climate change.');
    
    this.authService.getTotalImpact().subscribe(
      (totalImpactData) => {
        this.totalImpactValues = totalImpactData.map((impact) => {
          //let impactValue = (projects.find((project) => project.id === impact._id).multiplier * impact.totalTokens);
          let impactValue = impact.value;

          if(impact._id === 6) {
            impactValue = impactValue + 50;
          }

          if (impact._id === 2) {
            impactValue = impactValue + 200;
          }


          return {
            id: impact._id,
            text: impactValue.toString(),
          };
        });

        this.projects = this.projects.filter((project) => TOTAL_IMPACT_TEXTS.find((text) => project.id === text.id))
          .sort((a, b) => a.id - b.id);

        if(MissionComponent.scroll) {
          setTimeout(() => {
            this.ecoCoins.nativeElement.scrollIntoView({ behavior: 'smooth' });
          }, 1000);
          
          MissionComponent.scroll = false;
        }

      },
      () => {
        if (MissionComponent.scroll) {
          setTimeout(() => {
            this.ecoCoins.nativeElement.scrollIntoView({ behavior: 'smooth' });
          }, 1000);

          MissionComponent.scroll = false;
        }
      }
    );

    this.route.params.subscribe(params => {
      this.option = params['option'] || 'overview';
    });
  }

  public getImpactText(project: Project): string {
    return [
      {
        id: 1,
        text: 'Trees planted \n in Nepal',
      },
      {
        id: 2,
        text: 'Trees planted \n in Madagascar',
      },
      {
        id: 3,
        text: 'Trees planted \n in Kenya',
      },
      {
        id: 4,
        text: 'm\u00b2 of rewilded land',
      },
      {
        id: 5,
        text: 'kgs of plastic removed \n from the sea',
      },
      {
        id: 6,
        text: 'People provided \n with solar lighting ',
      },
      {
        id: 7,
        text: 'Clean cookstoves \n provided',
      },
      {
        id: 8,
        text: 'm\u00b2 of coral reef saved (Mexico)',
      },
      {
        id: 9,
        text: 'm\u00b2 of coral reef saved (Hawaii)',
      },
      {
        id: 11,
        text: 'm\u00b2 of peatland restored',
      },
    ].find((impactText) => impactText.id === project.id).text;
  }

  public getImpactValue(project: Project): string {
    if(!this.totalImpactValues) {
      return '';
    }

    return this.totalImpactValues.find((impactText) => impactText.id === project.id)?.text || '0';
  }

  public scrollToOffsets(): void {
    this.offsets.title.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  print(thing: any) {
    console.log(thing);
  }
}
