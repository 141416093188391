<app-fade *ngIf="!FooterService.footer" [text]="FooterService.fadeText"></app-fade>
<div *ngIf="FooterService.footer" class="super-content">
    <div class="content">
        <div class="title">FOLLOW US ON</div>
        <div class="icons">
            <a href="https://www.instagram.com/edengreenspace/" target="_blank">
                <img class="icon" src="../../../assets/instagram.png">
            </a>
            <a href="https://www.facebook.com/edengreenspace/" target="_blank">
                <img class="icon" src="../../../assets/facebook.png"/>
            </a>
            <a href="https://www.linkedin.com/company/eden-greenspace" target="_blank">
                <img class="icon" src="../../../assets/linkedin.png"/>
            </a>
        </div>
        <div class="title">GET IN TOUCH</div>
        <div class="item">contact@edengreenspace.co.uk</div>
        <div class="item">Maltfield Farm, Maltfield, Wedmore, BS28 4TR
        </div>
        <div class="row">
            <!-- TrustBox widget - Micro Review Count 
            <a href="https://www.iso.org/standard/66454.html" target="_blank">
                <img class="icon" src="../../../assets/iso-logo.png" />
            </a>
            -->
            <app-trustpilot></app-trustpilot>
        </div>
    </div>
    <div class="content">
        <div class="title">ABOUT</div>
        <a class="item" routerLink="/account-page">Account</a>
        <a class="item" routerLink="/overview">Overview</a>
        <a class="item" routerLink="/our-projects">Projects</a>
        <a class="item" routerLink="/business">Business</a>
        <a class="item" routerLink="/products/eden-bracelet">Bracelets</a>
        <a class="item" routerLink="/learn">Learn</a>
        <a class="item" href="../../../assets/Eden_Greenspace_Terms_and_Conditions.pdf" target="_blank">Terms and Conditions</a>
        <a class="item" href="../../../assets/Eden_Greenspace_Privacy_Policy.pdf" target="_blank">Privacy Policy</a>
    </div>
    <div class="content">
        <div class="title">NEWSLETTER</div>
        <div class="center">
            <div class="item title">Stay in touch with our monthly newsletter:</div>
            <div *ngIf="error" class="item error">{{error}}</div>
            <input class="control" [(ngModel)]="name" placeholder="Full Name"/>
            <input class="control" [(ngModel)]="email" placeholder="Email"/>
            <div class="button pointer clickable" (click)="signUp()">Sign up</div>
        </div>
    </div> 
</div>
