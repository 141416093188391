import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NextSectionComponent } from '../next-section/next-section.component';

@Component({
  selector: 'app-problem',
  templateUrl: './problem.component.html',
  styleUrls: ['./problem.component.css'],
  outputs: ['nextSection'],
})
export class ProblemComponent extends NextSectionComponent {
  @Input() blur = true;
  
  @ViewChild("title") title;
  
  constructor() {
    super();
  }
}
