<div class="content-container" *ngIf="isBusiness">
    <div class="content" *ngIf="!checkout && !isSubscription">
        <app-back-button></app-back-button>
        <app-title text="Create Impact" large="true"></app-title>
        <div *ngIf="lastOrder > 28" class="reminder-text">
            You have not ordered any certificates for completions or new lets for {{ lastOrder }} days.
            <br/><br/>
            Please order these certificates using the form below.
            <br /><br />
            <span class="bold underline orange pointer" (click)="reminder = true">If you have no deals during this time, please click here to tell us when your next completion/new let is expected.</span>
            <br /><br />
        </div>
        <div class="row">
            <div class="report-container">
            </div>
            <div class="form" [ngSwitch]="toggle">
                <app-toggle *ngIf="isEstateAgent" [toggleOptions]="toggleOptions" (toggleEvent)="toggleSwitch($event)" [lettings]="true"></app-toggle>
                <div *ngIf="voucher" class="voucher-info">
                    Please fill in the house details and chosen project to redeem your £24 voucher.
                </div>
                <div class="form-item">
                    <h2>Project:</h2>
                    <select (change)="projectChange($event)" class="control dropdown">
                        <option 
                            class="dropdown-option" 
                            *ngFor="let project of projects" 
                            [value]="project.id" 
                            [innerHTML]="project.name"
                            [selected]="project.id === purchase.projectId ? 'selected' : ''"
                        ></option>
                    </select>
                </div>
                <!--
                <div class="form-item" *ngSwitchCase="'lettings'">
                    <h2>Impact:</h2>
                    
                    <select class="control dropdown">
                        <option class="dropdown-option" (change)="selectLettingsPurchase($event)" *ngFor="let option of lettingsOptions" [value]="option"
                            [innerHTML]="option.text + ' (£' + option.cost + ')'"></option>
                    </select>
                    
                </div>
                -->
                <div class="form-item">
                    <h2>Order Date:</h2>
                    <input type="date" class="control" [(ngModel)]="purchase.date">
                </div>
                <ng-container *ngIf="!isEstateAgent">
                    <div class="form-item" >
                        <h2>Contribution Amount:</h2>
                        <input class="control" [ngModel]="purchase.cost?.toString() | pound" (ngModelChange)="setImpactValue($event)"
                            numbersOnly placeholder="Amount">
                    </div>
                    <div class="form-item">
                        <h2>Impact:</h2>
                        <div class="impact-text">{{ purchaseDetails }}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isEstateAgent">
                    <div class="form-item" *ngSwitchCase="'sales'">
                        <h2>Property Sale Price:</h2>
                        <input 
                            class="control" 
                            [ngModel]="purchase.houseCost?.toString() | pound" 
                            (ngModelChange)="setHouseCost($event)" 
                            numbersOnly
                            placeholder="Sale Price"
                        >
                    </div>
                    <div class="form-item">
                        <h2 *ngSwitchCase="'sales'">Vendor Name:</h2>
                        <h2 *ngSwitchCase="'lettings'">Landlord Name:</h2>
                        <input type="text" class="control" [(ngModel)]="purchase.buyer" maxlength="20" placeholder="Max 20 characters">
                    </div>
                    <div class="form-item">
                        <h2>Property Address:</h2>
                        <input type="text" class="control" [(ngModel)]="purchase.address" maxlength="28" placeholder="Max 28 characters">
                    </div>
                    <div class="form-item">
                        <h2>Image of House:</h2>
                        <input class="control" type="file" accept="image/*" (change)="imageChange($event)">
                    </div>
                </ng-container>
                <div class="form-item">
                    <h2>{{ isEstateAgent ? 'House name (for Office use)' : 'Reference/short description' }}:</h2>
                    <input type="text" class="control" [(ngModel)]="purchase.reference" maxlength="50" placeholder="Max 50 characters">
                </div>
                <div class="error" *ngIf="error">
                    {{ error }}
                </div>
                <div (click)="selectPurchase()" class="button pointer clickable">
                    Continue to Checkout
                </div>
                <div routerLink="/account-page" class="button clickable">
                    Back to Account Page
                </div>
            </div>
            <div class="report-container">
                <div class="report-label">“Using this system has really helped us get new instructions over the line.”</div>
                <div class="report">
                    <iframe class="pdf" src="https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/Impact-Creation-System-Report-1.pdf"></iframe>
                    <a class="report-link" href="https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/Impact-Creation-System-Report-1.pdf" target="_blank">Download</a>
                </div>
            </div>
        </div>
    </div>
    <div class="checkout" *ngIf="checkout && !isSubscription">
        <app-title text="Checkout" large="true"></app-title>
        <div class="form">
            <div class="subtitle">Purchase Summary</div>
            <div class="summary">
                <div class="summary-item">1x Certificate</div>
                <div class="summary-item">Impact: {{ purchaseDetails }}</div>
                <div *ngIf="!voucher" class="summary-item">Total: £{{ purchase.cost }}</div>
                <div *ngIf="isEstateAgent && toggle === 'sales'" class="summary-item">Sale Price: £{{ purchase.houseCost }}</div>
                <div class="summary-item">Reference: {{ purchase.reference }}</div>
                <div *ngIf="voucher" class="summary-item">Total: <s>£{{ purchase.cost }}</s><span class="bold"> Free </span><span class="voucher-text">(Voucher Applied *)</span></div>
            </div>
            <div class="subtitle">Certificate Details</div>
            <div class="summary">
                <div class="summary-item">Sale Date: {{ purchase.date }}</div>
                <div class="summary-item" *ngIf="isEstateAgent && toggle === 'sales'">Name of Vendor: {{ purchase.buyer }}</div>
                <div class="summary-item" *ngIf="isEstateAgent && toggle === 'lettings'">Name of Landlord: {{ purchase.buyer }}</div>
                <div class="summary-item" *ngIf="isEstateAgent">Property Address: {{ purchase.address }}</div>
                <div class="summary-item" *ngIf="!isEstateAgent">Project Name: {{ getProjectName(purchase.projectId) }}</div>
                <!--<div class="summary-item" *ngIf="!isEstateAgent">Business Name: {{ getBusinessName(account) }}</div>-->
            </div>
        </div>
    </div>
    <div class="checkout" *ngIf="checkout && isSubscription">
        <app-title text="Checkout" large="true"></app-title>
        <div class="form">
            <div class="subtitle">Purchase Summary</div>
            <div class="summary">
                <div class="summary-item">Yearly Impact:</div>
                <div class="summary-item" *ngFor="let project of getNonEmptyPurchases()">{{ getSubscriptionImpactText(project.projectId) }}</div>
                <div class="summary-item">Quarterly Amount: <span [class]="quaterlyContributionAmount ? 'strike' : ''">£{{ getQuaterlyAmmount() }}</span></div>
                <div class="summary-item">Yearly Total: <span [class]="quaterlyContributionAmount ? 'strike' : ''">£{{ businessSubscription.ammount * 4 | number:'1.2-2' }}</span></div>
                <div *ngIf="quaterlyContributionAmount" class="summary-item red">This purchase has been pre-paid by direct debit.</div>
            </div>
        </div>
    </div>
    <div *ngIf="isSubscription && !checkout" class="subscription-content">
        <app-title text="Annual Contribution" large="true"></app-title>
        <div class="form">
            <div *ngFor="let project of projects" class="form-item">
                <h2>{{ getSubscriptionImpactText(project.id) }}</h2>
                <input class="control" [ngModel]="getProjectPurchaseCost(project.id).toString() | pound" (ngModelChange)="setSubscriptionPrice($event, project.id)"
                    numbersOnly placeholder="Amount">
            </div>
            <h2>Quarterly Amount: £{{ getQuaterlyAmmount() }}</h2>
            <h2>Yearly Total: £{{ businessSubscription.ammount * 4 | number:'1.2-2' }} </h2>
            <h2 *ngIf="quaterlyContributionAmount > 0 && quaterlyContributionAmount != businessSubscription.ammount">Please ensure your Yearly Total is £{{ quaterlyContributionAmount * 4 }}</h2>
            <br/><br/>
            <div 
                *ngIf="quaterlyContributionAmount ? businessSubscription.ammount === quaterlyContributionAmount : businessSubscription.ammount >= 125" 
                (click)="checkout = true" 
                class="button pointer clickable"
            >
                Continue to Checkout
            </div>
        </div>
    </div>
    <div class="messages">
        <ng-container *ngIf="loading">
            <div class="info">
                Processing request...
            </div>
        </ng-container>
        <div class="info" *ngIf="message">
            {{ message }}
        </div>
    </div>
    <div class="buttons" *ngIf="checkout && !loading && (card || quaterlyContributionAmount)">
        <div *ngIf="!useExistingPaymentMethod && !(quaterlyContributionAmount && isSubscription)" (click)="useExistingPaymentMethod = !useExistingPaymentMethod" class="switch-payment-button pointer clickable">
            Use Existing Card
        </div>
        <div *ngIf="useExistingPaymentMethod && !(quaterlyContributionAmount && isSubscription)" class="card-info"> Using existing card ending in {{ card }} <span (click)="useExistingPaymentMethod = !useExistingPaymentMethod" class="switch-payment-button pointer clickable"> Use New Card
        </span>
        </div>
        <div *ngIf="useExistingPaymentMethod && !(quaterlyContributionAmount && isSubscription)" class="stripe-info">
            This page is powered and secured by Stripe. By using it you agree to the <a href="https://stripe.com/en-gb/checkout/legal" target="_blank">Terms and Conditions</a> and <a href="https://stripe.com/en-gb/privacy" target="_blank">Privacy Policy</a>
        </div>
        <div *ngIf="useExistingPaymentMethod || (quaterlyContributionAmount && isSubscription)" (click)="isSubscription ? createSubscription() : pay()" class="payment-button pointer clickable">
            {{ quaterlyContributionAmount ? 'Confirm' : 'Confirm and Pay' }}
        </div>
    </div>
    <app-login 
        *ngIf="!loading && checkout && !voucher && !useExistingPaymentMethod && !(quaterlyContributionAmount && isSubscription)" 
        [loginOnly]="false" 
        (loginEvent)="isSubscription ? createSubscription($event) : pay($event)" 
        [cardOnly]="true"
        [impactCreate]="true"
    ></app-login>
    <div class="checkout" *ngIf="checkout && voucher && !loading">
        * Prepaid for by Eden Greenspace.
        <div (click)="payFree()" *ngIf="checkout && voucher && !loading" class="button wide pointer clickable">
            Confirm Order
        </div>
    </div>
    <div class="checkout" *ngIf="checkout">
        <div (click)="checkout = false" class="button pointer clickable">
            Go Back
        </div>
    </div>
</div>
<ng-container *ngIf="!isBusiness">
    <div class="unauth-text">
        Please <span class="link pointer" routerLink="/login">login</span> to an authorised business account to access this page.
        <br /> <br />
    </div>
</ng-container>
<!-- The Modal -->
<div *ngIf="reminder" id="myModal" class="modal">
    <!-- Modal content -->
    <div class="modal-content">
        <span class="close pointer" (click)="reminder = false;">&times;</span>
        <div class="modal-title">
            Expected completion / new let
        </div>
        <ng-container *ngIf="!addedExpected">
            <div class="form-item no-bottom-margin">
                <input type="date" class="control" [(ngModel)]="expectedDate">
            </div>
            <div class="button wide pointer clickable" (click)="addExpectedOrder()">
                Submit
            </div>
        </ng-container>
        <div *ngIf="addedExpected" class="thanks-text">
            Thanks for updating us, good luck with your next deals!
        </div>
    </div>

</div>