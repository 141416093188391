import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

type PasswordResetMode = 'email' | 'reset' | 'waiting' | 'success';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
  public username: string;
  public password: string;
  public confirm: string;
  public mode: PasswordResetMode;
  public error = '';

  private authServive: AuthService;
  private route: ActivatedRoute;
  private router: Router;

  constructor(authService: AuthService, route: ActivatedRoute, router: Router) { 
    this.authServive = authService;
    this.route = route;
    this.router = router;
  }

  ngOnInit(): void {
    this.mode = this.route.snapshot.params['token'] ? 'reset' : 'email';
  }

  public sendPasswordEmail(): void {
    this.authServive.sendPasswordEmail(this.username).subscribe(
      (res) => {
        this.mode = 'waiting';
      },
      (err) => {
        console.log(err);
        this.error = err.error;
      }
    );
  }

  public resetPassword(): void {
    if(!this.password) {
      this.error = 'Please enter a new password'
      return;
    }

    if (this.password !== this.confirm) {
      this.error = 'Passwords do not match'
      return;
    }

    this.authServive.resetPassword(this.password, this.route.snapshot.params['token']).subscribe(
      (res) => {
        this.mode = 'success';
      },
      (err) => {
        console.log(err);
        this.error = err.error;
      }
    );
  }

}
