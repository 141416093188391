import { Component, NgZone, OnInit } from '@angular/core';

declare global {
  interface Window {
    Trustpilot?: any;
  }
}

@Component({
  selector: 'app-trustpilot',
  templateUrl: './trustpilot.component.html',
  styleUrls: ['./trustpilot.component.css']
})
export class TrustpilotComponent implements OnInit {
  
  constructor(private zone: NgZone) { }

  ngOnInit(): void {
    const trustboxRef = document.getElementById('trustbox');

    /**
     * Causes some kind of infinte loop with angular change detection in getEmptyProjects(),
     * so am running outise change detection.
     */
    setTimeout(() => {
      this.zone.runOutsideAngular(() => {
        window.Trustpilot?.loadFromElement(trustboxRef);
      })
    });
  }

}
