
<div class="top-row">
<img *ngIf="estateAgent" class="top-image clear-image-text" [src]="image" />
<div class="content">
<video *ngIf="winkworth" class="video" controls>
    <source [src]="'https://eden-blog-images.s3.eu-west-2.amazonaws.com/how-it-works-vid.mp4' + '#t=0.35'" type="video/mp4">
    Your browser does not support the video tag.
</video>
<pre *ngIf="estateAgent" class="top-text">
<span class="bold">Our Impact Creation System lets vendors and landlords select one of Eden’s environmental projects to support, when they choose Winkworth as their agent. 

But how does this work in practice for you and your office? Download the step-by-step guide <a class="link" [href]="pdf" target="_blank">here</a>, that explains the entire process from start to finish. What documents you’ll need. What to say. How to market the partnership.</span>
</pre>
<a *ngIf="estateAgent" class="button" [href]="pdf" target="_blank">Download Guide Here</a>

    <app-title class="title" text="General FAQs"></app-title>
    <div class="sub"><span class="link" routerLink="/our-projects" target="_blank">Project specific FAQs</span> are on each project's page.</div>
    <div class="dropdowns">
        <app-dropdown class="dropdown" dropdown="How can you prove my impact?" [contentMode]="true" [smallText]="true">
<pre class="text">How do you know that what we <span class="italic">say</span> we’re doing is what we’re <span class="italic">really</span> doing? This is a question we get a lot. Luckily, we take extra care to make sure everything we do is above board.
<span class="bold">1. Projects Research and Selection</span>

It starts with choosing the projects. We research each project for 5-6 months, including making personal visits to the sites ourselves. All our projects must conform with our <span class="link" routerLink="/about/projects">7 selection criteria</span> before we incorporate them onto our platform. All our projects:

• Address at least one of the <span class="link" routerLink="/about/overview" target="_blank">Three Planetary Crises</span> set out by the UN Environment Programme.

• Address several of the UN’s <a class="link" href="https://sdgs.un.org/goals" target="_blank">Sustainable Development Goals</a>.

• Are the best in their area - we work only with the largest and most reputable charities and NGOs, such as <a class="link" href="https://www.edenprojects.org/" target="_blank">Eden Reforestation Projects</a> and <a class="link" href="https://solar-aid.org/" target="_blank">Solar Aid</a>.

• Are visited personally by Founders Ryan and Sam at at least one of their sites, to assess their operations directly.

• Are <span class="bold">quantifiable</span> - not a single penny can be spent in support of any of our projects, without it being quantifiable in terms of number of trees planted, grams of plastic removed from the ocean, or metres squared rewilded, and so on.

• Are thoroughly researched - our Research Team investigate each partner’s financial structure, their administrative structure, the transparency of their practices, whether there are hypocritical practices (such as excessive flying), and their cost-effectiveness.

<span class="bold">2. Visuals and Maps</span>

We’ve gone the extra mile to make it easy to see what are projects do on the ground, and track each individual impact
that <span class="italics bold">you</span> - the Greenspace Community - are having.

• Our comprehensive <span class="link" routerLink="/our-projects" target="_blank">project bios and project-specific FAQs</span> detail the operations of each project, including pictures and videos from each project.

• Each project bio also includes specially made GIS maps of the precise sites where the amazing tree-planting and rewilding is taking place.

• Lastly, you can see pictures, videos and reels by Ryan and Sam taken on their regular site visits, on the Eden Greenspace <a class="link" href=" https://www.instagram.com/edengreenspace/?hl=en" target="_blank">Instagram</a>.

<span class="bold">3. Impact Records</span>

Lastly, we record and document every action our community takes.

To be as transparent as possible, we publish all the impact records - including certificates, dates and locations for all the impact our community has made, to date.

Anyone can view these records at any time, <a class="link" href="https://docs.google.com/spreadsheets/d/1A7nLmHQWvBZgTqCxm1EUVCQlY1agC3DLpNkOFhR-hT4/edit#gid=1599391297" traget="_blank">here</a>. 
</pre>
        </app-dropdown>
        <app-dropdown class="dropdown" dropdown="Why did you start Eden Greenspace?" [contentMode]="true" [smallText]="true">
<pre class="text">In late 2020 co-founders Ryan and Sam were in the midst of the Covid 19 pandemic. They saw the effects of covid
lockdowns cause our natural environments to regrow, and a global reduction in CO2 emissions.

But they were frustrated to see this glimmer of hope disappear as the pandemic subsided and normal life resumed. Once again, the planet was being forgotten.

That’s why Ryan and Sam created Eden Greenspace - a platform designed to restore the damage humans are doing to our planet, and bring nature back into our lives for good.
    
Right from the start, they wanted to avoid the greenwashing messages that ‘planting a tree’ was the one-size-fits-all solution which would save the planet.
    
Instead of just planting trees, they spent over a year researching the best environmental projects across the world, everything from rainforest protection to rewilding to ocean clean up projects. They then decided that a great way to choose projects would be to start with projects that address one of the UN Environment Programme’s <span class="link" routerLink="/about/overview">three planetary crises</span>.

By bringing all these projects together, the goal was to make real environmental action easy and accessible for ordinary people, who might never usually support environmental causes.
    
From there, it just kept growing, and they’re still working hard to spread awareness of these amazing projects, and bringing individuals and businesses together to support them.
</pre>
        <img class="big-image clear-image-text" src="../../../assets/ryan-sam.jpg" />
        </app-dropdown>
        <app-dropdown class="dropdown" dropdown="Where does your money go?" [contentMode]="true" [smallText]="true">
            <div class="row">
<pre class="pledge text">
What you see is what you get. As a not-for-profit, we guarantee that <span class="bold">100% of the money donated</span> to our environmental projects goes directly into funding restoration work on the ground.

How do we fund our curation work? We charge our corporate clients an additional 17.5% fee on all donations, to access our online account, marketing suite and receive expert advice.

All donations received through Eden’s platform can be viewed on our <span class="pointer bold clickable underline orange" routerLink="/about/impact">Public Impact Registry</span>.
</pre>
                <img class="image clear-image-text" src="../../../assets/pie-chart.png" />
            </div>
        </app-dropdown>
        <app-dropdown class="dropdown" dropdown="Why don’t you just plant trees?" [contentMode]="true" [smallText]="true">
<pre class="text">
Tree-planting, when done right, is a fantastic way of restoring the planet. It is a form of rewilding, it stabilises the soil and brings back wildlife. It’s also great for the climate, because trees sequester CO2.

But <span class="bold">there’s no ‘one size fits all’ solution to restoring our planet</span>. The only long-term, sustainable way to restore our planet is by tackling the problem from multiple angles.

That’s why at Eden Greenspace, we do things differently. We make it possible to support a <span class="bold italic">range</span> of environmental projects which tackle all <a class="link" href="https://unfccc.int/blog/what-is-the-triple-planetary-crisis" target="_blank">three planetary crises which the UNEP have identified</a>.
</pre>
            <img class="big-image clear-image-text" src="../../../assets/crisis.png" />
<pre class="text">
You can learn more about our projects <span class="link" routerLink="/our-projects">here</span>.
</pre>
        </app-dropdown>
        <app-dropdown class="dropdown" dropdown="What is carbon offsetting and why don’t you sell offsets?" [contentMode]="true" [smallText]="true">
                <div class="text">What are <span class="italics">carbon offsets</span>? Carbon offsetting is a system where you can
                    pay someone else to reduce their carbon emissions to compensate for your own carbon emissions. The idea is that
                    your emissions are ‘cancelled out’ by a reduction elsewhere.</div>
                <div class="text">Many so-called ‘environmental’ companies will tempt you into <span class="italics">buying</span>
                    carbon offsets as a way to ‘remove your carbon footprint’ with the click of a button.</div>
                <div class="text">We don’t.</div>
                <div class="text">Why? For two reasons.</div>
                <div class="text">
                    <img class="float-right" src="../../../assets/paltry.png" />Firstly, almost all carbon offsets <span
                        class="bold">don’t actually offset</span>. For example, when you buy an offset which supports the funding of
                    a renewable energy project, such as a wind farm, no <span class="italics">extra</span> carbon is absorbed. The
                    money from the offset enables the building of the wind farm instead of another coal-fired power station. This is
                    better than nothing, but because no <span class="italics">extra</span> carbon is absorbed from the atmosphere,
                    <span class="italics">your carbon footprint is not offset</span>.
                </div>
                <div class="text">95% of carbon offsets do not offset, and this is <a class="link"
                        target="_blank"
                        href="https://policy.friendsoftheearth.uk/insight/dangerous-distraction-offsetting-con#:~:text=Carbon%20offsetting%20and%20nature%20offsetting,carbon%20emissions%20and%20restoring%20nature">widely
                        acknowledged</a>. An offset that doesn’t offset doesn’t work.</div>
                <div class="text"><img class="float-left" src="../../../assets/apple.png" />Secondly, offsets send the wrong
                    message, and <span class="bold">distract from the real problem</span>. They allow big companies like <a
                        class="link" target="_blank"
                        href="https://www.apple.com/uk/newsroom/2021/10/apple-charges-forward-to-2030-carbon-neutral-goal-adding-9-gigawatts-of-clean-power-and-doubling-supplier-commitments/#:~:text=While Apple is already carbon,a net-zero climate impact.&text=In total%2C Apple has reduced,over the past five years">Apple</a>
                    and <a class="link" target="_blank"
                        href="https://www.bp.com/en/global/corporate/news-and-insights/press-releases/bernard-looney-announces-new-ambition-for-bp.html">BP</a>,
                    each with huge carbon footprints, to say that they are going ‘net zero’ or ‘carbon neutral’. </div>
                <div class="text">The reality is that every company, just like every one of us in fact, have a carbon footprint. The
                    only way to reduce our carbon footprint, and move towards a more sustainable future, is by either:</div>
                <div class="text">1. <span class="bold">Cutting out activities which emit carbon</span> - not taking flights,
                    choosing a diet with less meat, and so on.</div>
                <div class="text">And in the long-term</div>
                <div class="text">2. <span class="bold">Swapping these activities with carbon-<span class="italics">free</span>
                        alternatives</span> - electric cars and renewable energy.</div>
                <div class="text">Carbon offsets don’t work, and provide an excuse not to change our ways - both as a society, and
                    as individuals. Ultimately, restoring our planet to a healthy state is by taking <span class="italics">personal
                        responsibility</span>.</div>
                <div class="text">Where do we fit in?</div>
                <div class="text">Our goal at Eden Greenspace is not to tell you how to live your life, or pretend that we can
                    ‘offset your impact’. To promise that would be a lie. </div>
                <div class="text">Instead, our projects go above and beyond individual lifestyles, they are about restoring our
                    entire planet’s environmental problems, as a <span class="italics">collective</span>. You can read more about
                    the collective impact our projects are having <span class="link"
                        routerLink="/our-projects">here</span>.
                </div>
        </app-dropdown>
        <app-dropdown class="dropdown" dropdown="What is the ‘Triple Planetary Crisis’ and why does it matter?" [contentMode]="true" [smallText]="true">
<pre class="text">
We’ve said it before and we’ll say it again: <span class="italic">restoring our planet is not as simple as planting a tree</span>.

It’s much bigger than that. There are a whole range of problems ranging from air pollution to biodiversity loss to rising sea levels, which require a range of solutions.

So where should we start?

When we founded Eden Greenspace, we took our lead from the UNEP, or <span class="italic">United Nations Environment Programme</span>. They have identified <a class="link" href="https://unfccc.int/blog/what-is-the-triple-planetary-crisis](https://unfccc.int/blog/what-is-the-triple-planetary-crisis" target="_blank">three crises</a> facing our planet: climate, environment and pollution.

• <span class="climate">Climate crisis</span> - Perhaps the biggest crisis, climate change refers to the “long-term shifts in temperatures and weather patterns that in the long run will completely alter the ecosystems that support life on the planet.” Human actions have caused subtle changes to our climate to become a huge threat. To combat this crisis, we need to reduce our CO2 emissions and restore the ecosystems which naturally absorb CO2 from the atmosphere (such as via <span class="link" routerLink="/our-projects/tree-planting-in-kenya" target="_blank">tree-planting</span>).

• <span class="nature">Nature crisis</span> - Our planet’s ecosystems are under extreme strain from agricultural expansion, over-fishing and the effects of global warming. The result is the 1 in 8 species are facing extinction. But it’s affecting humans too - the nature crisis is impacting our food supplies, access to clean water, and is increasing weather-related disasters. Protecting endangered species and restoring our natural ecosystems are great ways to combat this crisis (for example, see our <span class="link" routerLink="/our-projects/rewilding-projects-uk" target="_blank">‘save the bees’ rewilding project</span>).

• <span class="pollution">Pollution crisis</span> - Our planet is being polluted in multiple ways. Our air is polluted by industrial emissions, often fossil fuels. Our fields and rivers are polluted by chemicals and waste (often due to a <span class="link" routerLink="/our-projects/rewilding-projects-uk" target="_blank">lack of natural pollinators</span>). Our oceans our polluted by plastic waste and microplastics - read about our <span class="link" routerLink="/our-projects/marine-plastic-removal-around-the-uk" target="_blank">marine plastic removal project</span> to see how you can address this crisis.

Eden Greenspace was started with the goal of providing a <span class="bold italic">range of environmental projects</span> that, collectively, will drive us towards the restoration of our planet.

We think that these three crisis are a good - if sobering - place for the change to begin.

            <img class="big-image clear-image-text" src="../../../assets/crisis.png" />
<pre class="text">
You can learn more about our projects <span class="link" routerLink="/our-projects">here</span>.
</pre>
</pre>
        </app-dropdown>
        <app-dropdown class="dropdown" dropdown="Are you a charity?" [contentMode]="true" [smallText]="true">
<pre class="text">
It might seem at odds with our ethos to be a <span class="bold">social enterprise</span> and not a charity, but being a charity comes with significant financial limitations which hold us back from expanding our community and scaling up our collective impact.

We feel that we cannot rely on large corporate donors and their profits because of the unwelcome influence and pressure over decision making that they bring.

More importantly, because we are not a charity but a <span class="bold">social enterprise</span>, our community can benefit from the support of like-minded investors to fulfil our goals at the scale our earth needs.

<span class="bold">We have years, not decades, to restore our planet</span>. That’s why we’ve done everything we can to turbo-charge the growth of our community and its collective impact.

We also borrow the best elements of charitable organizations to serve our cause. For example, we pledge that at least <span class="link" routerLink="/about/overview" target="_blank">80% of your money goes directly into supporting our projects</span>.

Similarly, transparency is built into everything we do. Unlike other organisations, we publish all the details of each project, and tell you exactly where your money is going. We are transparent about our methods for calculating your impact, and we <a class="link" href="https://docs.google.com/spreadsheets/d/1A7nLmHQWvBZgTqCxm1EUVCQlY1agC3DLpNkOFhR-hT4/edit#gid=1599391297](https://docs.google.com/spreadsheets/d/1A7nLmHQWvBZgTqCxm1EUVCQlY1agC3DLpNkOFhR-hT4/edit#gid=1599391297" target="_blank">publish</a> the total impact our community has had for each project, and our total impact to date.

Learn more about our values <span class="link" routerLink="/about/overview" target="_blank">here</span>.
</pre>
        </app-dropdown>
    </div>
</div>
</div>