
<div *ngIf="!contentMode" class="gallery-container" [attr.data-fullscreen]="fullscreen">
    <div *ngIf="images.length > 5 || desktop === false" class="scroll-arrow down pointer" [attr.data-fullscreen]="fullscreen" (click)="scrollGallery(false)"></div>
    <div #gallery [class]="fullscreen ? 'fullscreen-gallery' : (images.length < 6  && desktop === true ? 'gallery-small' : 'gallery')">
        <img [class]="desktop ? 'gallery-image-desk' : fullscreen ? 'fullscreen-image' : 'gallery-image' " *ngFor="let image of images" [src]="image"/>
    </div>
    <div *ngIf="images.length > 5 || desktop === false" class="scroll-arrow up pointer" [attr.data-fullscreen]="fullscreen" (click)="scrollGallery()"></div>
</div>
<div *ngIf="contentMode" class="gallery-container">
    <div class="scroll-arrow down pointer" (click)="scrollGallery(false)"></div>
    <div #gallery class="content-gallery">
        <ng-content></ng-content>
    </div>
    <div class="scroll-arrow up pointer" (click)="scrollGallery()"></div>
</div>