import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { projects } from 'server/projects';
import { SeoService } from 'src/app/services/seo.service';
import { Bio, BIOS } from '../projects/projects.component';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.css']
})
export class BioComponent implements OnInit {
  public bios = BIOS;
  public bio: Bio;
  private route: ActivatedRoute;
  private seoService: SeoService;

  constructor(route: ActivatedRoute, seoService: SeoService) { 
    this.route = route;
    this.seoService = seoService;
  }

  ngOnInit(): void {
    this.route.params.subscribe(() => {
      this.setBio();
    });

  }

  getProjectLogo(): string | null {
    return projects.find((project) => project.id === this.bio.projectId)?.image;
  }

  private setBio(): void {
    this.bio = BIOS.find((bio) => {
      return bio.url === this.route.snapshot.params['url'];
    });

    this.seoService.setSeo(this.bio.title, this.bio.description);
  }

}
