import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FooterService } from 'src/app/services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public email = '';
  public name = '';
  public error = '';

  public FooterService = FooterService;

  public authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
  }

  ngOnInit(): void {
  }

  public signUp(): void {
    this.authService.newsletterSignup(this.email, this.name).subscribe((message) => {
      this.error = message;
    });
  }

}
