import { Injectable } from '@angular/core';
import { CartItem } from '../components/gifting/gifting.component';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public static cart: CartItem[] = [];
  public static quantity: number = 1;
  public static express: false;
  constructor() { }
}
