import { AccountStep } from "./types";

export const accountSetup: AccountStep[] = [
    {
        title: 'Step 1/4: Download General Green Branding Materials',
        done: false,
        color: 'red',
        steps: [
            {
                title: 'Download Sales brochure (for clients).',
                done: false,
                percentage: 4.2,
                links: ['https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/Sales+and+Lettings+Brochures/Sales+Brochure.pdf'],
                nOfLinks: 1,
            },
            {
                title: 'Download Lettings brochures (for clients).',
                done: false,
                percentage: 4.2,
                links: [
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/Sales+and+Lettings+Brochures/Notting-Hill-Lettings-Brochure.pdf',
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/Sales+and+Lettings+Brochures/Lettings+Brochure+(%C2%A318_letting).pdf',
                ],
                nOfLinks: 1,
            },
            {
                title: 'Download Partnership Summary (for office use).',
                done: false,
                percentage: 4.2,
                links: ['https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/Partnership-Summary.pdf'],
                nOfLinks: 1,
            },
            {
                title: 'Download Office Posters.',
                done: false,
                percentage: 4.2,
                links: [
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/branded-content/General-Lettings-Projects-Poster.jpg',
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/branded-content/General-Sales-Project-Poster.jpg',
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/branded-content/Letting-Tree-Poster.jpg',
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/branded-content/Sales-Tree-Poster.jpg',
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/office-posters/Sales-Winkworth.pdf',
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/office-posters/Tenancy-Winkworth.pdf',
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/office-posters/Tree-Lettings-Winkworth.pdf',
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/office-posters/Tree-Sales-Winkworth.pdf',
                ],
                nOfLinks: 1,
            },
            {
                title: 'Download Email footers.',
                done: false,
                percentage: 4.2,
                links: [
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/footers/lettings-footer.jpg',
                    'https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/footers/sale-footer.jpg',
                ],
                nOfLinks: 2,
            },
            {
                title: 'Download Social Media Launch Post (tag @edengreenspace).',
                done: false,
                percentage: 4,
                links: ['https://eden-blog-images.s3.eu-west-2.amazonaws.com/marketing-media/Promoting+on+Social+Media/Launch+Post.png'],
                nOfLinks: 1,
            },
        ]
    },
    {
        title: 'Step 2/4: Valuation Letter',
        done: false,
        color: 'orange',
        steps: [
            {
                title: 'Going on MAs = Have you begun discussing the partnership with clients and given them a copy of the sales/lettings brochure?',
                done: false,
                percentage: 12.5,
                checkbox: true,
            },
            {
                title: 'Valuation Lettter = Have you added a short paragraph mentioning the partnership as part of the service Winkworth offers in your valuation letter template?',
                done: false,
                percentage: 12.5,
                checkbox: true,
            },
        ],
        exampleText: {
            title: 'Example from Winkworth Devizes(for sales):',
            quote: '“Our environmental impact is very important to us and in view of this Winkworth have partnered with Eden Greenspace (edengreenspace.co.uk) and will contribute to one of their projects in your name, and chosen by you, on the completion of your sale. Further information about these projects is attached to this letter.”',
            brackets: '[Sales brochure attached]'
        }
    },
    {
        title: 'Step 3/4: Instruction Letter',
        done: false,
        color: 'yellow',
        steps: [
            {
                title: 'Instruction Letter = Have you added a short paragraph again mentioning the partnership in your instruction letter template?',
                done: false,
                percentage: 25,
                checkbox: true,
            },
        ],
        exampleText: {
            title: 'Example from Winkworth Devizes (for sales):',
            quote: '“Our environmental impact is very important to us and in view of this Winkworth have partnered with Eden Greenspace (edengreenspace.co.uk) and will contribute to one of their projects in your name, and chosen by you, on the completion of your sale. Further information about these projects is attached to this letter.”',
            brackets: '[Sales brochure attached].'
        }
    },
    {
        title: 'Step 4/4: Exchange and Contracts',
        done: false,
        color: 'green',
        steps: [
            {
                title: 'Exchange Letter / Tenancy Agreed = Have you added a section to your exchange / completion letter template asking the vendor or landlord to choose which project they wish to support from the sales/lettings brochures?',
                done: false,
                percentage: 25,
                checkbox: true,
            },
        ],
        exampleText: {
            title: 'Example (for Lettings):',
            quote: '“Through our partnership with Eden Greenspace (edengreenspace.co.uk), Winkworth have pledged to use a percentage of their fee to support Eden’s world-leading environmental projects on your behalf. Please choose which project you would like to support from the attached brochure. We will then send you a personalised Certificate of Impact once the tenancy agreement has been signed.”',
            brackets: '[Lettings brochure attached]'
        }
    }
];

export function getAccountStepsPercentage(steps: AccountStep[]): number {
    return steps.map(
        (accountStep) => accountStep.steps
            .filter((step) => step.done)
            .map((step) => step.percentage)
            .reduce((acc, curr) => acc + curr, 0)
    ).reduce((acc, curr) => acc + curr, 0);
}