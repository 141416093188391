import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { environment } from 'src/environments/environment';
import { CartItem, getGroupImage } from '../gifting/gifting.component';
declare var Stripe: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  private stripe;
  
  public getGroupImage = getGroupImage;

  public cart: CartItem[] = [];

  public userName?: string;
  public loggedIn = false;
  public existing = false;
  public message?: string;
  public loading = false;
  public retry = false;

  public personalMessage?: string;
  public recipient?: string;
  public newsletter = false;

  private email?: string;
  
  private intent?: string;

  private authService: AuthService;
  private router: Router;
  
  constructor(authService: AuthService, router: Router) {
    this.authService = authService;
    this.router = router;
  }

  ngOnInit(): void {
    // Hack
    setTimeout(() => {
      this.stripe = environment.production
        ? Stripe('pk_live_51IX4vmBDboGHB3gmCr8cmjFzg0aO9LxJHErrbE8FAtOh806Mb3PWjkPFR3A6g4z4ySI1mlfoToieenXke3iuFP1A00zZvlxg13')
        : Stripe('pk_test_51IX4vmBDboGHB3gmxC86oDlimVDH8gS6qAZ0QFAT6BtWq2aYrO4VCosc457AOLotPFFMfM0hK7bOTlgLMxzwQgYT00rY5mAx2D');
    }, 200);

    this.cart = CartService.cart;
    this.authService.getAccountName().subscribe((account) => {
      this.loggedIn = true;
      this.existing = true;

      this.userName = 'Logged in as: ' + account.user.name;
      this.email = account.user.name;
    },
    (err) => {
      this.userName = 'Sign Up'
    });
  }

  public getTotalCost(): number {
    let total = 0;
    
    for(const item of this.cart) {
      total = total + item.cost * item.quantity;
    }
    
    return total;
  }

  public removeFromCart(item: CartItem): void {
    item.quantity = item.quantity - 1;
    this.cart = this.cart.filter((cartItem) => cartItem.quantity > 0);
    CartService.cart = this.cart;
  }

  public login(event?: string): void {
    this.authService.getAccountName().subscribe((account) => {
      this.loggedIn = true;
      this.userName = 'Logged in as: ' + account.user.name;
      this.email = account.user.name
      if(event) {
        this.message = '';
        this.retry = false;
        this.loading = true;
        this.authService.payOneOff(
          {
            paymentMethodId: event,
            cart: this.cart,
            personalMessage: this.personalMessage,
            recipient: this.recipient,
          }
        ).subscribe((result) => {
          this.loading = false;
          console.log(result);
          if(result.status === 'succeeded') {
            this.message = 'Payment successful!'
            this.retry = false;
            this.redirect();
          } else if(result.status === 'requires_payment_method') {
            this.intent = result.id;
            this.message = 'There was an error with your card. Please try again';
            this.retry = true;
          } else if (result.status === 'requires_action') {
            this.message = 'Awaiting confirmation';
            this.stripe.confirmCardPayment(result.client_secret, {
              payment_method: event,
            }).then((res) => {
              console.log(res);
              if (res.error) {
                this.intent = result.id;
                this.message = 'There was an error with your card. Please try again';
                this.retry = true;
              } else {
                if (res.paymentIntent.status === 'succeeded') {
                  this.message = 'Payment successful!'
                  this.redirect();
                }
              }
            })
          } else {
            this.message = 'There was an error with your card. Please try again';
            this.retry = true;
          }
        });
      }
    },
    (err) => {
      this.userName = 'You must login or create an account \n to pay for your plan.'
    });
  }

  public confirm() {
    this.message = '';
    this.loading = true;
    this.authService.payOneOff(
      {
        cart: this.cart,
        personalMessage: this.personalMessage,
        recipient: this.recipient,
      }
    ).subscribe((result) => {
      console.log(result);
      this.loading = false;
      if(result.status === 'succeeded') {
        if(this.retry) {
          this.message = 'Payment successful!'
        } else {
          this.message = 'Payment successful!'
        }
        this.retry = false;
        this.redirect();
      } else if(result.status === 'requires_payment_method') {
        this.intent = result.id;
        this.message = 'There was an error with your card. Please try again';
        this.retry = true;
      } else {
        this.message = 'There was an error with your card. Please try again';
        this.retry = true;
      }
    },
    (err) => {
      this.router.navigate(['/login']);
    }
    );
  }

  private redirect() {
    CartService.cart = [];
    this.authService.newsletterSignup(this.email, 'Eden Greenspace user').subscribe(
      () => {},
      () => {},
    );
    this.router.navigate(['success', 'cart']);
  }

  public switch (key) {
  }

}
