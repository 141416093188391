import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BusinessSubsription, CustomBusinessPurchase, EstateAgentPurchase, Purchase } from 'server/purchases';
import { AccountStep, Level, Media, Poll, Vote } from 'server/types';
import { environment } from 'src/environments/environment';
import { BlogUpload, BusinessPurchase } from '../components/admin/admin.component';
import { SelectedProject } from '../components/choose-plan/choose-plan.component';
import { BraceletObject } from '../components/login/login.component';
import { Review } from '../components/reviews/reviews.component';

const route = environment.production ? '' : 'http://localhost:8000/'

export interface PlanSummary {
  plan: string;
  projects: SelectedProject[];
  date?: Date;
}

export interface TotalImpact {
  _id: number;
  //totalTokens: number;
  value: number;
}

export interface CompletedImpact {
  _id: string;
  impact: TotalImpact[];
}

interface BlogTextSection {
  type: 'text' | 'quote' | 'subtitle' | 'html' | 'image' ;
  data: string;
}

export interface Blog {
  _id: string;
  title: string;
  author: string;
  category: string;
  summary: string;
  content: BlogTextSection[];
  thumbnail: string;
  tags: string[];
  date: string;
  url: string;
  metaTitle: string;
  metaDescription: string;
  readTime: number;
}

export interface TreesCounter {
  _id: string;
  total: number;
  goal: number;
  current: number;
}

export interface LeaderBoardItem {
  _id: string;
  branch: string;
  spend: number;
  houses: number;
  lettings: number;
  trees: number;
  rewilding: number;
  plastics: number;
  solarLighting: number;
}

export interface RecentOrder {
  branch: string;
  order: CustomBusinessPurchase;
}

export interface UserData {
  _id: string;
  username: string;
  purchases?: Purchase;
  lastLogin: string;
  downloadRecords?: string[];
  accountSteps: AccountStep[],
  //expectedOrder?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private http: HttpClient;

  constructor(http: HttpClient) { 
    this.http = http;
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  public getNewsletterPopup(): string {
    return localStorage.getItem('newsletter-popup');
  }

  public login(
    username: string, 
    password: string, 
    createNew = false, 
    firstName?: string, 
    lastName?: string,
    business = false,
    phone?: string,
    businessType?: string,
    businessLogo?: File,
    branch?: string,
  ) {
    localStorage.setItem('business-popup', 'true');
    if (createNew) {
      const formData = new FormData();

      // Store form name as "file" with file data
      formData.append("files", businessLogo);

      // Make http post request over api
      // with formData as req

      const body = {
        username: username,
        password: password,
        firstName: firstName,
        lastName: lastName,
        business: business,
        phone: phone,
        businessType: businessType,
      }
      formData.append("body", JSON.stringify(body));
      return this.http.post<any>(
          route + 'createAccount', 
          formData
        ).pipe(map((res) => {
        localStorage.setItem('token', res.accessToken);
        localStorage.setItem('newsletter-popup', 'false');
        localStorage.setItem('trees-popup', 'false');
        return res;
      },
      (err) => { 
        console.log(err);
        return err;
      }));
    } else if(branch) {
      return this.http.post<any>(
        route + 'create-winkworth-account',
        {
          username: username,
          password: password,
          firstName: firstName,
          lastName: lastName,
          business: business,
          phone: phone,
          businessType: businessType,
          branch: branch,
        }
      ).pipe(map((res) => {
        localStorage.setItem('token', res.accessToken);
        localStorage.setItem('newsletter-popup', 'false');
        localStorage.setItem('trees-popup', 'false');
        return res;
      },
        (err) => {
          console.log(err);
          return err;
        }));
    }
    return this.http.post<any>(route + 'login', {username: username, password: password}).pipe(map((res) => {
      localStorage.setItem('token', res.accessToken);
      localStorage.setItem('newsletter-popup', 'false');
      localStorage.setItem('trees-popup', 'false');
      return res;
    },
    (err) => {
      console.log(err);
      return err;
    }));
  }

  public canCreatePlan(): Observable<boolean> {
    return this.http.get<any>(route + 'canCreatePLan').pipe(map((res) => {
      return res.canCreatePlan;
    }));
  }

  public setPlan(planSummary: PlanSummary) {
    return this.http.post<any>(route + 'plan', planSummary);
  }

  public getAccountName(): Observable<any> {
    return this.http.get<any>(route + 'account');
  }
  
  public isAdmin(): Observable<boolean> {
    return this.http.get<any>(route + 'account').pipe(map((res) => res.user.name === 'jane_doe@mail.com'));
  }

  public isBusiness(): Observable<boolean> {
    return this.http.get<any>(route + 'business-account').pipe(
      catchError(() => of(false)),
      map((res) => !!res.user),
    );
  }

  public isEstateAgent(): Observable<boolean> {
    return this.http.get<any>(route + 'estate-agent').pipe(
      catchError(() => of(false)),
      map((res) => !!res.user),
    );
  }

  public isWinkworth(): Observable<boolean> {
    return this.http.get<any>(route + 'winkworth-account').pipe(
      catchError(() => of(false)),
      map((res) => !!res.user),
    );
  }

  public getBranchName(): Observable<string> {
    return this.http.get<any>(route + 'branch-name').pipe(map((res) => res.branch));
  }

  public hasVoucher(): Observable<boolean> {
    return this.http.get<any>(route + 'business-voucher');
  }

  public getQuaterlyContributionAmmount(): Observable<number | undefined> {
    return this.http.get<number | undefined>(route + 'quaterly-contribution-ammount')
  }

  public pay(paymentMethod): Observable<any> {
    return this.http.post<any>(route + 'pay', paymentMethod);
  }

  public payOneOff(paymentMethod): Observable<any> {
    return this.http.post<any>(route + 'pay-one-off', paymentMethod);
  }

  public payTrees(paymentMethod): Observable<any> {
    return this.http.post<any>(route + 'pay-trees', paymentMethod);
  }

  public payBracelet(paymentMethod): Observable<any> {
    return this.http.post<any>(route + 'pay-bracelet', paymentMethod);
  }

  public getBracelets(): Observable<BraceletObject[]> {
    return this.http.get<any>(route + 'bracelets');
  }

  public getTotalBracelets(): Observable<number> {
    return this.http.get<number>(route + 'total-bracelets');
  }

  public addReview(review: Review): Observable<any> {
    return this.http.put<any>(route + 'review', review);
  }

  public getReviews(): Observable<Review[]> {
    return this.http.get<Review[]>(route + 'review');
  }

  public getTotalCompletedImpact(): Observable<TotalImpact[]> {
    return this.http.get<TotalImpact[]>(route + 'total-completed-impact');
  }

  public getCompletedImpacts(): Observable<CompletedImpact[]> {
    return this.http.get<CompletedImpact[]>(route + 'completed-impacts');
  }

  public addCompletedImpact(impact: TotalImpact[]): Observable<any> {
    return this.http.post<any>(route + 'add-completed-impact', {impact});
  }

  public dispatchBracelet(_id) {
    return this.http.put<any>(route + 'dispatch-bracelet', { params: { _id: _id } });
  }

  public getUsersPurchases(): Observable<UserData[]> {
    return this.http.get<UserData[]>(route + 'users-data');
  }

  public createImpactPay(paymentMethod, purchase: EstateAgentPurchase): Observable<any> {
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append("files", purchase.image);
    purchase.image = null;

    // Make http post request over api
    // with formData as req
    formData.append("purchase", JSON.stringify(purchase));
    if(paymentMethod) {
      formData.append("paymentMethodId", JSON.stringify(paymentMethod));
    }

    return this.http.post<any>(route + 'pay-create-impact', formData);
    /*
    return this.http.post<any>(route + 'pay-create-impact', {
      paymentMethodId: paymentMethod,
      purchase,
    });
    */
  }

  public genericImpactPay(paymentMethod, purchase: EstateAgentPurchase): Observable<any> {
    return this.http.post<any>(route + 'pay-generic-impact', {paymentMethodId: paymentMethod, purchase});
  }

  public createBusinessSubscription(paymentMethod, purchase: BusinessSubsription): Observable<any> {
    return this.http.post<any>(route + 'create-business-subscription', { paymentMethodId: paymentMethod, purchase });
  }

  public createImpactFree(purchase: EstateAgentPurchase): Observable<any> {
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append("files", purchase.image);
    console.log(purchase.image);
    purchase.image = null;

    // Make http post request over api
    // with formData as req
    formData.append("purchase", JSON.stringify(purchase));

    return this.http.post<any>(route + 'create-impact-free', formData);
  }

  public getPlan(): Observable<any> {
    return this.http.get(route + 'plan');
  }

  public getTotalImpact(): Observable<TotalImpact[]> {
    return this.http.get<TotalImpact[]>(route + 'total-impact');
  }

  public updatePayment(paymentId: string): Observable<any> {
    return this.http.post(route + 'payment-update', {paymentId: paymentId});
  }

  public updatePassword(password: string, newPassword: string){
    return this.http.post(route + 'password-update', {
      password: password,
      newPassword: newPassword,
    });
  }

  public getPamentStatus(): Observable<any> {
    return this.http.get(route + 'payment-status');
  }

  public cancelSubscription(): Observable<any> {
    return this.http.post(route + 'cancel-subscription', {});
  }

  public newsletterSignup(email: string, name: string): Observable<string> {
    if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return of('Email address is invalid');
    } else if(!name) { 
      return of('Please enter your name');
    } else {
      localStorage.setItem('newsletter-popup', 'false');
      return this.http.post<string>(route + 'newsletter-sign-up', { email: email, name: name });
    }
  }

  public blogUpload(blog: BlogUpload): Observable<any> {
    const formData = new FormData(); 
  
    // Store form name as "file" with file data
    formData.append("files", blog.thumbnail, blog.thumbnail.name);
    blog.thumbnail = null;

    for(const content of blog.content) {
      if(content.type === 'image') {
        formData.append("files", content.data, content.data.name);
        content.data = null;
      }
    }
      
    // Make http post request over api
    // with formData as req
    formData.append("blog", JSON.stringify(blog));
    return this.http.post(route + 'blog-upload', formData);
  }

  public blogEdit(blog: Blog): Observable<any> {
    return this.http.put(route + 'blog-edit', blog);
  }

  public getBlogs(categories: string[], tags: string[], searchTerm?: string): Observable<Blog[]> {
    return this.http.get<Blog[]>(route + 'blogs', { params: { categories, tags: ['', ...tags], ...(searchTerm ? { searchTerm } : {}) }});
  }

  public getArticle(url: string): Observable<Blog> {
    return this.http.get<Blog>(route + 'article', {params: {url: url}});
  }

  public getLatestArticle(): Observable<Blog> {
    return this.http.get<Blog>(route + 'latest-article');
  }

  public getTrees(): Observable<TreesCounter> {
    return this.http.get<TreesCounter>(route + 'trees');
  }

  public addPurchase(purchase: BusinessPurchase): Observable<any> {
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append("files", purchase.certificate);
    purchase.certificate = null;

    // Make http post request over api
    // with formData as req
    formData.append("purchase", JSON.stringify(purchase));
    return this.http.post(route + 'business-purchase', formData);
  }

  public getTotalTrees(): Observable<number> {
    return this.http.get<number>(route + 'total-trees');
  }

  public getExampleAccount(): Observable<any> {
    return this.http.get<any>(route + 'example-account');
  }

  public getLeaderboard(): Observable<LeaderBoardItem[]> {
    return this.http.get<LeaderBoardItem[]>(route + 'leaderboard');
  }

  public getRecentOrders(): Observable<RecentOrder[]> {
    return this.http.get<RecentOrder[]>(route + 'recent-orders');
  }
  
  public sendPasswordEmail(username: string): Observable<any> {
    return this.http.put(route + 'reset-password-email', {username: username});
  }

  public resetPassword(password: string, token: string): Observable<any> {
    return this.http.put(route + 'reset-password', { password: password, token: token });
  }

  public getMedia(): Observable<Media> {
    return this.http.get<Media>(route + 'media');
  }

  public getLevel(): Observable<Level> {
    return this.http.get<Level>(route + 'level');
  }

  public getLevelLogo(): Observable<string> {
    return this.http.get<Level>(route + 'level').pipe(map(
      (level) => {
        switch (level) {
          case 'seed':
            return '../../../assets/seed-level.png'
          case 'sappling':
            return '../../../assets/sappling-level.png'
          case 'tree':
            return '../../../assets/tree-level.png'
          case 'grove':
            return '../../../assets/grove-level.png'
          case 'forest':
            return '../../../assets/forest-level.png'
        }
      }
    ));
  }

  public resetPopup(): Observable<any> {
    return this.http.put(route + 'reset-popup', {});
  }

  public addDownloadRecord(record: string): Observable<any> {
    return this.http.put(route + 'add-download-record', { record });
  }
  
  public getDefaultCard(): Observable<string> {
    return this.http.get<string>(route + 'default-card');
  }

  public getAccountSteps(): Observable<AccountStep[]> {
    return this.http.get<AccountStep[]>(route + 'account-steps');
  }

  public updateAccountSteps(accountSteps: AccountStep[]): Observable<any> {
    return this.http.put(route + 'update-account-steps', { accountSteps });
  }

  public addExpectedOrder(expectedOrder: Date): Observable<any> {
    return this.http.post(route + 'add-expected-order', { expectedOrder });
  }

  public getPoll(pollId: string): Observable<Poll> {
    return this.http.get<Poll>(route + 'poll', { params: { pollId } });
  }

  public voteInPoll(vote: Vote, pollId: string): Observable<any> {
    return this.http.post<any>(route + 'vote', { vote, pollId });
  }
}
