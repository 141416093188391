import { Component, OnInit } from '@angular/core';
import { getBusinessName } from 'server/accounts';
import { Media } from 'server/types';
import { AuthService } from 'src/app/services/auth.service';
import { ToggleOptions } from '../toggle/toggle.component';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {
  public toggleOptions: ToggleOptions[] = [
    {
      name: 'marketing-materials',
      displayName: 'Marketing Materials',
    },
    {
      name: 'project-media',
      displayName: 'Project Media',
    },
    {
      name: 'eden-brand-content',
      displayName: 'Eden Brand Content',
    }
  ];

  public toggle: string;
  public media: Media;
  public business = false;
  public branch: string;
  public businessName: string;
  public levelLogo: string;
  public name: string;
  
  public authService: AuthService;

  constructor(authService: AuthService) { 
    this.authService = authService;
  }

  ngOnInit(): void {
    this.authService.isBusiness().subscribe(
      (business) => { 
        this.business = business; 
      
        this.authService.getMedia().subscribe((media) => {
          this.media = media;
        });

        this.authService.getBranchName().subscribe((branch) => {
          this.branch = branch;
        });

        this.authService.getAccountName().subscribe((res) => {
          this.businessName = getBusinessName(res.user.name);
          this.name = res.user.name;
        });

        this.authService.getLevelLogo().subscribe((logo) => {
          this.levelLogo = logo;
        });
      }
    );
  }

  public toggleSwitch(option: string): void {
    this.toggle = option;
  }

  public print(thing: any): void {
    console.log(thing);
  }

}
