import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pound',
  pure: false
})
export class PoundPipe implements PipeTransform {

  transform(value: string): string {
    return '£' + (value || '');
  }

}
