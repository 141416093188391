import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {
  @Input() text: string
  @Input() large = false;
  @Input() medium = false;
  @Input() small = false;
  @Input() white = false;

  @ViewChild("title") title;

  constructor() { }

  ngOnInit(): void {
  }

}
