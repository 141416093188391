import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-business-how-it-works',
  templateUrl: './business-how-it-works.component.html',
  styleUrls: ['./business-how-it-works.component.css']
})
export class BusinessHowItWorksComponent implements OnInit {
  public pdf: string;
  public image: string;
  public estateAgent = false;
  public winkworth = false;

  private authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
  }

  ngOnInit(): void {
    this.authService.isEstateAgent().subscribe((res) => {
      if(res) {
        this.authService.isWinkworth().subscribe((res2) => {
          if(res2) {
            this.winkworth = true;
            this.pdf = '../../../assets/winkworth-office-guide.pdf';
            this.image = '../../../assets/business-how-it-works.jpg';
          } else {
            this.pdf = '../../../assets/rural-view-office-guide.pdf';
            this.image = '../../../assets/rural-view-how-it-works.jpg';
          }
        });
      }
      this.estateAgent = res;
    });
  }

}
